import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList, ModalForm, Uploader, CustomNumberFormat } from '../../../components';
import { connect } from 'react-redux';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils';
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup
} from '@mui/material';
import AsyncSelect from 'react-select/async'

class TopupList extends Component {

  newTopup = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New Topup",
      data: {
        money_to_wallet: 0,
        actual_fee: 0,
        ad_acc_per_issuance_fee: 0,
        acc_deposit: 0,
        paypal_fee: 0,
        screenshot_transaction_type: 'upload',
        drebest_received: 1
      },
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Email Address
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              name="email"
              value={this.props.user?.email}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Team ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/teams', {search: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({id: e.team_id, name: `${e.team_search}`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="team_id"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'team_id',
                    value,
                    depends: [{
                      route: '/api/v1/fuds',
                      query: { 'team_id': value.id, 'can_use': 1 },
                      key: 'fud_id'
                    }]
                  }
                }
                handleChange(event)
              }}
              placeholder={"Team"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData.values.team_id}
              styles={submitData.errors?.team_id ? errorStyles : customStyles}
            />
            {submitData.errors?.team_id && <span style={{ color: 'red' }}>{submitData.errors.team_id[0]}</span>}
          </div>
          {submitData.values.team_id &&
          <>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                FUD ID <span style={{ color: 'red' }}>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/fuds', {search: inputValue, team_id: submitData.values.team_id.id || 0}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({...e, id: e.fud_id})))
                      }
                    })
                  })
                })}
                defaultOptions={ctx.state.options['fud_id']?.map(e => ({ ...e, id: e.fud_id })) || []}
                onInputChange={handleInputChange}
                isSearchable
                name="fud_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'fud_id',
                      value
                    }
                  }
                  handleChange(event)
                  if (value) {
                    const e = {
                      target: {
                        name: 'actual_fee',
                        value: (value.fee_of_pack || 0) - (value.total_topup_actual_fee || 0)
                      }
                    }
                    handleChange(e)
                  }
                }}
                placeholder={"FUD"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.fud_id}
                styles={submitData.errors?.fud_id ? errorStyles : customStyles}
              />
              {submitData.errors?.fud_id && <span style={{ color: 'red' }}>{submitData.errors.fud_id[0]}</span>}
            </div>
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Remaining Topup
              </Box>
              <TextField 
                fullWidth
                disabled
                margin='dense'
                name="remaining_topup"
                value={submitData.values.fud_id?.remaining_topup || 0}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  },
                  inputComponent: CustomNumberFormat
                }}
              />
            </div>
          </>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Payment Method <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/payment_methods', {name: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({...e, id: e.payment_method_id})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="payment_method_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "payment_method_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Payment method..."}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.payment_method_id || null}
              styles={submitData.errors?.payment_method_id ? errorStyles : customStyles}
            />
            {submitData.errors?.payment_method_id && <span style={{ color: 'red' }}>{submitData.errors?.payment_method_id[0]}</span>}
          </div>
          {submitData?.values.payment_method_id &&
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Sent to
              </Box>
              <div style={{ background: '#F8F9FA', padding: '16px 25px', border: '1px solid #C2C7CD', borderRadius: 8, maxHeight: 150, overflow: 'auto' }}>
                <div dangerouslySetInnerHTML={{ __html: submitData.values.payment_method_id.info }} />
              </div>
            </div>
          }
          {submitData?.values.payment_method_id && submitData.values.payment_method_id?.code === 'po' &&
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Send from email
              </Box>
              <TextField 
                fullWidth
                margin='dense'
                name="email_from"
                value={submitData.values.email_from}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
              />
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              A - Money To Wallet ($)<span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="money_to_wallet"
              onChange={handleChange}
              value={submitData.values.money_to_wallet}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors?.money_to_wallet}
              helperText={submitData.errors?.money_to_wallet ? submitData.errors.money_to_wallet[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              B - Actual Fee ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="actual_fee"
              onChange={handleChange}
              value={submitData.values.actual_fee}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              C - Creation fee ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="ad_acc_per_issuance_fee"
              onChange={handleChange}
              value={submitData.values.ad_acc_per_issuance_fee}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              D - Acc Deposit ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="acc_deposit"
              onChange={handleChange}
              value={submitData.values.acc_deposit}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          {submitData.values.payment_method_id?.code === 'paypal' &&
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              E - Paypal Fee <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="paypal_fee"
              onChange={handleChange}
              value={submitData.values.paypal_fee}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors?.paypal_fee ? true : false}
              helperText={submitData.errors?.paypal_fee ? submitData.errors?.paypal_fee[0] : ''}
            />
          </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Total money USD
            </Box>
            <TextField 
              fullWidth
              disabled
              margin='dense'
              name="total_money"
              value={Number.parseFloat(submitData.values.money_to_wallet || 0) + Number.parseFloat(submitData.values.actual_fee || 0) + Number.parseFloat(submitData.values.ad_acc_per_issuance_fee || 0) + Number.parseFloat(submitData.values.acc_deposit || 0) + Number.parseFloat(submitData.values.paypal_fee || 0)}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          {submitData.values.payment_method_id?.code == 'bank_vnd' &&
            <>
              <div style={{ marginTop: 10 }}>
                <Box fontSize={15} fontWeight='bold'>
                  Apply Rate
                </Box>
                <TextField 
                  fullWidth
                  disabled
                  margin='dense'
                  name="total_money"
                  value={submitData.values.payment_method_id.rate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                    inputComponent: CustomNumberFormat
                  }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <Box fontSize={15} fontWeight='bold'>
                  Total money VND
                </Box>
                <TextField 
                  fullWidth
                  disabled
                  margin='dense'
                  name="total_money"
                  value={submitData.values.payment_method_id.rate * (Number.parseFloat(submitData.values.money_to_wallet || 0) + Number.parseFloat(submitData.values.actual_fee || 0) + Number.parseFloat(submitData.values.ad_acc_per_issuance_fee || 0) + Number.parseFloat(submitData.values.acc_deposit || 0) + Number.parseFloat(submitData.values.paypal_fee || 0))}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                    inputComponent: CustomNumberFormat
                  }}
                />
              </div>
            </>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Voucher Code
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="voucher_code"
              onChange={handleChange}
              value={submitData.values.voucher_code}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          {/* <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Drebest received</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.drebest_received === 1 ? true : false}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'drebest_received',
                        value: event.target.checked ? 1 : 0
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'drebest_received'}
                />
              }
            />
          </div> */}
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Cancel
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="cancel"
              onChange={handleChange}
              value={submitData.values.cancel}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Screenshot Transaction Type
            </Box>
            <RadioGroup
              value={submitData.values.screenshot_transaction_type}
              name="screenshot_transaction_type"
              onChange={handleChange}
            >
              <FormControlLabel value="upload" control={<Radio />} label="Upload Image" />
              <FormControlLabel value="direct_link" control={<Radio />} label="Direct Link" />
            </RadioGroup>
          </div>
          
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Screenshot of Transaction <span style={{ color: 'red' }}>*</span>
            </Box>
            {submitData.values.screenshot_transaction_type == 'upload' ? 
            <>
            <Uploader 
              className={submitData.errors?.files ? 'form-upload-error' : ''}
              name="attachment"
              multiple={true}
              accept='image/*'
              uploadOnPicked
              handleChange={handleChange}
              submitData={submitData}
            />
            {submitData.errors?.files && <span style={{ color: 'red' }}>{submitData.errors.files[0]}</span>}
            </> 
            : 
            <TextField 
              fullWidth
              margin='dense'
              name="screenshot_transaction_link"
              onChange={handleChange}
              value={submitData.values.screenshot_transaction_link}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              helperText={submitData.errors?.screenshot_transaction_link ? submitData.errors.screenshot_transaction_link[0] : ''}
              error={submitData.errors?.screenshot_transaction_link ? true : false}
            />
            }
          </div>
          {submitData.values.payment_method_id?.code === 'paypal' &&
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Paypal Transection ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="paypal_transection_id"
              onChange={handleChange}
              value={submitData.values.paypal_transection_id}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              helperText={submitData.errors?.paypal_transection_id ? submitData.errors.paypal_transection_id[0] : ''}
              error={submitData.errors?.paypal_transection_id ? true : false}
            />
          </div>
          }
          {submitData.values.payment_method_id?.code === 'po' &&
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Payoneer Transection ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="payone_transection_id"
              onChange={handleChange}
              value={submitData.values.payone_transection_id}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              helperText={submitData.errors?.payone_transection_id ? submitData.errors.payone_transection_id[0] : ''}
              error={submitData.errors?.payone_transection_id ? true : false}
            />
          </div>
          }
          {submitData.values.payment_method_id?.code === 'bank_vnd' &&
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Bank Transection ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="bank_transection_id"
              onChange={handleChange}
              value={submitData.values.bank_transection_id}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              helperText={submitData.errors?.bank_transection_id ? submitData.errors.bank_transection_id[0] : ''}
              error={submitData.errors?.bank_transection_id ? true : false}
            />
          </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Transfer Contents
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="tranfer_content"
              onChange={handleChange}
              value={submitData.values.tranfer_content}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              GMC code
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="gmc_code"
              onChange={handleChange}
              value={submitData.values.gmc_code}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Creating Service fee manual
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="create_service_manual_fee"
              onChange={handleChange}
              value={submitData.values.create_service_manual_fee}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          {this.props.user?.admin &&
            <div style={{ marginTop: 10 }}>
              <FormControlLabel
                label={<Box fontSize={15} fontWeight="bold">Recheck 2</Box>}
                control={
                  <Checkbox 
                    checked={submitData.values.recheck === 1 ? true : false}
                    onChange={(event) => {
                      const e = {
                        target: {
                          name: 'recheck',
                          value: event.target.checked ? 1 : 0
                        }
                      }
                      handleChange(e)
                    }}
                    color="primary"
                    name={'recheck'}
                  />
                }
              />
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Note
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="note"
              onChange={handleChange}
              value={submitData.values.note}
              variant="outlined"
              minRows={3}
              maxRows={5}
              multiline
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          const schema = {
            team_id: {presence: {message: '^Required' }},
            payment_method_id: {presence: {message: '^Required'}},
            // fud_id: {presence: { message: '^Required' }},
            money_to_wallet: {
              presence: {message: '^Required'},
              numericality: {
                greaterThanOrEqualTo: 0,
                message: '^Number must be greater or equal to 0'
              }
            },
          }
          if (submitData.values.screenshot_transaction_type == 'upload') {
            schema['files'] = {presence: {message: '^Required'}}
          } else {
            schema['screenshot_transaction_link'] = {presence: {message: '^Required'}}
          }
          if (submitData.values.payment_method_id?.code === 'paypal') {
            schema['paypal_fee'] = {
              presence: {message: '^Required'},
              numericality: {
                greaterThanOrEqualTo: 0,
                message: '^Number must be greater or equal to 0'
              }
            }
            schema['paypal_transection_id'] = {
              presence: {allowEmpty: false, message: '^Required'},
              format: {
                pattern: /^[a-zA-Z0-9]+$/,
                message: '^Paypal Transaction is in valid'
              },
            }
          } else if (submitData.values.payment_method_id?.code === 'bank_vnd') {
            schema['bank_transection_id'] = {
              presence: {allowEmpty: false, message: '^Required'},
              format: {
                pattern: /^[a-zA-Z0-9]+$/,
                message: '^Bank Transaction is in valid'
              },
            }
          } else if (submitData.values.payment_method_id?.code === 'po') {
            schema['payone_transection_id'] = {
              presence: {allowEmpty: false, message: '^Required'},
              format: {
                pattern: /^[0-9]*$/,
                message: '^Payoneer Transaction is in valid'
              },
              length: {
                minimum: 9,
                message: '^Transaction has minimum 9 digits'
              },
              numericality: {
                greaterThanOrEqualTo: 286432591,
                message: '^Transaction must greater than 286432591'
              }
            }
          }
          return schema
        },
        onAction: (submitData) => {
          const allKeys = Object.keys(submitData.values)
          const query = {}
          allKeys.map(key => {
            if (key == 'files') {
              query['extra'] = submitData.values[key]
            } else if (typeof submitData.values[key] == 'object' || submitData.values[key] instanceof Object) {
              query[key] = submitData.values[key]?.id || 0
            } else {
              query[key] = submitData.values[key]
            }
          })
          return new Promise((resolve) => {
            request.post('/api/v1/topups', query).then(res => {
              if (res.data.success) {
                var items = Object.assign([], ctx.state?.items)
                items.unshift(res.data.data)
                ctx.setState({ items })
                toastr.success("Add topup successful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/api/v1/topups"
        titlePage="Drebest | 3.1 Topup Wallet request (DB3)"
        title="3.1 Topup Wallet request (DB3)"
        fields={listFields}
        valueKey="topup_id"
        onClickRow={(item) => navigate(`/app1/topup/${item.topup_id}`)}
        actions={[
          {
            text: 'New',
            variant: 'contained',
            icon: <AddIcon />,
            action: (ctx) => this.newTopup(ctx),
            visible: true
          }
        ]}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TopupList);