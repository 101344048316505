/* eslint-disable react/no-set-state */
/* eslint-disable no-redeclare */
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { IconX, IconChevronDown, IconSearch } from '@tabler/icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeId } from '../utils';
import clsx from 'clsx'

const useStyles = (theme) => ({
  container: {
    position: 'relative',
    '&.open>.dropdown': {
      display: 'block'
    }
  },
  dropdown: {
    paddingLeft: 0,
    position: 'absolute',
    top: 40,
    left: -10,
    display: 'none',
    minWidth: 250,
    backgroundColor: '#FFF',
    zIndex: 1000,
    listStyle: 'none',
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    '& li': {
      padding: '0px 12px',
    },
    '& .no-option': {
      color: '#8E8E93',
      fontSize: 14,
      paddingTop: 6,
      paddingBottom: 12
    },
    '& .option': {
      '&:hover': {
        backgroundColor: '#f2fcf2'
      }
    },
    '& .search': {
      paddingTop: 12,
      paddingBottom: 6,
      position: 'relative',
      '& input': {
        width: '100%',
        padding: '6px 12px',
        border: '1px solid #DDDDDD',
        borderRadius: 6,
        '&:focus': {
          border: '1px solid #00AB55',
          outline: 0
        }
      },
      '& svg': {
        position: 'absolute',
        top: 'calc(50% + 3px)',
        right: 20,
        transform: 'translateY(-50%)',
      }
    }
  },
  tag_main: {
    display: "flex",
    flexWrap: "wrap",
    height: 44,
    alignItems: 'center'
  }
});

class MultiInputPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      items: [],
      selectedItems: [],
      value: null,
    };
    this.wrapperRef = React.createRef()
    this.dropdownRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.props.loadOptions) {
      this.props.loadOptions().then(items => {
        this.setState({ items });
      });
    }
    if (this.props.onDelete) {
      this.props.onDelete(this.handleClearOptions);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  handleOpen = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  handleChangeValue = (event) => {
    this.setState({ value: event.target.value }, () => {
      this.props.loadOptions(this.state.value).then(items => {
        this.setState({ items })
      })
    });
  };

  handleSelectItem = (item) => {
    const { valueKey } = this.props;
    const selectedItems = Object.assign([], this.state.selectedItems)
    const index = selectedItems.findIndex(e => e[valueKey || 'id'] == item[valueKey || 'id'])
    if (index !== -1) {
      selectedItems.splice(index, 1)
    } else {
      selectedItems.push(item)
    }
    this.setState({ selectedItems })
    if (this.props.onChange) {
      this.props.onChange(selectedItems)
    }
  }

  handleClearOptions = () => {
    this.setState({ selectedItems: [] })
    if (this.props.onChange) {
      this.props.onChange([])
    }
  }

  render() {
    const { classes, valueKey, getOptionLabel, noOptionsMessage, renderAriaLabel, defaultAriaLabel, disabledSearch } = this.props;
    const { value, items, isOpen, selectedItems } = this.state;
    return (
      <div className={clsx(classes.tag_main, 'pick_wrapper')} ref={this.wrapperRef} key={this.props.key || makeId(10)} onClick={this.handleOpen}>
        <div className={clsx(classes.container, "col-lg-12 pl-0 pr-0", isOpen ? 'open' : '')}>
          <div 
            style={this.props.style || {}} 
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center">
              {this.props.icon}
              <span style={{ padding: '0 4px', fontSize: 14 }}>{selectedItems.length > 0 ? (renderAriaLabel ? renderAriaLabel(selectedItems) : `${selectedItems.length} items selected`) : (defaultAriaLabel || 'Select')}</span>
            </div>
            <div className="d-flex align-items-center">
            {selectedItems.length > 0 && 
              <IconX 
                size={16} 
                stroke={1} 
                cursor={'pointer'}
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleClearOptions()
                }}
              />
            }
            <IconChevronDown size={16} stroke={1} />
            </div>
          </div>
          <ul className={clsx(classes.dropdown, 'dropdown')} ref={this.dropdownRef}>
            {!disabledSearch &&
              <li className="search">
                <IconSearch size={16} color="#c5c6c7" />
                <input
                  autoFocus
                  className="search-bar-input"
                  placeholder="Search"
                  value={value || ""}
                  onChange={this.handleChangeValue}
                />
              </li>
            }
            {items.length == 0 && <li className="no-option">{noOptionsMessage || 'No results found'}</li>}
            <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
              {items.length > 0 && items.map(item => (
                <li className="option" key={item[valueKey || 'id']}>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    label={<span style={{ fontSize: 14 }}>{getOptionLabel ? getOptionLabel(item) : item.name}</span>}
                    control={
                      <Checkbox
                        disableRipple
                        size="small"
                        checked={selectedItems.map(e => e[valueKey || 'id']).includes(item[valueKey || 'id'])}
                        onChange={() => this.handleSelectItem(item)}
                        color="primary"
                      />
                    }
                  />
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>
    );
  }
}


const connectedList = withStyles(useStyles)(MultiInputPicker);
export { connectedList as MultiInputPicker };