import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, toastr } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { fudLevel } from './constants';
import moment from 'moment';

const fields = [
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    renderValue: (item) => `FUD ${item.fud_id}`
  },
  {
    type: 'text',
    name: 'team_name',
    label: 'Team ID & Name',
    disabled: true,
    renderValue: (item) => item.team_id ? `${item.team_search || ''} ${item?.team?.owner_name || ''}` : ''
  },
  {
    type: 'text',
    name: 'type_acc',
    label: 'Acc Type',
    disabled: true,
    renderValue: (item) => item.team?.type_acc
  },
  {
    type: 'text',
    name: 'team_mess_group',
    label: 'Team - Mess group',
    disabled: true,
    renderValue: (item) => item.team?.mess_group_link
  },
  // {
  //   type: 'number',
  //   name: 'cycle_end',
  //   label: 'Hết chu kỳ',
  // },
  {
    type: 'text',
    name: '1st_first_date',
    label: '1st Topup date',
    disabled: true,
    renderValue: (item) => item['1st_first_date'] ? moment(item['1st_first_date']).format('DD/MM/YYYY HH:mm:ss') : ''
  },
  {
    type: 'number',
    name: 'extend_special',
    label: 'Extend Special',
  },
  {
    type: 'text',
    name: 'cycle_end_date',
    label: 'Cycle end date',
    disabled: true,
    renderValue: (item) => item.cycle_end_date ? moment(item.cycle_end_date).format('DD/MM/YYYY HH:mm:ss') : '',
  },
  {
    type: 'text',
    name: 'count_down_day',
    label: 'Count down day',
    disabled: true,
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'The remaining topup',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_topup_to_now',
    label: 'A - Total Topup -> Now',
    disabled: true,
  },
  {
    type: 'number',
    name: 'package_money',
    label: 'Package Money',
    disabled: true,
  },
  {
    type: 'select',
    name: 'level',
    label: 'Level',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = fudLevel.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
        resolve(result)
      })
    },
    valueKey: 'id',
    renderValue: (item) => item.level ? fudLevel.find(e => e.name == item.level) : null,
  },
  {
    type: 'text',
    name: 'content_notice',
    label: 'Nội dung nhắc',
    disabled: true,
  },
  {
    type: 'number',
    name: 'fee_of_pack',
    label: 'Fee of package',
    disabled: true,
  },
  {
    type: 'number',
    name: 'actual_fee_sent',
    label: 'B - Actual fee sent',
    disabled: true,
  },

  {
    type: 'number',
    name: 'fee_by_package',
    label: '% Fee by package',
    disabled: true,
  },
  {
    type: 'number',
    name: 'percent_fee_actual',
    label: '% Fee actual',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'can_use',
    label: 'Can use',
    disabled: true,
    checked: (item) => item.can_use === 1 ? true : false,
  },
  {
    type: 'number',
    name: 'custom_fee',
    label: 'Custom fee',
  },
  {
    type: 'checkbox',
    name: 'cancel',
    label: 'Cancel',
    checked: (item) => item.cancel
  },

  {
    type: 'text',
    name: 'fba_type',
    label: 'FBA type',
    disabled: true,
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
  },
  {
    type: 'checkbox',
    name: 'cycle_end',
    label: 'Hết chu kỳ',
    checked: (item) => item.cycle_end
  },


  {
    type: 'text',
    name: 'discount_code',
    label: 'Discount code',
  },

]

const FudCountForm = (props) => {

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (['cancel', 'cycle_end'].includes(key)) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (key == 'level') {
        query[key] = formState.changes[key].name
        query['package_money'] = formState.changes[key].value
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/fud/${formState.values.fud_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  const [mode, setMode] = useState('view')
  return (
    <ResourceForm
      mode={mode}
      fields={fields}
      route="app1/fud_count"
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/fud/${id}`, { fud_count: 1 }).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="FUD Detail"
      actionBack={() => props.navigate('/app1/fud_counts')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default withRouter(FudCountForm);