import React, { useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { makeId } from '../../../utils'
import { IconTrash } from '@tabler/icons';

const useStyles = theme => ({
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    },
  },
})

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})(TextField);

const TypeAcc = (props) => {
  const { item, disabled, classes, ctx } = props;

  const onChange = (value) => {
    const form = Object.assign({}, ctx.state.formState)
    form.values['type_acc'] = value
    form.changes['type_acc'] = value
    ctx.setState({ formState: form })
  }

  return (
    <div>
      <Box fontSize={15} fontWeight="bold">Type Acc</Box>
      <div className='d-flex flex-column'>
        {item.type_acc?.length > 0 && item.type_acc.map(type => (
          <div className="d-flex align-items-center justify-content-between" style={{ gap: 5 }} key={type.id}>
            <div className='flex-1'>
              <CustomTextField
                disabled={disabled}
                margin="dense"
                InputProps={{
                  classes: {
                    disabled: classes.input
                  },
                }}
                type={'text'}
                name={'type'}
                value={type.name}
                onChange={(event) => {
                  const acc_types = Object.assign([], item.type_acc)
                  const obj = acc_types.find(e => e.id == type.id)
                  obj['name'] = event.target.value
                  onChange(acc_types)
                }}
                fullWidth
                variant="outlined"
              />
            </div>
            {!disabled && 
              <div 
                className="d-flex align-items-center justify-content-center cursor-pointer" 
                style={{ padding: 6, border: '1px solid red', borderRadius: 6 }}
                onClick={() => {
                  const acc_types = item.type_acc?.filter(e => e.id != type.id)
                  if (acc_types.length == 0) {
                    acc_types.push({id: makeId(10), name: ''})
                  }
                  onChange(acc_types)
                }}
              >
                <IconTrash color="red" width={20} height={20} />
              </div>
            }
          </div>
        ))}
      </div>
      {!disabled && 
        <Button 
          className="mt-2"
          onClick={() => {
            const acc_types = Object.assign([], item.type_acc)
            acc_types.push({id: makeId(10), name: ''})
            onChange(acc_types)
          }}
          variant="outlined"
          disabled={ 
            item.type_acc?.length > 0 && item.type_acc[item.type_acc.length - 1].name == ''
          }
        >
          Add
        </Button>
      }
    </div>
  )
}

export default withStyles(useStyles)(TypeAcc)