import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

import {
  Box, Card, Container, Typography, InputAdornment, IconButton, Stack, TextField, Grid, FormControlLabel, Link, Checkbox
} from '@mui/material';
// layouts
// components
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { LoadingButton } from '@mui/lab';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles } from '../utils/selectStyle';
import { mainMarket, timeZoneAcc } from '../components/customer/RegisterTeam/constants';
import { request } from '../_services_'
import { toastr } from '../_helpers_';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  paddingTop: theme.spacing(4),
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Fullname is required'),
    shortname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Shortname is required'),
    facebook_link: Yup.string().required('Facebook link is required'),
    company_name: Yup.string().required('Company name is required'),
    company_shortname: Yup.string().test('len', 'Must be exactly 3 characters', val => val?.length === 3).required('Company short name is required'),
    product_type_id: Yup.object().required('Product type is required'),
  });

  const loadOptions = (inputValue, loadingData) => {
    return loadingData(inputValue)
  };

  const handleInputChange = (newValue) => {
    return newValue;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullname: "",
      shortname: "",
      facebook_link: "",
      company_name: "",
      company_shortname: "",
      product_type_id: null
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      var data = {}
      var keys = Object.keys(values)
      keys.map(key => {
        if (typeof values[key] === 'object') {
          if (key === 'product_type_id') {
            data[key] = values[key].product_type_id
          } else if (key === 'source_id') {
            data[key] = values[key].source_id
          } else if (key === 'support_channel_id') {
            data[key] = values[key].support_channel_id
          } else if (['main_market', 'time_zone'].includes(key)) {
            data[key] = values[key].value
          }
        } else if (key !== "agree_term") {
          data[key] = values[key]
        }
      })
      request.post('/api/v1/register_team', data).then(res => {
        formik.setSubmitting(false)
        if (res.data.success) {
          navigate('/login')
          toastr.success("create team success. Login and use app")
        } else {
          toastr.success(res.data.msg)
        }
      }, error => {
        formik.setSubmitting(false)
        toastr.error(error?.response?.data?.msg || error.toString())
      })
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;

  return (
    <RootStyle title="Register | Minimal-UI">
      <AuthLayout>
        Already have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          Login
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Manage the job more effectively with Minimal
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>

      <Container>
        <ContentStyle>
          <Grid>
            <div style={{ padding: "10px 5px" }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div style={{
                    position: '-webkit - sticky',
                    position: 'sticky',
                    top: -24,
                    paddingTop: 24,
                    background: '#fff',
                    zIndex: 10
                  }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                      <Typography variant="h4" gutterBottom>
                        Register new team
                      </Typography>
                      <LoadingButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        loading={isSubmitting}
                        // disabled={!values.agree_term}
                        type="submit"
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div>
                        <Box className='mb-1' fontWeight={"bold"}>Email address <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          autoComplete="new-email"
                          type="email"
                          placeholder="Email address"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box fontWeight={"bold"}>Password <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          // autoComplete="new-password"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Password"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Fullname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Fullname"
                          {...getFieldProps('fullname')}
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Shortname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Shortname"
                          {...getFieldProps('shortname')}
                          error={Boolean(touched.shortname && errors.shortname)}
                          helperText={touched.shortname && errors.shortname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB profile name</Box>
                        <TextField
                          fullWidth
                          placeholder="FB profile name"
                          {...getFieldProps('facebook_profile_name')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB link <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="FB link"
                          {...getFieldProps('facebook_link')}
                          error={Boolean(touched.facebook_link && errors.facebook_link)}
                          helperText={touched.facebook_link && errors.facebook_link}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Phone number</Box>
                        <TextField
                          fullWidth
                          placeholder="Phone"
                          {...getFieldProps('phone')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Team/Company fullname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Team/ Company fullname"
                          {...getFieldProps('company_name')}
                          error={Boolean(touched.company_name && errors.company_name)}
                          helperText={touched.company_name && errors.company_name}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Team/Company shortname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Team/Company shortname"
                          {...getFieldProps('company_shortname')}
                          error={Boolean(touched.company_shortname && errors.company_shortname)}
                          helperText={touched.company_shortname && errors.company_shortname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main Product Type <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/product_types', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                }
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="product_type_id"
                          onChange={(value) => setFieldValue('product_type_id', value)}
                          placeholder={"Main product type"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ product_type_id }) => product_type_id}
                          valueKey={"product_type_id"}
                          value={values.product_type_id}
                          styles={Boolean(touched.product_type_id && errors.product_type_id) ? errorStyles : customStyles}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main web <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Main web"
                          {...getFieldProps('main_web')}
                          error={Boolean(touched.main_web && errors.main_web)}
                          helperText={touched.main_web && errors.main_web}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main fanpage</Box>
                        <TextField
                          fullWidth
                          placeholder="Main Fanpage"
                          {...getFieldProps('main_fanpage')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main market <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              resolve(mainMarket)
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="main_market"
                          onChange={(value) => setFieldValue('main_market', value)}
                          placeholder={"Main market"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ value }) => value}
                          valueKey={"value"}
                          value={values.main_market}
                          styles={Boolean(touched.main_market && errors.main_market) ? errorStyles : customStyles}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Timezone Acc <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              resolve(timeZoneAcc)
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="time_zone"
                          onChange={(value) => setFieldValue('time_zone', value)}
                          placeholder={"Timezone Acc"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ label }) => label}
                          getOptionValue={({ value }) => value}
                          valueKey={"value"}
                          value={values.time_zone}
                          styles={Boolean(touched.time_zone && errors.time_zone) ? errorStyles : customStyles}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Number of Ads Acc</Box>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder="Number of Ads Acc"
                          {...getFieldProps('number_ads')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Spent/month (USD) <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder="Spent/month (USD)"
                          {...getFieldProps('spent_per_month')}
                          error={Boolean(touched.spent_per_month && errors.spent_per_month)}
                          helperText={touched.spent_per_month && errors.spent_per_month}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>How did you know us? <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/sources', { search: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                } else {
                                  resolve([])
                                }
                              }, error => {
                                resolve([])
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="source_id"
                          onChange={(value) => setFieldValue('source_id', value)}
                          placeholder={"How did you know us?"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={({ source_id }) => source_id}
                          valueKey={"source_id"}
                          value={values.source_id}
                          styles={Boolean(touched.source_id && errors.source_id) ? errorStyles : customStyles}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Detail info / link</Box>
                        <TextField
                          fullWidth
                          placeholder="Detail info"
                          {...getFieldProps('source_link')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main communication/support channel? <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/channels', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                } else {
                                  resolve([])
                                }
                              }, error => {
                                resolve([])
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="support_channel_id"
                          onChange={(value) => setFieldValue('support_channel_id', value)}
                          placeholder={"Main communication/support channel"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={({ support_channel_id }) => support_channel_id}
                          valueKey={"support_channel_id"}
                          value={values.support_channel_id}
                          styles={Boolean(touched.support_channel_id && errors.support_channel_id) ? errorStyles : customStyles}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Detail info / link</Box>
                        <TextField
                          fullWidth
                          disabled
                          margin='dense'
                          name="finish_setting"
                          value={"https://www.messenger.com/t/1398722322"}
                          variant="outlined"
                          InputProps={{
                            classes: {
                              notchedOutline: 'notchedOutline'
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => window.open('https://www.messenger.com/t/1398722322', '_blank')}>
                                  <LaunchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                      <div className='mt-2'>
                        {/* <FormControlLabel
                          color="primary"
                          control={
                            <Checkbox
                              checked={values.agree_term}
                              color="primary"
                              disableRipple
                              name='agree_term'
                              onChange={(event) => setFieldValue('agree_term', event.target.checked)}
                            />
                          }
                          label={ */}
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          By register you agree to Drebest&nbsp;
                          <Link href="https://tiny.cc/DBrate" target={"_blank"} underline="always" sx={{ color: 'text.primary' }}>
                            Terms of Service and Privacy Policy.
                          </Link>
                        </Typography>
                        {/* }
                        /> */}
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </div>
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
