import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';


export const listFields = [
  {
    name: 'code',
    label: 'Acc code',
    sort:true,
    filter:true
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item.team_id?.team_search} ${item?.team_id?.owner_name || ''}` : '',
    sort:true,
    sortName:'teams.team_search',
    sort:true,
    sortName:'teams.team_search|teams.owner_name*1',
  },
  
  {
    name: 'name',
    label: 'Acc Name',
    sort:true,
    sort:true,

  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort:true,
    sort:true,

  },
  {
    name: 'spending_limit',
    label: 'Delta Spending Limit',
    sort:true,
  },
  {
    name: 'spent_current',
    label: 'Delta Spent',
    sort:true,
  },
  {
    name: 'remaining',
    label: 'Delta Remaining',
    sort:true,
  },
  {
    name: 'topup_processing',
    label: 'Topup in Processing',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Acc Timezone',
    sort:true,
  },
  {
    name: 'status',
    label: 'Status',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Ads Manager',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Acc Quality',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Payment Settings',
    sort:true,
  },
  {
    name: 'partner_code',
    label: 'Partner code',
    sort:true,
    sort:true,

  },
  {
    name: 'keep_deposit',
    label: 'Keep deposit',
    sort:true,
  },
  {
    name: 'using',
    label: 'Using',
    sort:true,
  }
]

export const formFields = [
 
]