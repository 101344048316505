import { Checkbox } from "@mui/material"

export const listFields = [
  {
    name: 'name',
    label: 'Package name',
    sort: true,
    width: '8rem'
  },
  {
    name: 'total_topup_30days',
    label: 'Total topup',
    renderValue: (item) => item.total_topup_30days?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort: true,
    width: '8rem'
  },
  {
    name: 'FS2',
    label: 'FS2',
    sort: true,
    renderValue: (item) => item['FS2'] ? `${item['FS2']}%` : '',
    width: '4rem'
  },
  {
    name: 'FSN',
    label: 'FSN',
    sort: true,
    renderValue: (item) => item['FSN'] ? `${item['FSN']}%` : '',
    width: '4rem'
  },
  {
    name: 'FV2',
    label: 'FV2',
    sort: true,
    renderValue: (item) => item['FV2'] ? `${item['FV2']}%` : '',
    width: '4rem'
  },
  {
    name: 'FVN',
    label: 'FVN',
    sort: true,
    renderValue: (item) => item['FVN'] ? `${item['FVN']}%` : '',
    width: '4rem'
  },
  {
    name: 'GSK',
    label: 'GSK',
    sort: true,
    renderValue: (item) => item['GSK'] ? `${item['GSK']}%` : '',
    width: '4rem'
  },
  {
    name: 'GSC',
    label: 'GSC',
    sort: true,
    renderValue: (item) => item['GSC'] ? `${item['GSC']}%` : '',
    width: '4rem'
  },
  {
    name: 'GVK',
    label: 'GVK',
    sort: true,
    renderValue: (item) => item['GVK'] ? `${item['GVK']}%` : '',
    width: '4rem'
  },
  {
    name: 'GVC',
    label: 'GVC',
    sort: true,
    renderValue: (item) => item['GVC'] ? `${item['GVC']}%` : '',
    width: '4rem'
  },
  {
    name: 'hidden',
    label: 'Hidden',
    renderValue: (item) => <Checkbox checked={item.hidden} disabled color="primary"/>,
    width: '4rem'
  }
]

export const formFields = [
  {
    type: 'text',
    name: 'name',
    label: 'Package name',
  },
  {
    type: 'number',
    name: 'total_topup_30days',
    label: 'Total Topup',
  },
  {
    type: 'number',
    name: 'FS2',
    label: 'FS2',
  },
  {
    type: 'number',
    name: 'FSN',
    label: 'FSN',
  },
  {
    type: 'number',
    name: 'FV2',
    label: 'FV2',
  },
  {
    type: 'number',
    name: 'FVN',
    label: 'FVN',
  },
  {
    type: 'number',
    name: 'GSK',
    label: 'GSK',
  },
  {
    type: 'number',
    name: 'GSC',
    label: 'GSC',
  },
  {
    type: 'number',
    name: 'GVK',
    label: 'GVK',
  },
  {
    type: 'number',
    name: 'GVC',
    label: 'GVC',
  },
  {
    type: 'checkbox',
    name: 'hidden',
    label: 'Hidden',
    checked: (item) => item.hidden || false 
  }
]