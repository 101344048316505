import React, { Component } from 'react'
import { listRef2ReviewFields, partnerCodes } from './fields';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'
import { IconBrandMessenger } from '@tabler/icons';

const fields = [
  {
    name: 'code',
    label: 'Acc Code',
    sort:true,
    filter:true,

  },
  {
    name: 'RAC_number',
    label: 'RAC group',
    sort:true,
    sortName:'"RAC_number"',
    filter:true,
    filterName:'RAC_number',
  },
  {
    name: '"REF_number"',
    label: 'REF Number Manual',
    sort:true,
    filter:true,
    filterName:'REF_number',

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_username',
    label: 'Team Seller Username',
    renderValue: (item) => item?.team_id ? `${item.team_id?.team_search || ''} ${item?.team_id?.owner_name || ''}` : '',
    sort:true,
    sortName:'team_id',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1',

  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort:true,
    filter:true,

  },
  {
    name: 'br_business',
    label: 'BR Business Name',
    renderValue: (item) => item.ref_id?.rac_id ? item.ref_id.rac_id.br_business : '',
    sort:true,
    filter:true,
    filterName:'ref_reviews.rac_number.br_business@ilike',

  },
  {
    name: 'timezone',
    label: 'Acc Timezone',
    sort:true,

  },
  {
    name: 'sin_id',
    label: 'SIN Number',
    renderValue: (item) => item.ref_id?.sin_id ? `${item.ref_id.sin_id.sin_number}` : '',
    sort:true,
    sortName:'ref_reviews.signup_webinfo.sin_number',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.sin_number',

  },
  {
    name: 'partner_code',
    label: 'Partner code',
    renderValue: (item) => item.ref_id?.sin_id?.partner_code,
    sort:true,
    sortName:'ref_reviews.signup_webinfo.partner_code',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.partner_code',
  },
  {
    name: 'name',
    label: 'Acc Name',
    sort:true,
    filter:true,

  },
  {
    name: 'create_date',
    label: 'Time created acc',
    renderValue: (item) => moment(item.create_date).format('DD/MM/YYYY HH:mm:ss'),
    sort:true,
  },
  {
    name: 'bm_id',
    label: 'BM ID Manual',
    renderValue: (item) => item.ref_id?.sin_id?.bm_id,
    sort:true,
    sortName:'ref_reviews.signup_webinfo.bm_id',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.bm_id',

  },
  {
    name: 'bm_name_2',
    label: 'BM Name Link 2 (Manual)',
    renderValue: (item) => item.ref_id?.sin_id?.bm_name_2,
    sort:true,
    sortName:'ref_reviews.signup_webinfo.bm_name_2',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.bm_name_2',
  },
  {
    name: 'bm2_id',
    label: 'BM ID Link 2 (Manual)',
    renderValue: (item) => item.ref_id?.sin_id?.bm2_id,
    sort:true,
    sortName:'ref_reviews.signup_webinfo.bm2_id',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.bm2_id',
  },
  {
    name: 'share_acc_to_bm',
    label: 'Share Acc to BM',
    renderValue: (item) => <Checkbox disabled checked={item.share_acc} color="primary" />,
    sort:true,
  },
  {
    name: 'permanently_disabled',
    label: 'Permanently Disabled',
    renderValue: (item) => <Checkbox disabled checked={item.permanently_disabled} color="primary" />,
    sort:true,
  },
  {
    name: 'keep_deposit',
    label: 'Keep Deposit',
    renderValue: (item) => item.keep_deposit ? <NumberFormat value={item.keep_deposit || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'spent_current',
    label: 'Spent',
    renderValue: (item) => item.spent_current ? <NumberFormat value={item.spent_current || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'spending_limit',
    label: 'Acc Spending Limit',
    renderValue: (item) => item.spending_limit ? <NumberFormat value={item.spending_limit || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'status',
    label: 'Status',
    sort:true,
  },
  {
    name: 'disable_date',
    label: 'Disabled date',
    renderValue: (item) => item.disable_date ? moment(item.disable_date).format('DD/MM/YYYY HH:mm:ss') : '',
    sort:true,
  },
  {
    name: 'spending_limit_1',
    label: 'Spending limit 1 (Before)',
    renderValue: (item) => item.spending_limit_1 ? <NumberFormat value={item.spending_limit_1 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'spent_1',
    label: 'Spent 1 (Before)',
    renderValue: (item) => item.spent_1 ? <NumberFormat value={item.spent_1 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'remaining_1',
    label: 'Remaining 1 (Before)',
    renderValue: (item) => item.remaining_1 ? <NumberFormat value={item.remaining_1 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'cleared_spent',
    label: 'Spent (Cleared)',
    renderValue: (item) => item.cleared_spent ? <NumberFormat value={item.cleared_spent || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'cleared_data',
    label: 'Cleared data',
    renderValue: (item) => <Checkbox disabled checked={item.cleared_data} color="primary" />,
    sort:true,
  },
  {
    name: 'image_payment_setting',
    label: 'Image payment setting',
    renderValue: (item) => item.image_payment_setting ? <NumberFormat value={item.image_payment_setting || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
    width: '12rem'
  },
  {
    name: 'spending_limit_2',
    label: 'Spending limit 2 (After)',
    renderValue: (item) => item.spending_limit_2 ? <NumberFormat value={item.spending_limit_2 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'spent_2',
    label: 'Spent 2 (After)',
    renderValue: (item) => item.spent_2 ? <NumberFormat value={item.spent_2 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'remaining_2',
    label: 'Remaining 2 (After)',
    renderValue: (item) => item.remaining_2 ? <NumberFormat value={item.remaining_2 || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'spent_2_manual',
    label: 'Spent 2 (After) manual',
    renderValue: (item) => item.spent_2_manual ? <NumberFormat value={item.spent_2_manual || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'remaining',
    label: 'Remaining',
    renderValue: (item) => item.remaining ? <NumberFormat value={item.remaining || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'cannot_access',
    label: "Can't access",
    renderValue: (item) => <Checkbox disabled checked={item.cannot_access} color="primary" />,
    sort:true,
  },
  {
    name: 'stop',
    label: "Stop",
    renderValue: (item) => <Checkbox disabled checked={item.stop} color="primary" />,
    sort:true,
  },
  {
    name: 'time_stop',
    label: "Time stop",
    renderValue: (item) => item.time_stop ? moment(item.time_stop).format('DD/MM/YYYY HH:mm:ss') : '',
    sort:true,
  },
  {
    name: 'solution',
    label: "Giải pháp",
    renderValue: (item) => item.solution ? <span className='line-clamp-1'>{item.solution}</span> : '',
    sort:true,
  },
  {
    name: 'revoke',
    label: 'Thu hồi',
    renderValue: (item) => <Checkbox disabled checked={item.revoke} color="primary" />,
    sort:true,
  }
]

class RacWarehouseList extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/api/v1/newids"
        titlePage="Drebest | 7.2 RAC Warehouse"
        title="7.2 RAC Warehouse"
        fields={fields}
        defaultFilters={{type_acc: 'RAC', list:'warehouse'}}
        valueKey="id"
        onClickRow={(item) => navigate(`/app3/rac_warehouse/${item.public_id}`)}
      />
    )
  }

}

export default RacWarehouseList;