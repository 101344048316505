import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toastr, withRouter } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import PriceSharings from './PriceSharing';

const fields = [
  {
    type: 'text',
    name: 'code',
    label: 'Product code',
  },
  {
    type: 'text',
    name: 'title',
    label: 'Product Name',
  },
  {
    type: 'number',
    name: 'price',
    label: 'Price',
  },
  {
    type: 'number',
    name: 'cycle_end',
    label: 'Cycle end',
  },
  {
    type: 'number',
    name: 'commission_rate',
    label: 'Commission rate (%)',
  },
  {
    type: 'custom',
    name: 'sharings',
    label: 'Price Sharings',
    renderValue: (item, handleChange, disabled) => <PriceSharings submitData={item} handleChange={handleChange} disabled={disabled} />
  }
]

const ProductForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, submitData) => {
    const allKeys = Object.keys(submitData.changes)
    const query = {}
    allKeys.map(key => {
      if (key == 'sharings') {
        const sharings = []
        submitData.values[key].map(sh => {
          sharings.push({key: sh.key, value: sh.value})
        })
        query[key] = sharings
      } else {
        query[key] = submitData.values[key]
      }
    })
    request.put(`/api/v1/product/${submitData.values.id}`, query).then(res => {
      if (res.data.success) {
        toastr.success("Update successfully")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err)
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={fields}
      route="app1/product"
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/product/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Product detail"
      actionBack={() => props.navigate('/app1/products')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default withRouter(ProductForm);