import React, { Component } from "react";
import tinymce from "tinymce";
import "tinymce/themes/silver";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/table";
import axios from "axios";
import { authHeaders } from '../_helpers_'

class TinyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: null
    };
  }

  componentDidMount() {
    this.initEditor(this.props)
  }

  componentDidUpdate(prevProps){
    if(this.props.detectReset != prevProps.detectReset){
      this.onSetContent(this.props.content);
    }
  }

  initEditor = (props) => {
    const { toolbar } = props;
    const default_tolbar = {
      toolbar1:
        "bold italic strikethrough underline | alignleft aligncenter alignright alignjustify | image media link anchor | code outdent indent",
      toolbar2:
        "numlist bullist | forecolor backcolor removeformat pagebreak | table",
      toolbar3: "fontselect fontsizeselect styleselect",
    };
    var init = {
      selector: `#${props.id}`,
      plugins: "link image autoresize media anchor code pagebreak lists table autolink",
      autoresize_bottom_margin: 50,
      images_upload_url: `${process.env.REACT_APP_API_URL}/api/v1/upload_image`,
      toolbar_sticky: true,
      toolbar_sticky_offset: 60,
      images_upload_handler: function (blobInfo, success, failure) {
        setTimeout(function () {
          var formData = new FormData();
          formData.append("file", blobInfo.blob());
          // let user = JSON.parse(localStorage.getItem("user_hebecore"));
          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/api/v1/upload_image`,
            headers: authHeaders(),
            data: formData,
          }).then((res) => {
            if (res.status == 200) {
              success(res.data.data.url);
            } else {
              failure();
            }
          })
            .catch((err) => {
              failure();
            });
        }, 2000);
      },
      menubar: false,
      classes: "error-xxxx",
      resize: true,
      statusbar: false,
      setup: (editor) => {
        editor.on("keyup change", () => {
          const content = editor.getContent();
          props.onEditorChange(content);
        });
      },
      file_picker_types: 'file image media',
      file_picker_callback: function (callback, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();

          var fd = new FormData();
          var files = file;
          fd.append("file", files);

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/api/v1/upload_image`,
            headers: authHeaders(),
            data: fd,
          }).then((res) => {
            reader.onload = function (e) {
              callback(res.data.data.url, meta)
            };
            reader.readAsDataURL(file);
          })

          return
        };
        input.click();
      },
    };
    if (toolbar) {
      init = { ...init, ...toolbar };
    } else {
      init = { ...init, ...default_tolbar };
    }
    tinymce.init(init);
    this.setState({ editor: tinymce.activeEditor })
    if (props.content) {
      this.onSetContent(props.content);
    }
  }

  componentWillUnmount() {
    tinymce.remove(this.state.editor);
  }

  onSetContent = (description) => {
    tinymce.activeEditor.setContent(description);
  };

  render() {
    return (
      <textarea
        id={this.props.id}
        value={this.props.content}
        onChange={(e) => console.log(e)}
        style={this.props.style || {}}
        className={this.props.className || ""}
        placeholder={this.props.placeholder || ""}
      />
    );
  }
}

export default TinyEditor;
