// ----------------------------------------------------------------------

export default function FormControlLabel(theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: '#000'
          }
        }
      }
    }
  };
}
