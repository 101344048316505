import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { authentication } from  './authentication'
import { locale } from  './locale'
import { alert } from  './alert'
import { setting } from  './setting'
import { openSnackbar } from './snackbar'


const rootReducer = combineReducers({
  alert,
  authentication,
  locale,
  loadingBar: loadingBarReducer,
  setting,
  openSnackbar
});

export default rootReducer;