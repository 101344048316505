import NumberFormat from 'react-number-format';
import React from 'react';

const NumericString = React.forwardRef(function NumericString(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={true}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowLeadingZeros={true}
      thousandSeparator=''
      isNumericString
    />
  );
});

export default NumericString