import React, { useRef } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles, makeId } from '../../../utils';
import { ModalForm, ResourceList, TinyEditor } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

export const typeTemplates = [
  {
    code: 'fud_expired',
    name: 'FUD expired',
    note: 'Variable: [[fud_id]], [[team_id]], [[cycle_end_day]], [[remaining_topup]]'
  },
  {
    code: 'fud_warning_expired',
    name: 'FUD warning expired',
    note: 'Variable: [[fud_id]], [[team_id]], [[cycle_end_day]], [[remaining_topup]]'
  }
]

const fields = [
  {
    name: 'type',
    label: 'Type',
    sort: true,
    width: '10rem',
    renderValue: (item) => typeTemplates.find(e => e.code == item.type).name
  },
  {
    name: 'title',
    label: 'Title',
    sort: true,
    width: '15rem'
  },
  {
    name: 'content',
    label: 'Content',
    sort: true,
    width: '50rem',
    renderValue: (item) => <div dangerouslySetInnerHTML={{__html: item.content}} />
  }
]

const TemplateList = (props) => {
  const editorRef = useRef();

  const createTemplate = (item, ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New template',
      data: item || {},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Type
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = typeTemplates.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="type"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'type',
                    value: value.code
                  }
                }
                handleChange(event)
              }}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
              valueKey={"code"}
              value={typeTemplates.find(e => e.code == submitData.values.type)}
              styles={submitData.errors?.type ? errorStyles : customStyles}
            />
          </div>
          { submitData.values.type &&
            <>
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  Title <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="title"
                  onChange={handleChange}
                  value={submitData.values.title}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.title}
                  helperText={submitData.errors?.title ? submitData.errors.title[0] : ''}
                />
              </div>
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  Content <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  margin='dense'
                  name="content"
                  onChange={handleChange}
                  value={submitData.values.content}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.content}
                  helperText={submitData.errors?.content ? submitData.errors.content[0] : ''}
                />
              </div>
              {/* <div className='mt-2 d-editor'>
                <Box fontSize={15} fontWeight='bold'>
                  Content <span style={{ color: 'red' }}>*</span>
                </Box>
                <TinyEditor
                  className={submitData.errors?.content ? 'error' : ''}
                  style={{ width: '100%' }}
                  content={submitData.values.content}
                  id={makeId(20)}
                  ref={editorRef}
                  onEditorChange={(content) => {
                    const event = {
                      target: {
                        name: 'content',
                        value: content
                      }
                    }
                    handleChange(event)
                  }}
                />
                {submitData.errors?.content && <small style={{ color: 'red'}}>{submitData.errors.content[0]}</small>}
              </div> */}
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold' sx={{textDecoration: 'underline'}}>
                  Note:
                </Box>
                <Box fontSize={15}>
                  {typeTemplates.find(e => e.code == submitData.values.type).note}
                </Box>
              </div>
            </>
          }
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          var schema = {
            type: {
              presence: { allowEmpty: false, message: '^Required' },
            }
          }
          if (submitData.values.type) {
            schema['title'] = {
              presence: { allowEmpty: false, message: '^Required' }
            }
            schema['content'] = {
              presence: { allowEmpty: false, message: '^Required' }
            }
          }
          return schema
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            if (item) {
              request.put(`/api/v1/template/${item.id}`, submitData.values).then(res => {
                if (res.data.success) {
                  const items = ctx.state.items.map(e => e.id == item.id ? res.data.data : e)
                  ctx.setState({ items })
                  resolve(true);
                  toastr.success("Save template successful")
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            } else {
              request.post('/api/v1/templates', submitData.values).then(res => {
                if (res.data.success) {
                  const items = Object.assign([], ctx.state.items)
                  items.push(res.data.data)
                  ctx.setState({ items, totals: ctx.state.totals + 1 })
                  resolve(true);
                  toastr.success("Save template successful")
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            }
          })
        }
      }
    })
  }

  return (
    <ResourceList 
      route="/api/v1/templates"
      titlePage="Drebest | Template"
      actions={[
        {
          text: 'New',
          variant: 'contained',
          icon: <AddIcon />,
          action: (ctx) => createTemplate(null, ctx),
          visible: true
        }
      ]}
      title="Template"
      fields={fields}
      valueKey="id"
      onClickRow={(item, ctx) => createTemplate(item, ctx)}
    />
  )

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TemplateList)