import { request } from '../../../_services_';
import { Avatar, Box, Button, Checkbox, CircularProgress, Tooltip } from '@mui/material';
import { capitalizeStr, handleInputChange, loadOptions, errorStyles, customStyles, disabledStyles } from '../../../utils'
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import DatePicker from "react-datepicker";

export const typeList = [
  { id: 'percent', name: 'Percent' },
  { id: 'amount', name: 'Amount' }
]

export const typeTeam = [
  { id: 'all', name: 'All Team' },
  { id: 'rai', name: 'Team RAI' },
  { id: 'rac', name: 'Team RAC' },
  { id: 'gg', name: 'Team GG' },
  { id: 'tiktok', name: 'Team TikTok' },
  { id: 'facebook', name: 'Team Facebook' },
  { id: 'specific', name: 'Specific Team' },
]

export const productApply = [
  { id: 'all', name: 'All Service' },
  { id: 'specific', name: 'Specific Service' }
]

export const discountFields = [
  // {
  //   name: 'id',
  //   label: 'ID',
  //   renderValue: (item) => item.id,
  //   sort: true,
  //   filter: true
  // },
  {
    name: 'title',
    label: 'Discount Name',
    renderValue: (item) => item.title,
    sort: true,
    filter: true
  },
  {
    name: 'code',
    label: 'Code',
    renderValue: (item) => item.code,
    filter: true
  },
  {
    name: 'discount_type',
    label: 'Discount Type',
    renderValue: (item) => typeList.find(e => e.id == item.discount_type)?.name || '',
    sort: true,
    filter: true
  },
  {
    name: 'value',
    label: 'Value',
    renderValue: (item) => item.value,
    sort: true,
    filter: true
  },
  {
    name: 'start_date',
    label: 'Start date',
    renderValue: (item) => moment(item.start_date).format('DD-MM-YYYY'),
    sort: true,
    filter: true,
  },
  {
    name: 'end_date',
    label: 'End date',
    renderValue: (item) => item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : '',
    sort: true,
    filter: true,
  },
  {
    name: 'team_kind',
    label: 'Type of Team',
    renderValue: (item) => typeTeam.find(e => e.id == item.team_kind)?.name || '',
    sort: true,
    filter: true
  },
  {
    name: 'team_ids',
    label: 'Teams',
    renderValue: (item) => 
      <Tooltip title={item.team_ids?.map(e => e.name).join(", ")} arrow placement='top'>
        <div>{item.team_ids?.map(e => e.name).join(", ")}</div>
      </Tooltip>
    ,
    sort: true,
    filter: true
  },
  {
    name: 'product_kind',
    label: 'Service Apply',
    renderValue: (item) => productApply.find(e => e.id == item.product_kind)?.name || '',
    sort: true,
    filter: true
  },
  {
    name: 'product_ids',
    label: 'Services',
    renderValue: (item) => 
      <Tooltip title={item.product_ids?.map(e => e.name).join(", ")} arrow placement='top'>
        <div>{item.product_ids?.map(e => e.name).join(", ")}</div>
      </Tooltip>,
    sort: true,
    filter: true
  },
  {
    name: 'number_of_uses',
    label: 'Number of uses',
    renderValue: (item) => item.number_of_uses,
    sort: true,
    filter: true
  },
  {
    name: 'limit_number_of_uses',
    label: 'Limit number of uses',
    renderValue: (item) => item.limit_number_of_uses,
    sort: true,
    filter: true
  },
  {
    name: 'status',
    label: 'status',
    renderValue: (item) => item.status,
    sort: true,
    filter: true
  },
  {
    name: 'disabled',
    label: 'Disabled',
    renderValue: (item) => <Checkbox checked={item.disabled} disabled color="primary"/>
  }
]

export const discountFormFields = [

  // {
  //   type: 'text',
  //   name: 'id',
  //   label: 'ID',
  //   renderValue: (item) => item.id,
  //   disabled: true
  // },
  {
    type: 'text',
    name: 'code',
    label: 'Code',
    renderValue: (item) => item.code,
    disabled: true
  },
  {
    type: 'text',
    name: 'title',
    label: 'Discount Name',
    renderValue: (item) => item.title,
  },
  {
    type: 'select',
    name: 'discount_type',
    label: 'Discount Type',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = typeList.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => typeList.find(e => e.id == item.discount_type),
  },
  {
    type: 'number',
    name: 'value',
    label: 'Value',
    renderValue: (item) => item.value
  },
  {
    type: 'custom',
    name: 'date',
    renderValue: (item, ctx) => 
    <div className='d-flex' style={{ marginTop: 10, gap: 5 }}>
      <div style={{ width: '50%' }}>
        <Box fontSize={15} fontWeight='bold'>
          Start date
        </Box>
        <DatePicker
          disabled={ ctx.props.mode == 'view' ? true : false }
          name='start_date'
          className='mt-1 font-date'
          wrapperClassName='custom-picker'
          popperClassName='custom-popper-picker'
          selected={item.start_date ? new Date(item.start_date) : null}
          onChange={(value) => {
            const event = {
              target: {
                name: 'start_date',
                value
              }
            }
            ctx.handleChange(event)
          }}
          dateFormat='dd/MM/yyyy'
        />
        {item?.errors?.start_date && <span style={{ color: 'red', fontSize: 12 }}>{item?.errors?.start_date[0]}</span>}
      </div>
      <div style={{ width: '50%' }}>
        <Box fontSize={15} fontWeight='bold'>
          End date
        </Box>
        <DatePicker
          disabled={ ctx.props.mode == 'view' ? true : false }
          name='end_date'
          className='mt-1 font-date'
          wrapperClassName='custom-picker'
          popperClassName='custom-popper-picker'
          selected={item.end_date ? new Date(item.end_date) : null}
          onChange={(value) => {
            const event = {
              target: {
                name: 'end_date',
                value
              }
            }
            ctx.handleChange(event)
          }}
          dateFormat='dd/MM/yyyy'
        />
      </div>
    </div>
  },
  {
    type: 'custom',
    name: 'team_kind',
    label: 'Type of Team',
    renderValue: (item, ctx) => 
    <>
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight='bold'>
          Type of Team
        </Box>
        <AsyncSelect
          isDisabled={ ctx.props.mode == 'view' ? true : false }
          className="MuiFormControl-marginDense"
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
            return new Promise(resolve => {
              const result = typeTeam.filter(e => e.name.includes((inputValue || '').toUpperCase()))
              resolve(result)
            })
          })}
          defaultOptions
          onInputChange={handleInputChange}
          isSearchable
          name={"team_kind"}
          onChange={(value) => {
            var e = {
              target: {
                name: "team_kind",
                value,
                depends: [{
                  route: '/admin/v1/teams',
                  query: { 'team_kind': value.id },
                  key: 'id'
                }]
              },
            };
            ctx.handleChange(e)
          }}
          placeholder={"Type of Team"}
          menuPortalTarget={document.body}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          valueKey={"id"}
          value={typeTeam.find(e => e.id == item.team_kind) || item.team_kind}
          styles={ctx?.props?.mode == 'view' ? disabledStyles : customStyles}
        />
        {ctx.props?.submitData?.errors?.team_kind && <span style={{ color: 'red', fontSize: 12 }}>{ctx.props?.submitData.errors?.team_kind[0]}</span> }
      </div>
      { ['specific'].includes(item?.team_kind?.id || item?.team_kind) && 
        <div style={{ marginTop: 10 }}>
          <AsyncSelect
            isDisabled={ ctx.props.mode == 'view' ? true : false }
            className="MuiFormControl-marginDense"
            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
              return new Promise(resolve => {
                request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items.map(e => ({ id: e.team_id, name: `Team ${e.team_id}` })))
                  }
                })
              })
            })}
            defaultOptions
            onInputChange={handleInputChange}
            isSearchable
            isMulti
            name="team_ids"
            onChange={(value) => {
              const event = {
                target: {
                  name: 'team_ids',
                  value
                }
              }
              ctx.handleChange(event)
            }}
            placeholder={"Teams"}
            menuPortalTarget={document.body}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            valueKey={"id"}
            value={item?.team_ids}
            styles={ctx?.props?.mode == 'view' ? disabledStyles : customStyles}
          />
          {ctx.props?.submitData?.errors?.team_ids  && <span style={{ color: 'red', fontSize: 12 }}>{ctx.props?.submitData?.errors?.team_ids [0]}</span> }
        </div>
      }
    </>,
  },
  {
    type: 'custom',
    name: 'product_kind',
    renderValue: (item, ctx) => 
    <>
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight='bold'>
          Service Apply
        </Box>
        <AsyncSelect
          isDisabled={ ctx.props.mode == 'view' ? true : false }
          className="MuiFormControl-marginDense"
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
            return new Promise(resolve => {
              const result = productApply.filter(e => e.name.includes((inputValue || '').toUpperCase()))
              resolve(result)
            })
          })}
          defaultOptions
          onInputChange={handleInputChange}
          isSearchable
          name={"product_kind"}
          onChange={(value) => {
            var e = {
              target: {
                name: "product_kind",
                value,
                depends: [{
                  route: '/api/v1/products',
                  query: { 'product_kind': value.id },
                  key: 'id'
                }]
              },
            };
            ctx.handleChange(e)
          }}
          placeholder={"Service Apply"}
          menuPortalTarget={document.body}
          getOptionLabel={({ name }) => name}
          getOptionValue={({ id }) => id}
          valueKey={"id"}
          value={productApply.find(e => e.id == item.product_kind) || item.product_kind}
          styles={ctx?.props?.mode == 'view' ? disabledStyles : customStyles}
        />
        {ctx.props?.submitData?.errors?.product_kind && <span style={{ color: 'red', fontSize: 12 }}>{ctx.props?.submitData.errors?.product_kind[0]}</span> }
      </div>
      { ['specific'].includes(item?.product_kind?.id || item.product_kind) && 
        <div style={{ marginTop: 10 }}>
          <AsyncSelect
            isDisabled={ ctx.props.mode == 'view' ? true : false }
            className="MuiFormControl-marginDense"
            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
              return new Promise(resolve => {
                request.get('/api/v1/products', { search: inputValue }).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items.map(e => ({ id: e.id, name: e.title })))
                  }
                })
              })
            })}
            defaultOptions
            onInputChange={handleInputChange}
            isSearchable
            isMulti
            name="product_ids"
            onChange={(value) => {
              const event = {
                target: {
                  name: 'product_ids',
                  value
                }
              }
              ctx.handleChange(event)
            }}
            placeholder={"Sevices"}
            menuPortalTarget={document.body}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            valueKey={"id"}
            value={item?.product_ids}
            styles={ctx?.props?.mode == 'view' ? disabledStyles : customStyles}
          />
          {ctx.props?.submitData?.errors?.product_ids  && <span style={{ color: 'red', fontSize: 12 }}>{ctx.props?.submitData?.errors?.product_ids[0]}</span> }
        </div>
      }
    </>,
  },
  {
    type: 'number',
    name: 'number_of_uses',
    label: 'Number of uses',
    renderValue: (item) => item.number_of_uses,
    disabled: true
  },
  {
    type: 'text',
    name: 'limit_number_of_uses',
    label: 'Limit number of uses',
    renderValue: (item) => item.limit_number_of_uses
  },
  {
    type: 'text',
    name: 'status',
    label: 'Status', 
    renderValue: (item) => item.status,
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'disabled',
    label: 'Disabled',
    checked: (item) => item.disabled
  }
]
