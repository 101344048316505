import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { ModalForm, ResourceList, CustomTextField } from '../../../components';
import { toastr } from '../../../_helpers_';
import { slugifyStr } from '../../../utils';
import { request } from '../../../_services_';
import { fields } from './fields'

const AdminUser = (props) => {

  const createUser = (ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New Admin User',
      data: {},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Email <span style={{ color: 'red' }}>*</span>
            </Box>
            <CustomTextField
              fullWidth
              margin='dense'
              name="email"
              onChange={handleChange}
              value={submitData.values.email}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.email}
              helperText={submitData.errors?.email ? submitData.errors.email[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Fullname
            </Box>
            <CustomTextField
              fullWidth
              autoComplete='new-email'
              margin='dense'
              name="fullname"
              onChange={(e) => {
                handleChange(e)
                const event = {
                  target: {
                    name: 'shortname',
                    value: slugifyStr(e.target.value)
                  }
                }
                handleChange(event)
              }}
              value={submitData.values.fullname}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.fullname}
              helperText={submitData.errors?.fullname ? submitData.errors.fullname[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Shortname
            </Box>
            <CustomTextField
              fullWidth
              disabled
              margin='dense'
              name="shortname"
              onChange={handleChange}
              value={submitData.values.shortname}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Password
            </Box>
            <CustomTextField
              fullWidth
              autoComplete='new-password'
              margin='dense'
              type="password"
              name="password"
              onChange={handleChange}
              value={submitData.values.password}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.password}
              helperText={submitData.errors?.password ? submitData.errors.password[0] : ''}
            />
          </div>
        </div>
      ),
      action:{
        titleAction: 'Save',
        schema: {
          email: {
            presence: { allowEmpty: false, message: '^Required' },
            email: true
          },
          fullname: {
            presence: { allowEmpty: false, message: '^Required' },
          },
          password: {
            presence: { allowEmpty: false, message: '^Required' },
          }
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.post('/admin/v1/users', submitData.values).then(res => {
              if (res.data.success) {
                const items = Object.assign([], ctx.state.items)
                items.push({uid: res.data.data, active: true, ...submitData.values})
                ctx.setState({ items, totals: ctx.state.totals + 1 })
                toastr.success("Create user succesful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err?.response?.data?.msg || error.toString())
              resolve(false)
            })
          })
        }
      },
    })
  }

  return (
    <ResourceList 
      route="/admin/v1/users"
      titlePage="Drebest | Admin Users"
      actions={[
        {
          text: 'New',
          variant: 'contained',
          icon: <AddIcon />,
          action: (ctx) => createUser(ctx),
          visible: true
        }
      ]}
      title="Admin Users"
      fields={fields}
      valueKey="uid"
    />
  )

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(AdminUser)