import { Checkbox } from '@mui/material'
import { request } from '../../../_services_'
import NumberFormat from "react-number-format"
import React from 'react';
import { IconBrandMessenger } from '@tabler/icons';

export const listFields = [
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_name',
    label: 'Team ID & Name',
    renderValue: (item) => item.team_id ? `${item.team_id?.team_search || ''} ${item.name || ""}` : '',
    width: '8rem',
    sort:true,
    sortName:'teams.team_id',
    filter:true,
    filterName:'teams.team_search|name*1'
  },
  {
    name: 'fullname',
    label: 'User - Fullname',
    width: '10rem',
    sort:true,
    filter:true,
  },
  {
    name: 'timezone',
    label: 'Timezone',
    renderValue: (item) => item.team_id ? item.team_id.timezone : '',
    sort:true,
    sortName:'teams.timezone'
  },
  {
    name: 'spent_per_month',
    label: 'Spent / Month',
    renderValue: (item) => item.team_id ? <NumberFormat value={item.team_id.spent_per_month || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
    sortName:'teams.spent_per_month'
  },
  {
    name: 'discount_code',
    label: 'Discount code',
    renderValue: (item) => item.team_id?.discount_code,
    sort:true,
    sortName:'teams.discount_code',
    filter:true,
    filterName:'teams.discount_code'
  },
  {
    name: 'renting',
    label: 'Renting',
    sort:true
  },
  {
    name: 'money_to_wallet',
    label: 'Sum of A - Money to Wallet',
    width: '12rem',
    renderValue: (item) => item.money_to_wallet ? <NumberFormat value={item.money_to_wallet || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'actual_fee',
    label: 'Sum of B - Actual Fee',
    width: '12rem',
    renderValue: (item) => item.actual_fee ? <NumberFormat value={item.actual_fee || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'ad_acc_per_issuance_fee',
    label: 'Sum of C - Ad Acc creation/insuance fee',
    width: '16rem',
    renderValue: (item) => item.ad_acc_per_issuance_fee ? <NumberFormat value={item.ad_acc_per_issuance_fee || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'acc_deposit',
    label: 'Sum of D - Acc deposit',
    width: '12rem',
    renderValue: (item) => item.acc_deposit ? <NumberFormat value={item.acc_deposit || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'paypal_fee',
    label: 'Sum of E - Paypal fee 3%',
    width: '12rem',
    renderValue: (item) => item.paypal_fee ? <NumberFormat value={item.paypal_fee || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'total_money',
    label: 'Sum of Total Money',
    width: '12rem',
    renderValue: (item) => item.total_money ? <NumberFormat value={item.total_money || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'spending_limit',
    label: 'Spending Limit',
    width: '12rem',
    renderValue: (item) => item.spending_limit ? <NumberFormat value={item.spending_limit || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'spend_all_acc',
    label: 'Spend All Acc',
    width: '12rem',
    renderValue: (item) => item.spend_all_acc ? <NumberFormat value={item.spend_all_acc || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'remaining_all_acc',
    label: 'Remaining all acc',
    width: '12rem',
    renderValue: (item) => item.remaining_all_acc ? <NumberFormat value={item.remaining_all_acc || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'wallet_remaining',
    label: 'Wallet Remaining',
    width: '12rem',
    renderValue: (item) => item.wallet_remaining ? <NumberFormat value={item.wallet_remaining || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'topup_acc_processing',
    label: 'Topup Acc (processing)',
    width: '12rem',
    renderValue: (item) => item.topup_acc_processing ? <NumberFormat value={item.topup_acc_processing || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'wallet_remaining_after',
    label: 'Wallet Remaining (after)',
    width: '12rem',
    renderValue: (item) => item.wallet_remaining_after ? <NumberFormat value={item.wallet_remaining_after || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true
  },
  {
    name: 'note',
    label: 'Note 2',
    sort:true,
    filter:true,
    filterName:'note@ilike'
  },
  {
    name: 'skip',
    label: 'Skip',
    sort:true
  },
  {
    name: 'cycle_end_date',
    label: 'Cycle_end_date',
    sort:true
  },
  {
    name: 'countdown',
    label: 'Count down',
    sort:true
  },
  {
    name: 'stop_renting',
    label: 'Stop renting',
    sort:true
  },
  {
    name: 'sale_convert',
    label: 'Sale convert',
    renderValue: (item) => item.team_id?.sale_convert,
    width: '12rem',
    sort:true,
    sortName:'teams.sale_convert',
    filter:true,
    filterName:'teams.sale_convert'
  },
  {
    name: 'team_user_referrer_2',
    label: 'NGT Team user 2',
    sort:true
  },
  {
    name: 'team_user_referrer_1',
    label: 'NGT Team user 1',
    sort:true
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    renderValue: (item) => item.team_id?.type_acc,
    width: '8rem',
    sort:true,
    sortName:'teams.type_acc',
    filter:true,
    filterName:'teams.type_acc'
  },
  // {
  //   name: 'type_acc_2',
  //   label: 'Type acc 2',
  //   renderValue: (item) => item.team_id?.type_acc2,
  //   width: '8rem'
  // },
  {
    name: 'rate_max',
    label: 'Rate max',
    renderValue: (item) => item.team_id?.rate_max,
    width: '8rem',
    sort:true,
    sortName:'teams.rate_max'
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Team mess group',
    renderValue: (item) => item.team_id?.mess_group_link
  },
  {
    type: 'text',
    name: 'team_name',
    label: 'Team ID & Name',
    renderValue: (item) => item.team_id ? `${item.team_id.team_search} ${item.team_id.short_name}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'fullname',
    label: 'User - Fullname',
    disabled: true
  },
  {
    type: 'text',
    name: 'timezone',
    label: 'Timezone of Acc',
    renderValue: (item) => item.team_id?.timezone,
    disabled: true
  },
  {
    type: 'text',
    name: 'spent_per_month',
    label: 'Spent / Month',
    renderValue: (item) => item.team_id?.spent_per_month,
    disabled: true,
  },
  {
    type: 'text',
    name: 'discount_code',
    label: 'Discount code',
    renderValue: (item) => item.team_id?.discount_code
  },
  {
    type: 'number',
    name: 'money_to_wallet',
    label: 'Sum of A - Money to Wallet',
    disabled: true,
    renderValue: (item) => item.money_to_wallet ? <NumberFormat value={item.money_to_wallet || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'actual_fee',
    label: 'Sum of B - Actual Fee',
    disabled: true,
    renderValue: (item) => item.actual_fee ? <NumberFormat value={item.actual_fee || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'ad_acc_per_issuance_fee',
    label: 'Sum of C - Ad Acc creation/insuance fee',
    disabled: true,
    renderValue: (item) => item.ad_acc_per_issuance_fee ? <NumberFormat value={item.ad_acc_per_issuance_fee || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'acc_deposit',
    label: 'Sum of D - Acc deposit',
    disabled: true,
    renderValue: (item) => item.acc_deposit ? <NumberFormat value={item.acc_deposit || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'paypal_fee',
    label: 'Sum of E - Paypal fee 3%',
    disabled: true,
    renderValue: (item) => item.paypal_fee ? <NumberFormat value={item.paypal_fee || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'total_money',
    label: 'Sum of Total Money',
    disabled: true,
    renderValue: (item) => item.total_money ? <NumberFormat value={item.total_money || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    name: 'spending_limit',
    label: 'Spending Limit',
    disabled: true,
    renderValue: (item) => item.spending_limit ? <NumberFormat value={item.spending_limit || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'spend_all_acc',
    label: 'Spend All Acc',
    disabled: true,
    renderValue: (item) => item.spend_all_acc ? <NumberFormat value={item.spend_all_acc || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'remaining_all_acc',
    label: 'Remaining all acc',
    disabled: true,
    renderValue: (item) => item.remaining_all_acc ? <NumberFormat value={item.remaining_all_acc || 0} displayType="text" thousandSeparator={','} /> : ''
  },
  {
    type: 'number',
    name: 'wallet_remaining',
    label: 'Wallet Remaining',
    disabled: true,
    renderValue: (item) => item.wallet_remaining ? <NumberFormat value={item.wallet_remaining || 0} displayType="text" thousandSeparator={','} /> : ''
  },
]