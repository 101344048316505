
import { listFields, formFields, filterRAI } from './fields';

import React, { Component } from 'react'
import { ResourceList, ModalForm, CustomNumberFormat, DrawerForm } from '../../../components';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box, Checkbox, FormControlLabel
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_'

class RAITopupList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newTopupAcc = (ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New Topup Acc',
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Acc Code
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/accounts', { search: inputValue, type:'rai' }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.account_id, name: `${e.acc_code}` })))
                    }
                  })
                })
              })}
              cacheOptions
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="account"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'account',
                    value
                  }
                }
                handleChange(event)
              }}
              placeholder={"Account"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData.values.account_id}
              styles={submitData.errors?.account_id ? errorStyles : customStyles}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Amount
            </Box>
            <TextField
              margin="dense"
              InputProps={{
                // inputComponent: CustomNumberFormat
              }}
              type={'number'}
              placeholder={'Money topup'}
              name={'money_topup'}
              value={submitData.values.money_topup}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              error={submitData.errors?.money_topup}
              helperText={submitData.errors?.money_topup ? submitData.errors?.money_topup[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <FormControlLabel
              control={
                <Checkbox
                  name='topup_done'
                  checked={submitData.values.topup_done}
                  onChange={e => {
                    const event = {
                      target: {
                        name: 'topup_done',
                        value: e.target.checked
                      }
                    }
                    handleChange(event)
                  }}
                />
              }
              label='Topup done'
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Create',
        schema: {
          account: { presence: { allowEmpty: false, message: '^Required' } },
          money_topup: { presence: { allowEmpty: false, message: '^Required' } }
        },
        onAction: (submitData) => {
          const query = {}
          const allKeys = Object.keys(submitData.values)
          allKeys.map(key => {
            if (key == 'account') {
              query['account_id'] = submitData.values[key].id
            } else if (key == 'money_topup') {
              query[key] = Number.parseFloat(submitData.values[key])
            } else if (typeof submitData.values[key] === 'boolean') {
              query[key] = submitData.values[key] ? 1 : 0
            } else if (typeof submitData.values[key] === 'object' || submitData.values[key] instanceof Object) {
              query[key] = submitData.values[key].id
            } else {
              query[key] = submitData.values[key]
            }
          })
          query['type'] = 'rai'
          return new Promise((resolve) => {
            request.post('/admin/v1/acc_topups', query).then(res => {
              if (res.data.success) {
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
                resolve(true);
                toastr.success("Create successful")
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'status') {
        query[key] = formState.changes[key] == true ? 'done' : 'new'
      } else if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/acc_topup/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err.response.data.msg || err.response.data.message)
    })
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/acc_topups"
          titlePage="Drebest | 8.1 RAI Topup"
          title="8.1 RAI Topup"
          defaultFilters={{type: 'rai'}}
          fields={listFields('rai')}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              icon: <AddIcon />,
              variant: 'contained',
              visible: true,
              action: (ctx) => this.newTopupAcc(ctx)
            }
          ]}
          filters={filterRAI}
        />
        <DrawerForm
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/acc_topup/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="RAI Detail"
          actions={[
            // {
            //   text: 'Edit',
            //   variant: 'contained',
            //   color: 'primary',
            //   action: () => this.setState({ mode: 'edit' }),
            //   visible: mode == 'view'
            // },
            // {
            //   text: 'Save',
            //   variant: 'contained',
            //   color: 'primary',
            //   action: (ctx, formState) => this.handleSave(ctx, formState),
            //   visible: mode == 'edit'
            // },
            // {
            //   text: 'Cancel',
            //   variant: 'outlined',
            //   color: 'error',
            //   action: (ctx, formState) => this.handleCancel(ctx),
            //   visible: mode == 'edit'
            // },
          ]}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RAITopupList);