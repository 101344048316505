import React, { Component } from 'react'
import { listB5Fields } from './fields';
import { ResourceList } from '../../../components';

class RequestB5 extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="Drebest | 9.2 Request B5"
        title="9.2 Request B5"
        fields={listB5Fields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/9b_request_b5/${item.request_id}`)}
        defaultFilters={
          {codes:JSON.stringify(['B5'])}
        }
      />
    )
  }

}

export default RequestB5;