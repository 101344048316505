import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';

import moment from 'moment';

export const listFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort:true,
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item.name}`,
    sort:true,
    filter:true,
  },
  {
    name: 'account_id',
    label: 'Acc code',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code||''}` : '',
    sort:true,
    sortName:'accounts.code',
    filter:true,
    filterName:'accounts.code',

  },
  {
    name: 'account_id',
    label: 'Acc Name auto',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.name}` : '',
    sort:true,
    sortName:'accounts.name',
    filter:true,
    filterName:'accounts.name',

  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.acc_id||''}` : '',
    sort:true,
    sortName:'accounts.acc_id',
    filter:true,
    filterName:'accounts.acc_id',
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search||''} ${item?.account_id.team_id?.owner_name||''}` : '',
    sort:true,
    sortName:'accounts.teams.team_search',
    filter:true,
    filterName:'accounts.teams.team_search|accounts.teams.team_search*1',

  },
  {
    name: 'money_topup_to_acc',
    label: 'Money topup to Acc',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Wallet Remaining (after)',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Partner Code',
    sort:true,
  },
  {
    label: 'Request Type code',
    name: 'request_type_id',
    renderValue: (item) => `${item?.request_type_id?.name || ''}`,
    sort:true,
    sortName:'request_types.code',
    filter:true,
    filterName:'request_types.name',
  },
  {
    name: 'status_hp',
    label: 'Status HP',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Team ID manual',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''}` : '',
    sort:true,
    sortName:'accounts.teams.team_search',
    filter:true,
    filterName:'accounts.teams.team_search',
  },
  {
    name: 'other_note',
    label: 'Other Notes (ghi chú khác)',
    sort:true,
    filter:true,
    filterName:'other_note@ilike'

  },
  {
    name: 'acc_status',
    label: 'Acc Status',
    sort:true,
  },
  {
    name: 'via_bm',
    label: 'Via Name on BM (show)',
    sort:true,
    filter:true,

  },
  {
    name: 'bm_name',
    label: 'BM Name',
    sort:true,
    filter:true,

  },
  {
    name: 'bm_register_first',
    label: 'BM register first time?',
    sort:true,
  },
  {
    name: 'bm_invite_link',
    label: 'BM invite link (Admin Role)',
    sort:true,
  },
  {
    name: 'send_image',
    label: 'Send image through:',
    sort:true,
  },
  {
    name: 'bm_2fa_image',
    label: 'BM 2FA Image (upload)',
    sort:true,
  },
  {
    name: 'count_time',
    label: 'Count Time',
    sort:true,
  },
  {
    name: 'duplicate',
    label: 'Duplicate',
    sort:true,
  },
  {
    name: 'team_content_link',
    label: 'Link content (show)',
    sort:true,
  },
  {
    name: 'team_seller_content',
    label: 'Team Seller Content',
    sort:true,
  },
  {
    name: 'reason_stop',
    label: 'Nguyên nhân dừng hợp tác',
    sort:true,
  },
  {
    name: 'domain_verification',
    label: 'Domain Verification Method',
    sort:true,
  },
  {
    name: 'pixel_id',
    label: 'Pixel ID',
    sort:true,
  },
  {
    name: 'pixel_name',
    label: 'Pixel Name',
    sort:true,
  },
  {
    name: 'pixel guide',
    label: 'Pixel guide (show)',
    sort:true,
  },
  {
    name: 'share_manage_pixel_via',
    label: 'Share the right to manage Pixel for Via',
    sort:true,
  },
  {
    name: 'rules',
    label: 'Rules for refund (show)',
    sort:true,
  },
  {
    name: 'delete_all_campaigns',
    label: 'Deleted all campaigns?',
    renderValue: (item) => item?.delete_all_campaigns != null ? (item?.delete_all_campaigns ? 'True' : 'False') : null,
    sort:true,

  },
  {
    name: 'have_account_block_or_ads_active',
    label: 'Có tài khoản bị khoá và có Ad Post chạy dở?',
    renderValue: (item) => item?.have_account_block_or_ads_active != null ? (item?.have_account_block_or_ads_active ? 'True' : 'False') : null,
    sort:true,

  },
  {
    name: 'plan_ads_active',
    label: 'Phương án với Ad Post chạy dở trên Acc bị khoá?',
    sort:true,
  },
  {
    name: 'paypal_acc',
    label: 'Paypal acc',
    sort:true,
  },
  {
    name: 'bank_id',
    label: 'Bank Vietnam',
    renderValue: (item) => item?.bank_id?.name || '',
    sort:true,
    sortName:'banks.name',
    filter:true,
    filterName:'banks.name'
  },
  {
    name: 'so_tai_khoan',
    label: 'Số tài khoản',
    sort:true,
  },
  {
    name: 'chu_tai_khoan',
    label: 'Chủ tài khoản',
    sort:true,
  },
  {
    name: 'acc_type',
    label: 'Acc Type',
    sort:true,
  },
  {
    name: 'related_fb_policy',
    label: 'Related PB policy',
    sort:true,
  },
  {
    name: 'bm_share_button',
    label: 'BM Share button',
    sort:true,
  },
  {
    name: 'request_type_id',
    label: 'Request Type',
    renderValue: (item) => `${item?.request_type_id?.name}`,
    sort:true,
    sortName:'request_types.name'
  }
]

