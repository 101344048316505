import moment from "moment"
import ExtraField from "./ExtraField"
import {Box, FormControlLabel, Radio, RadioGroup, Button } from '@mui/material';
import { request } from '../../../_services_';
export const fields = (ctx, ctxList) => ([
  {
    name: 'name',
    label: 'Service ID',
    sort: true,
    width: '6rem',
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_search,
    sort: true,
    width: '6rem',
  },
  {
    name: 'product_id',
    label: 'Service',
    sort: true,
    renderValue: (item) => item.product_id?.title,
    width: '14rem'
  },
  {
    name: 'fee',
    label: 'Fee',
    sort: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '6rem'
  },
  {
    name: 'create_date',
    label: 'Timestamp',
    sort: true,
    renderValue: (item) => moment(item.create_date).format('DD/MM/YYYY HH:mm:ss'),
    width: '10rem'
  },
  // {
  //   name: 'end_date',
  //   label: 'End date',
  //   sort: true,
  //   renderValue: (item) => item.end_date ? moment(item.end_date).format('DD/MM/YYYY HH:mm:ss') : '',
  //   width: '8rem'
  // },
  {
    name: 'action',
    label: '',
    align: 'center',
    renderValue: (item) => !item.cancelled && item.product_id?.can_be_cancelled ? 
      <Button 
        variant="outlined" 
        color="error" 
        onClick={(e) => {
          e.stopPropagation()
          ctx.cancelServices(ctxList, [item.id])
        }}
      >
        Cancel
      </Button> : '',
    width: '5rem'
  }
])

export const formFields = [
  {
    type: 'text',
    name: 'name',
    label: 'Service ID',
    disabled: true
  },
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    disabled: true,
    renderValue: (item) => item.create_date ? moment(item.create_date).format('YYYY-MM-DD HH:mm:SS') : ''
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item.team_id?.team_search
  },
  {
    type: 'text',
    name: 'product_id',
    label: 'Service',
    disabled: true,
    renderValue: (item) => item.product_id?.title
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Fee',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    visible: (item) => item.product_id?.code != 'A' ? true : false,
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Money topup to wallet',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    visible: (item) => item.product_id?.code == 'A' ? true : false,
  },
  {
    type: 'custom',
    name: 'option',
    label: 'Option',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => 
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight="bold">Option</Box>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="option"
          value={item.option}
          row
        >
          <FormControlLabel disabled value="upgrade_fud" control={<Radio />} label="Upgrade FUD" />
          <FormControlLabel disabled value="new_fud" control={<Radio />} label="New FUD" />
        </RadioGroup>
      </div>
  },
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    visible: (item) => item.product_id?.code == 'A' || item.product_id?.code == 'B' && item.option == 'upgrade_fud' ? true : false,
    renderValue: (item) => item.fud_id ? `FUD ${item.fud_id?.fud_id}` : ''
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => item.package_id?.name
  },
  {
    type: 'number',
    name: 'num_account',
    label: 'Number of accounts',
    disabled: true,
    visible: (item) => ['C', 'D'].includes(item.product_id?.code) ? true : false,
  },
  {
    type: 'custom',
    name: 'extra_fields',
    visible: (item) => item.extra_fields?.length > 0 ? true : false,
    label: 'Extra fields',
    renderValue: (item, ctx) => <ExtraField submitData={item} handleChange={ctx.handleChange} disabled={ctx.props.mode == 'view'} />
  },
  {
    type: 'number',
    name: 'deduct',
    label: 'Deduct',
    disabled: (item) => item.product_id?.auto_deduct ? true : false
  },
  {
    type: 'text',
    name: 'discount_code',
    label: 'Discount Code',
    disabled: true
  },
  {
    type: 'number',
    name: 'actual_fee',
    label: 'Actual Fee',
    disabled: true
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
    disabled: true,
  },
]

export const filters = [
  {
    type: 'input_picker',
    valueKey: 'id',
    key: 'teams',
    defaultAriaLabel: 'Team',
    labelKey: 'title',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/teams', { search: inputValue || '' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  },
  {
    type: 'input_picker',
    valueKey: 'id',
    key: 'products',
    defaultAriaLabel: 'Service Code',
    labelKey: 'title',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/products', { search: inputValue || '', no_limit: 1 }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  },
  {
    type: 'date_picker',
    key: 'create_date',
    placeholder: 'First day of FUD',
  }
]