import { request } from '../../../_services_';
import moment from 'moment';
import { IconBrandMessenger } from '@tabler/icons';

const listStatus = [
  { id: 'new', name: 'New' },
  { id: 'processing', name: 'Processing' },
  { id: 'done', name: 'Done' },
  { id: 'cancel', name: 'Cancel' }
]

export const listFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort: true
  },
  {
    name: 'uid',
    label: 'Username',
    renderValue: (item) => item.user_id ? `User_${item.user_id?.uid} ${item.user_id.fullname || ''}` : '',
    sort: true,
    sortName: 'teams.owner_name',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1'
  },
  {
    name: 'amount',
    label: 'Amount ($)',
    sort: true,
  },
  {
    name: 'is_payment',
    label: 'Status',
    renderValue: (item) => item.is_payment ? 'Paid' : 'Unpaid',
    sort: true
  }
]

export const formFields = [
  {
    type: 'text',
    name: 'timestamp',
    label: 'Timestamp',
    renderValue: (item) => moment(item.create_date).format('DD/MM/YYYY HH:mm:ss'),
    disabled: true
  },
  {
    type: 'text',
    name: 'id',
    label: 'Commission ID',
    renderValue: (item) => `Commission ${item.id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'uid',
    label: 'Username',
    renderValue: (item) => item.user_id ? `User_${item.user_id?.uid} ${item.user_id.fullname || ''}` : '',
    disabled: true,
  },
  {
    type: 'number',
    name: 'amount',
    label: 'Commission amount',
    disabled: true
  },
  {
    type: 'text',
    name: 'src_uid',
    label: 'Aff Username',
    renderValue: (item) => `User_${item.src_uid}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'is_payment',
    label: 'Status',
    renderValue: (item) => item.is_payment ? 'Paid' : 'Unpaid',
    disabled: true,
  },
  {
    type: 'text',
    name: 'screenshot',
    label: 'Screenshot url',
  }
]
