import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';


export const listFields = [

  {
    name: 'partner_code',
    label: 'Partner code',
    sort: true,
    filter:true,
  },
  {
    name: 'code',
    label: 'RAC ID',
    sort: true,
    filter:true,

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item.team_id?.team_search || ''} ${item?.team_id?.owner_name || ''}` : '',
    sort: true,
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1'

  },
  {
    name: 'keep_deposit',
    label: 'Keep Deposite',
    sort: true,

  },
  {
    name: 'status',
    label: 'Status (manual)',
    sort: true,

  },
  {
    name: 'disable_type',
    label: 'Disabled type',
    sort: true,

  },
  {
    name: 'disable_reason',
    label: 'Disabled reason',
    sort: true,

  },
  {
    name: 'Permanently_Disabled',
    label: 'Permanently Disabled',
    sort: true,

  },
  {
    name: 'disable_date',
    label: 'Disabled date',
    sort: true,
  },
  {
    name: 'note',
    label: 'Note',
    sort: true,
    filter:true,
    filterName:'note@ilike'
  },
  {
    name: 'giai_phap',
    label: 'Giải pháp',
    sort: true,
  },
  {
    name: 'bm_id',
    label: 'BM ID (preventive)',
    sort: true,
    filter:true,

  },
  {
    name: 'time_zone',
    label: 'Acc Timezone (manual)',
    sort: true,
  },
  {
    name: 'using',
    label: 'Using',
    sort: true,
  },
  {
    name: 'thu_hoi',
    label: '9-Thu hồi',
    sort: true,
  },
  {
    name: 'name',
    label: 'Manual Acc Name',
    sort: true,
    filter:true,

  },
  {
    name: 'spend_fb',
    label: 'Cần check số Spent trên FB',
    sort: true,
  },
  {
    name: 'page1_name',
    label: 'Page 1 - Name Link',
    sort: true,
    filter:true,

  },
  {
    name: 'page2_name',
    label: 'Page 2 - Name Link',
    sort: true,
    filter:true,

  },
  {
    name: 'spent_1',
    label: 'Spent 1 (before)_87',
    sort: true,

  },
  {
    name: 'spent_panda',
    label: 'Spent by Panda',
    sort: true,

  },
  {
    name: 'chenh_tool_pd',
    label: 'Chênh Tool & PD',
    sort: true,

  },
  {
    name: 'Prefix',
    label: 'Prefix',
    sort: true,

  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort: true,
    filter:true,

  },
  {
    name: 'status',
    label: 'Status',
    sort: true,

  }
]

export const formFields = [
  {
    type: 'text',
    name: 'account_id',
    label: 'RAC ID',
    renderValue: (item) => `RAC ${item.account_id}`,
    disable: true
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item.team_id?.team_search || ''} ${item?.team_id?.owner_name || ''}` : '',
    disable: true

  },
  {
    type: 'text',
    name: 'account_id',
    label: 'ACC ID',
    disable: true
  },
  {
    type: 'text',
    name: 'status',
    label: 'Status',
    disable: true
  },
  {
    type: 'text',
    name: 'time_zone',
    label: 'Disabled type',
    disable: true
  },
  ,
  {
    type: 'text',
    name: 'time_zone',
    label: 'Team Mess-Group',
    disable: true,
    renderValue: (item) => item?.team_id ? `${item.team_id?.mess_group_link}` : '',
  },
  {
    type: 'text',
    name: 'time_zone',
    label: 'Permanently Disabled',
    disable: true,
  },
  {
    name: 'time_zone',
    label: 'Ads Manager',
    disable: true,
    type: 'text',

  },
  {
    name: 'disable_date',
    label: 'Acc Quality',
    disable: true,
    type: 'text',

  },
  {
    name: 'time_zone',
    label: 'Payment Settings',
    disable: true,
    type: 'text',
  },
  {
    name: 'disable_date',
    label: 'Disabled date',
    disable: true,
    type: 'text',
  },
  {
    name: 'note',
    label: 'Note',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Giải pháp',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Payment Settings Image Link',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Partner code',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Page 1 - Name Link',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Page 2 - Name Link',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Spent 1',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Spent by Panda',
    disable: true,
    type: 'text',
  },
  {
    name: 'time_zone',
    label: 'Prefix',
    disable: true,
    type: 'text',
  }
]