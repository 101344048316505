import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm, ModalForm } from '../../../components';
import { request } from '../../../_services_';
import { formRef2ReviewFields } from './fields'

const RefToReviewForm = (props) => {
  const [mode, setMode] = useState('view')
  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  const qualifiedWebpage = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: 'Web & page qualified?',
      data: {},
      customView: () => (
        <div className='p-3'>
          Are you sure?
        </div>
      ),
      action: {
        onAction: () => {
          const query = {
            webpage_qualified_content: 1
          }
          return new Promise((resolve) => {
            request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
              if (res.data.success) {
                const form = Object.assign({}, formState)
                form.changes = {}
                form.values = res.data.data
                ctx.setState({ formState: form })
                toastr.success("Update successful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error("There was an error updating data")
              resolve(false)
            })
          })
        }
      }
    })
    
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={formRef2ReviewFields}
      route={'app3/ref_review'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/admin/v1/ref_review/${id}`,{list: 'to_review'}).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Ref Review"
      actionBack={() => props.navigate('/app3/ref_reviews')}
      actions={[
        {
          text: 'Webpage Qualified',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => qualifiedWebpage(ctx, formState),
          visible: mode == 'view'
        },
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(RefToReviewForm))