import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
//
import { Snackbar } from '@mui/material';
import { IconSettings, IconCheck } from '@tabler/icons';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import SettingDrawer from './SettingDrawer';
import { showSettings } from '../../_actions_';
// import { LoadingBar } from 'react-redux-loading-bar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100vh',
  paddingTop: APP_BAR_MOBILE + 6,
  width: '100%',
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // height: `calc(100vh - ${APP_BAR_DESKTOP}px)`,
    overflow: 'auto',
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch()
  const snackbar = useSelector(state => state.openSnackbar)
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} {...props}/>
      <MainStyle>
        <Outlet {...props}/>
      </MainStyle>
      <div className='setting-fab' onClick={() => dispatch(showSettings())}>
        <IconSettings size={28} stroke={2} color="#FFF" />
      </div>
      <SettingDrawer />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackbar.data?.openSnackbar?.open}
        autoHideDuration={1500}
        onClose={() => {
          dispatch({ type: 'OPEN_SNACKBAR', openSnackbar: null })
        }}
        message={<div className='d-flex align-items-center' style={{ gap: 10 }}><IconCheck size={20} stroke={2} /><span className='ml-2'>{snackbar.data?.openSnackbar?.text}</span></div>}
        ContentProps={{
          className: 'snack-success'
        }}
      />
    </RootStyle>
  );
}
