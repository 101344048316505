import React from 'react'
import NumberFormat from "react-number-format"
import moment from 'moment'
import { Label } from '../../../components';
import { capitalizeStr } from '../../../utils';

const statusList = [
  {id: 'success', name: 'Success'},
  {id: 'pending', name: 'Pending'},
]

export const filters = [
  {
    valueKey: 'id',
    key: 'status',
    defaultAriaLabel: 'Status',
    getOptionLabel: (item) => item.name,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = statusList.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
        resolve(result)
      })
    }
  },
  {
    type: 'input_picker',
    valueKey: 'id',
    key: 'teams',
    defaultAriaLabel: 'Team',
    labelKey: 'title',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/teams', { search: inputValue || '' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  },
  {
    type: 'date_picker',
    key: 'sent_date',
    placeholder: 'Timestamp',
  }
]

export const fields = [
  {
    name: 'sent_date',
    label: 'Timestamp',
    sort: true,
    width: '6rem',
    renderValue: (item) => item.sent_date ? moment(item.sent_date).format('DD/MM/YYYY HH:mm:SS') : ''
  },
  {
    name: 'payment_id',
    label: 'Payment ID',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    width: '4rem',
    renderValue: (item) => <Label variant="ghost" color={(item.status === 'pending' && 'warning') || 'success'}>{capitalizeStr(item.status)}</Label>
  },
  {
    name: 'amount',
    label: 'Amount',
    sort: true,
    width: '4rem',
    renderValue: (item) => <NumberFormat value={item.amount} displayType="text" thousandSeparator={','} />,
  },
  {
    name: 'description',
    label: 'Description',
    sort: true,
    filter: true,
    width: '9rem',
    renderValue: (item) => <span className='line-clamp-1'>{item.description}</span>
  },
  {
    name: 'team_id',
    label: 'Team',
    renderValue: (item) => item.team_id?.team_id ? `Team_${item.team_id.team_id}` : '',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'sent_by',
    label: 'Sent By',
    sort: true,
    filter: true,
    width: '8rem',
    renderValue: (item) => <span className='line-clamp-1'>{item.sent_by}</span>
  },
]

export const formfields = [
  {
    type: 'text',
    disabled: true,
    name: 'sent_date',
    label: 'Timestamp',
    renderValue: (item) => item.sent_date ? moment(item.sent_date).format('DD/MM/YYYY HH:mm:SS') : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'payment_id',
    label: 'Payment ID',
  },
  {
    name: 'sent_by',
    label: 'Sent By',
    type: 'text',
    disabled: true,
  },
  {
    type: 'number',
    disabled: true,
    name: 'amount',
    label: 'Amount',
  },
  {
    type: 'textarea',
    disabled: true,
    name: 'description',
    label: 'Description',
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team',
    renderValue: (item) => item.team_id ? `Team_${item.team_id.id}` : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'status',
    label: 'Status',
    renderValue: (item) => item.status ? capitalizeStr(item.status) : ''
  },
]