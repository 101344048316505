export const authHeaders = () => {
  const user = JSON.parse(localStorage.getItem('user_admin_drebest'))
  if (user && user.token) {
    return {
      'x-access-token': user.token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "app":"employee"
    }
  }
  return {
    "app":"employee"
  }
}
