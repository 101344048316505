import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList, DrawerForm } from '../../../components';
import { fields, formfields, filters } from './fields'
import { request } from '../../../_services_';

class TransactionHistoryList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  render () {
    const { user } = this.props;
    const { open, mode, objectId } = this.state
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          route="/api/v1/wallet_histories"
          titlePage="Drebest | 3.1 Total Fund"
          actions={[]}
          title="3.1 Total Fund"
          fields={fields}
          valueKey="id"
          defaultFilters={{kind: 'income'}}
          onClickRow={(item) => this.setState({ open: true, objectId: item.id, mode: 'view' })}
          filters={filters}
        />
        <DrawerForm 
          fields={formfields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/wallet_transaction/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Total Fund"
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TransactionHistoryList)