import React from 'react'
import { ButtonDropdown, SmallTinyEditor } from '../../../components';
import { listExtraFields, tables } from './constants';
import { TextField, Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import { IconTrash } from '@tabler/icons';
import AsyncSelect from 'react-select/async';
import { loadOptions, handleInputChange, customStyles, capitalizeStr, makeId } from '../../../utils';
import { withStyles } from '@mui/styles';

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    },
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  }
})(TextField);

const ExtraField = (props) => {
  const { submitData, handleChange, disabled } = props;
  const editorRef = React.useRef(null);

  const addField = (field_type, submitData, handleChange) => {
    const extra_field = Object.assign([], submitData['extra_field'])
    const field = listExtraFields.find(e => e.type == field_type)
    extra_field.push({...field, id: makeId(15)})
    const event = {
      target: {
        name: 'extra_field',
        value: extra_field
      }
    }
    handleChange(event)
  }

  const addChildField = (field_id, field_type, submitData, handleChange) => {
    const extra_field = Object.assign([], submitData['extra_field'])
    const item = extra_field.find(e => e.id == field_id)
    const keys = Object.assign([], item.keys)
    const field = listExtraFields.find(e => e.type == field_type)
    keys.push({...field, id: makeId(15)})
    item['keys'] = keys
    const event = {
      target: {
        name: 'extra_field',
        value: extra_field
      }
    }
    handleChange(event)
  }

  return (
    <div className='mt-2'>
      <Box fontSize={15} fontWeight='bold'>
        Extra Field
      </Box>
      <div className="d-flex flex-column gap-1 mt-2">
        {submitData['extra_field']?.map((field, index) => (
          <div className='card-item' key={field.id}>
            <div className='d-flex align-items-center justify-content-between' style={{ borderBottom: '1px solid #E0E0E0', padding: '10px 16px' }}>
              <span style={{ fontWeight: '500' }}>{index + 1}. {capitalizeStr(field.type)} Field</span>
              {!disabled &&
              <div 
                style={{ border: '1px solid red', borderRadius: 6, padding: 4 }} 
                className="d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() => {
                  const fields = submitData['extra_field']?.filter(e => e.id != field.id)
                  const event = {
                    target: {
                      name: 'extra_field',
                      value: fields
                    }
                  }
                  handleChange(event)
                }}
              >
                <IconTrash color="red" size={16} stroke={2} />
              </div>
              }
            </div>
            <div className='p-3'>
              <div className='d-flex gap-2'>
                <div className='flex-1'>
                  <div>
                    <Box fontSize={14} fontWeight={600}>Key</Box>
                    <CustomTextField 
                      disabled={disabled}
                      name="key"
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      value={field.key}
                      onChange={(e) => {
                        const fields = Object.assign([], submitData['extra_field'])
                        const item = fields.find(e => e.id == field.id)
                        item[e.target.name] = e.target.value
                        const event = {
                          target: {
                            name: 'extra_field',
                            value: fields
                          }
                        }
                        handleChange(event)
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        },
                      }}
                    />
                  </div>
                  <div className='d-editor mt-2'>
                    <Box fontSize={14} fontWeight={600}>Label</Box>
                    {disabled ? 
                    <div className='mt-2' style={{ background: '#ECECEC', fontSize: 14, padding: '10px 14px', border: '1px solid rgba(145, 158, 171, 0.24)', borderRadius: 8 }}>
                      <span dangerouslySetInnerHTML={{ __html: field.label }} />
                    </div> : 
                    <SmallTinyEditor
                      disabled={disabled}
                      style={{ width: '100%' }}
                      content={field.label}
                      id={makeId(20)}
                      ref={editorRef}
                      onEditorChange={(content) => {
                        const fields = Object.assign([], submitData['extra_field'])
                        const item = fields.find(e => e.id == field.id)
                        item['label'] = content
                        const event = {
                          target: {
                            name: 'extra_field',
                            value: fields
                          }
                        }
                        handleChange(event)
                      }}
                    />
                    }
                  </div>
                  {['select', 'multiselect', 'text', 'number'].includes(field.type) && 
                    <div className='mt-2'>
                      <Box fontSize={14} fontWeight={600}>Placeholder</Box>
                      <CustomTextField 
                        disabled={disabled}
                        name="placeholder"
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        value={field.placeholder}
                        onChange={(e) => {
                          const fields = Object.assign([], submitData['extra_field'])
                          const item = fields.find(e => e.id == field.id)
                          item[e.target.name] = e.target.value
                          const event = {
                            target: {
                              name: 'extra_field',
                              value: fields
                            }
                          }
                          handleChange(event)
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'notchedOutline'
                          },
                        }}
                      />
                    </div>
                  }
                </div>
                <div className='flex-1'>
                  <div>
                    <FormControlLabel 
                      label={"Visible by condition"}
                      control={
                        <Checkbox
                          disabled={disabled}
                          checked={field.has_condition === 1 ? true : false}
                          onChange={(e) => {
                            const fields = Object.assign([], submitData['extra_field'])
                            const item = fields.find(e => e.id == field.id)
                            item[e.target.name] = e.target.checked ? 1 : 0
                            if (e.target.checked) {
                              item['condition'] = ['', '']
                            } else {
                              delete item['condition']
                            }
                            const event = {
                              target: {
                                name: 'extra_field',
                                value: fields
                              }
                            }
                            handleChange(event)
                          }}
                          color="primary"
                          name={'has_condition'}
                        />
                      }
                    />
                  </div>
                  {field.has_condition == 1 && 
                    <div className='mt-2'>
                      <Box fontSize={14} fontWeight={600}>Condition</Box>
                      <div className="d-flex gap-1 align-items-center">
                        <div className='flex-1'>
                          <AsyncSelect
                            isDisabled={disabled}
                            className="MuiFormControl-marginDense mt-2"
                            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                              return new Promise(resolve => {
                                const result = submitData['extra_field']?.filter(e => e.id != field.id && e.key).map(e => ({id: e.key}))
                                resolve(result)
                              })
                            })}
                            defaultOptions
                            onInputChange={handleInputChange}
                            name="field"
                            onChange={(value) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              const condition = Object.assign(['', ''], item['condition'])
                              condition[0] = value.id
                              item['condition'] = condition
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            placeholder={"Field..."}
                            menuPortalTarget={document.body}
                            getOptionLabel={({ id }) => id}
                            getOptionValue={({ id }) => id}
                            valueKey={"id"}
                            value={field.condition && field.condition.length > 0 ? {id: field.condition[0]} : null}
                            styles={customStyles}
                          />
                        </div>
                        <div className='flex-1'>
                          <CustomTextField 
                            disabled={disabled}
                            name="placeholder"
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            value={field.condition && field.condition?.length > 1 ? field.condition[1] : ''}
                            onChange={(e) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              const condition = Object.assign(['', ''], item['condition'])
                              condition[1] = e.target.value
                              item['condition'] = condition
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: 'notchedOutline'
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <div className='mt-2'>
                    <FormControlLabel 
                      label={"Require by condition"}
                      control={
                        <Checkbox 
                          disabled={disabled}
                          checked={field.required_condition === 1 ? true : false}
                          onChange={(e) => {
                            const fields = Object.assign([], submitData['extra_field'])
                            const item = fields.find(e => e.id == field.id)
                            item[e.target.name] = e.target.checked ? 1 : 0
                            if (e.target.checked) {
                              item['required'] = ['', '']
                            } else {
                              item['required'] = 0
                            }
                            const event = {
                              target: {
                                name: 'extra_field',
                                value: fields
                              }
                            }
                            handleChange(event)
                          }}
                          color="primary"
                          name={'required_condition'}
                        />
                      }
                    />
                  </div>
                  {field.required_condition == 1 ?
                    <div className='mt-2'>
                      <Box fontSize={14} fontWeight={600}>Require Condition</Box>
                      <div className="d-flex gap-1 align-items-center">
                        <div className='flex-1'>
                          <AsyncSelect 
                            isDisabled={disabled}
                            className="MuiFormControl-marginDense mt-2"
                            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                              return new Promise(resolve => {
                                const result = submitData['extra_field']?.filter(e => e.id != field.id && e.key).map(e => ({id: e.key}))
                                resolve(result)
                              })
                            })}
                            defaultOptions
                            onInputChange={handleInputChange}
                            name="field"
                            onChange={(value) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              const required = Object.assign(['', ''], item['required'])
                              required[0] = value.id
                              item['required'] = required
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            placeholder={"Field..."}
                            menuPortalTarget={document.body}
                            getOptionLabel={({ id }) => id}
                            getOptionValue={({ id }) => id}
                            valueKey={"id"}
                            value={field.required && field.required?.length > 0 ? {id: field.required[0]} : null}
                            styles={customStyles}
                          />
                        </div>
                        <div className='flex-1'>
                          <CustomTextField 
                            disabled={disabled}
                            name="placeholder"
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            value={field.required && field.required?.length > 1 ? field.required[1] : ''}
                            onChange={(e) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              const required = Object.assign(['', ''], item['required'])
                              required[1] = e.target.value
                              item['required'] = required
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: 'notchedOutline'
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div> : 
                    <div className='mt-2'>
                      <FormControlLabel
                        label={"Required"}
                        control={
                          <Checkbox 
                            disabled={disabled}
                            checked={field.required === 1 ? true : false}
                            onChange={(e) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              item[e.target.name] = e.target.checked ? 1 : 0
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            color="primary"
                            name={'required'}
                          />
                        }
                      />
                    </div>
                  }
                  {['select', 'multiselect'].includes(field.type) &&
                    <div className='mt-2'>
                      <FormControlLabel 
                        label={"Dynamic options"}
                        control={
                          <Checkbox 
                            disabled={disabled}
                            checked={field.dynamic_option === 1 ? true : false}
                            onChange={(e) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              item[e.target.name] = e.target.checked ? 1 : 0
                              if (e.target.checked) {
                                delete item['options']
                              } else {
                                item['options'] = []
                              }
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            color="primary"
                            name={'dynamic_option'}
                          />
                        }
                      />
                    </div>
                  }
                  {['select', 'multiselect'].includes(field.type) && field.dynamic_option == 1 &&
                    <div className='mt-2'>
                      <Box fontWeight={600} fontSize={14}>Table</Box>
                      <AsyncSelect 
                        isDisabled={disabled}
                        className="MuiFormControl-marginDense mt-2"
                        loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                          return new Promise(resolve => {
                            const result = tables.filter(e => e.label.toLowerCase().includes((inputValue || '').toLowerCase()))
                            resolve(result)
                          })
                        })}
                        defaultOptions
                        onInputChange={handleInputChange}
                        name="route"
                        onChange={(value) => {
                          const fields = Object.assign([], submitData['extra_field'])
                          const item = fields.find(e => e.id == field.id)
                          item['route'] = value.value
                          const event = {
                            target: {
                              name: 'extra_field',
                              value: fields
                            }
                          }
                          handleChange(event)
                        }}
                        placeholder={"Table..."}
                        menuPortalTarget={document.body}
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ value }) => value}
                        valueKey={"value"}
                        value={tables.find(e => e.value == field.route)}
                        styles={customStyles}
                      />
                    </div>
                  }
                  {field.type == 'radio' &&
                    <div className='mt-2'>
                      <Box fontSize={14} fontWeight={600}>Options</Box>
                      <div className='d-flex flex-column'>
                        {field.options?.map(option => (
                          <div className='d-flex align-items-center gap-1' key={option.id}>
                            <div className='flex-1'>
                              <CustomTextField 
                                disabled={disabled}
                                name="key"
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                placeholder='Key'
                                value={option.key}
                                onChange={(e) => {
                                  const fields = Object.assign([], submitData['extra_field'])
                                  const item = fields.find(e => e.id == field.id)
                                  const options = Object.assign([], item.options)
                                  const op = options.find(e => e.id == option.id)
                                  op['key'] = e.target.value
                                  item['options'] = options
                                  const event = {
                                    target: {
                                      name: 'extra_field',
                                      value: fields
                                    }
                                  }
                                  handleChange(event)
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: 'notchedOutline'
                                  },
                                }}
                              />
                            </div>
                            <div className='flex-1'>
                              <CustomTextField 
                                disabled={disabled}
                                name="label"
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                placeholder='Label'
                                value={option.label}
                                onChange={(e) => {
                                  const fields = Object.assign([], submitData['extra_field'])
                                  const item = fields.find(e => e.id == field.id)
                                  const options = Object.assign([], item.options)
                                  const op = options.find(e => e.id == option.id)
                                  op['label'] = e.target.value
                                  item['options'] = options
                                  const event = {
                                    target: {
                                      name: 'extra_field',
                                      value: fields
                                    }
                                  }
                                  handleChange(event)
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: 'notchedOutline'
                                  },
                                }}
                              />
                            </div>
                            {!disabled &&
                            <div 
                              className='cursor-pointer d-flex align-items-center justify-content-center' 
                              style={{ padding: 4, height: 30, width: 30, border: '1px solid red', borderRadius: 6 }}
                              onClick={() => {
                                const fields = Object.assign([], submitData['extra_field'])
                                const item = fields.find(e => e.id == field.id)
                                const options = item['options'].filter(e => e.id != option.id)
                                item['options'] = options
                                const event = {
                                  target: {
                                    name: 'extra_field',
                                    value: fields
                                  }
                                }
                                handleChange(event)
                              }}
                            >
                              <IconTrash size={16} color="red" />
                            </div>
                            }
                          </div>
                        ))}
                      </div>
                      {!disabled &&
                      <Button 
                        className='mt-2'
                        variant="outlined" 
                        color="primary"
                        onClick={() => {
                          const fields = Object.assign([], submitData['extra_field'])
                          const item = fields.find(e => e.id == field.id)
                          const options = Object.assign([], item['options'])
                          options.push({id: makeId(12)})
                          item['options'] = options
                          const event = {
                            target: {
                              name: 'extra_field',
                              value: fields
                            }
                          }
                          handleChange(event)
                        }}
                      >
                        Add
                      </Button>
                      }
                    </div>
                  }
                  {(['select', 'multiselect'].includes(field.type) && !field.dynamic_option) &&
                    <div className='mt-2'>
                      <Box fontSize={14} fontWeight={600}>Options</Box>
                      <div className='d-flex flex-column'>
                        {field.options?.map(option => (
                          <div className='d-flex align-items-center gap-1' key={option.id}>
                            <div className='flex-1'>
                              <CustomTextField 
                                disabled={disabled}
                                name="key"
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                value={option.title}
                                onChange={(e) => {
                                  const fields = Object.assign([], submitData['extra_field'])
                                  const item = fields.find(e => e.id == field.id)
                                  const options = Object.assign([], item.options)
                                  const op = options.find(e => e.id == option.id)
                                  op['title'] = e.target.value
                                  item['options'] = options
                                  const event = {
                                    target: {
                                      name: 'extra_field',
                                      value: fields
                                    }
                                  }
                                  handleChange(event)
                                }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: 'notchedOutline'
                                  },
                                }}
                              />
                            </div>
                            {!disabled && 
                            <div 
                              className='cursor-pointer d-flex align-items-center justify-content-center' 
                              style={{ padding: 4, height: 30, width: 30, border: '1px solid red', borderRadius: 6 }}
                              onClick={() => {
                                const fields = Object.assign([], submitData['extra_field'])
                                const item = fields.find(e => e.id == field.id)
                                const options = item['options'].filter(e => e.id != option.id)
                                item['options'] = options
                                const event = {
                                  target: {
                                    name: 'extra_field',
                                    value: fields
                                  }
                                }
                                handleChange(event)
                              }}
                            >
                              <IconTrash size={16} color="red" />
                            </div>
                            }
                          </div>
                        ))}
                      </div>
                      {!disabled &&
                      <Button 
                        className='mt-2'
                        variant="outlined" 
                        color="primary"
                        onClick={() => {
                          const fields = Object.assign([], submitData['extra_field'])
                          const item = fields.find(e => e.id == field.id)
                          const options = Object.assign([], item['options'])
                          options.push({id: makeId(12)})
                          item['options'] = options
                          const event = {
                            target: {
                              name: 'extra_field',
                              value: fields
                            }
                          }
                          handleChange(event)
                        }}
                      >
                        Add
                      </Button>
                      }
                    </div>
                  }
                  {field.type == 'text' && 
                    <div className='mt-2'>
                      <FormControlLabel 
                        label={"Numeric String"}
                        control={
                          <Checkbox 
                            disabled={disabled}
                            checked={field.numericString === 1 ? true : false}
                            onChange={(e) => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              item[e.target.name] = e.target.checked ? 1 : 0
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                            color="primary"
                            name={'numericString'}
                          />
                        }
                      />
                    </div>
                  }

                </div>
              </div>
              {field.type == 'array' &&
                <div className='mt-2'>
                  <Box fontSize={14} fontWeight={600}>Fields</Box>
                  <div className='d-flex flex-column gap-1 mt-2'>
                    {field.keys?.map((f, ii) => (
                      <div className='card-item' key={f.id}>
                        <div className='d-flex align-items-center justify-content-between' style={{ borderBottom: '1px solid #E0E0E0', padding: '10px 16px' }}>
                          <span style={{ fontWeight: '500' }}>{ii + 1}. {capitalizeStr(f.type)} Field</span>
                          {!disabled &&
                          <div 
                            style={{ border: '1px solid red', borderRadius: 6, padding: 4 }} 
                            className="d-flex align-items-center justify-content-center cursor-pointer"
                            onClick={() => {
                              const fields = Object.assign([], submitData['extra_field'])
                              const item = fields.find(e => e.id == field.id)
                              const keys = item['keys'].filter(e => e.id != f.id)
                              item['keys'] = keys
                              const event = {
                                target: {
                                  name: 'extra_field',
                                  value: fields
                                }
                              }
                              handleChange(event)
                            }}
                          >
                            <IconTrash color="red" size={16} stroke={2} />
                          </div>
                          }
                        </div>
                        <div className='p-3'>
                          <div>
                            <Box fontWeight={600} fontSize={14}>Key</Box>
                            <CustomTextField 
                              disabled={disabled}
                              name="key"
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              placeholder='Key'
                              value={f.key}
                              onChange={(e) => {
                                const fields = Object.assign([], submitData['extra_field'])
                                const item = fields.find(e => e.id == field.id)
                                const keys = Object.assign([], item.keys)
                                const op = keys.find(e => e.id == f.id)
                                op[e.target.name] = e.target.value
                                item['keys'] = keys
                                const event = {
                                  target: {
                                    name: 'extra_field',
                                    value: fields
                                  }
                                }
                                handleChange(event)
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: 'notchedOutline'
                                },
                              }}
                            />
                          </div>
                          <div className='mt-2'>
                            <Box fontWeight={600} fontSize={14}>Label</Box>
                            <CustomTextField 
                              disabled={disabled}
                              name="label"
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              placeholder='Label'
                              value={f.label}
                              onChange={(e) => {
                                const fields = Object.assign([], submitData['extra_field'])
                                const item = fields.find(e => e.id == field.id)
                                const keys = Object.assign([], item.keys)
                                const op = keys.find(e => e.id == f.id)
                                op[e.target.name] = e.target.value
                                item['keys'] = keys
                                const event = {
                                  target: {
                                    name: 'extra_field',
                                    value: fields
                                  }
                                }
                                handleChange(event)
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: 'notchedOutline'
                                },
                              }}
                            />
                          </div>
                          {['number', 'text'].includes(f.type) &&
                            <>
                              <div className='mt-2'>
                                <Box fontWeight={600} fontSize={14}>Placeholder</Box>
                                <CustomTextField 
                                  disabled={disabled}
                                  name="placeholder"
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                  placeholder='Placeholder'
                                  value={f.placeholder}
                                  onChange={(e) => {
                                    const fields = Object.assign([], submitData['extra_field'])
                                    const item = fields.find(e => e.id == field.id)
                                    const keys = Object.assign([], item.keys)
                                    const op = keys.find(e => e.id == f.id)
                                    op[e.target.name] = e.target.value
                                    item['keys'] = keys
                                    const event = {
                                      target: {
                                        name: 'extra_field',
                                        value: fields
                                      }
                                    }
                                    handleChange(event)
                                  }}
                                  InputProps={{
                                    classes: {
                                      notchedOutline: 'notchedOutline'
                                    },
                                  }}
                                />
                              </div>
                              {f.type == 'text' && 
                                <div className='mt-2'>
                                  <FormControlLabel 
                                    label={"Numeric String"}
                                    control={
                                      <Checkbox 
                                        disabled={disabled}
                                        checked={f.numericString === 1 ? true : false}
                                        onChange={(e) => {
                                          const fields = Object.assign([], submitData['extra_field'])
                                          const item = fields.find(e => e.id == field.id)
                                          const keys = Object.assign([], item.keys)
                                          const op = keys.find(e => e.id == f.id)
                                          op[e.target.name] = e.target.checked ? 1 : 0
                                          item['keys'] = keys
                                          const event = {
                                            target: {
                                              name: 'extra_field',
                                              value: fields
                                            }
                                          }
                                          handleChange(event)
                                        }}
                                        color="primary"
                                        name={'numericString'}
                                      />
                                    }
                                  />
                                </div>
                              }
                            </>
                          }
                          {['select', 'multiselect'].includes(f.type) &&
                            <>
                              <div className='mt-2'>
                                <FormControlLabel 
                                  label={"Dynamic options"}
                                  control={
                                    <Checkbox 
                                      disabled={disabled}
                                      checked={f.dynamic_option === 1 ? true : false}
                                      onChange={(e) => {
                                        const fields = Object.assign([], submitData['extra_field'])
                                        const item = fields.find(e => e.id == field.id)
                                        const keys = Object.assign([], item['keys'])
                                        const op = keys.find(e => e.id == f.id)
                                        op[e.target.name] = e.target.checked ? 1 : 0
                                        if (e.target.checked) {
                                          delete op['options']
                                        } else {
                                          op['options'] = []
                                        }
                                        item['keys'] = keys
                                        const event = {
                                          target: {
                                            name: 'extra_field',
                                            value: fields
                                          }
                                        }
                                        handleChange(event)
                                      }}
                                      color="primary"
                                      name={'dynamic_option'}
                                    />
                                  }
                                />
                              </div>
                              {f.dynamic_option == 1 ?
                                <div className='mt-2'>
                                  <Box fontWeight={600} fontSize={14}>Table</Box>
                                  <AsyncSelect 
                                    isDisabled={disabled}
                                    className="MuiFormControl-marginDense mt-2"
                                    loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                                      return new Promise(resolve => {
                                        const result = tables.filter(e => e.label.toLowerCase().includes((inputValue || '').toLowerCase()))
                                        resolve(result)
                                      })
                                    })}
                                    defaultOptions
                                    onInputChange={handleInputChange}
                                    name="route"
                                    onChange={(value) => {
                                      const fields = Object.assign([], submitData['extra_field'])
                                      const item = fields.find(e => e.id == field.id)
                                      const keys = Object.assign([], item['keys'])
                                      const op = keys.find(e => e.id == f.id)
                                      op['route'] = value.value
                                      item['keys'] = keys
                                      const event = {
                                        target: {
                                          name: 'extra_field',
                                          value: fields
                                        }
                                      }
                                      handleChange(event)
                                    }}
                                    placeholder={"Table..."}
                                    menuPortalTarget={document.body}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                    valueKey={"value"}
                                    value={tables.find(e => e.value == f.route)}
                                    styles={customStyles}
                                  />
                                </div> : 
                                <div className='mt-2'>
                                  <Box fontSize={14} fontWeight={600}>Options</Box>
                                  <div className='d-flex flex-column'>
                                    {f.options?.map(option => (
                                      <div className='d-flex align-items-center gap-1' key={option.id}>
                                        <div className='flex-1'>
                                          <CustomTextField 
                                            disabled={disabled}
                                            name="key"
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                            value={option.title}
                                            onChange={(e) => {
                                              const fields = Object.assign([], submitData['extra_field'])
                                              const item = fields.find(e => e.id == field.id)
                                              const keys = Object.assign([], item['keys'])
                                              const op = keys.find(e => e.id == f.id)
                                              const options = Object.assign([], op.options)
                                              const o = options.find(e => e.id == option.id)
                                              o['title'] = e.target.value
                                              op['options'] = options
                                              item['keys'] = keys
                                              const event = {
                                                target: {
                                                  name: 'extra_field',
                                                  value: fields
                                                }
                                              }
                                              handleChange(event)
                                            }}
                                            InputProps={{
                                              classes: {
                                                notchedOutline: 'notchedOutline'
                                              },
                                            }}
                                          />
                                        </div>
                                        {!disabled &&
                                        <div 
                                          className='cursor-pointer d-flex align-items-center justify-content-center' 
                                          style={{ padding: 4, height: 30, width: 30, border: '1px solid red', borderRadius: 6 }}
                                          onClick={() => {
                                            const fields = Object.assign([], submitData['extra_field'])
                                            const item = fields.find(e => e.id == field.id)
                                            const keys = Object.assign([], item['keys'])
                                            const op = keys.find(e => e.id == f.id)
                                            const options = op['options'].filter(e => e.id != option.id)
                                            op['options'] = options
                                            item['keys'] = keys
                                            const event = {
                                              target: {
                                                name: 'extra_field',
                                                value: fields
                                              }
                                            }
                                            handleChange(event)
                                          }}
                                        >
                                          <IconTrash size={16} color="red" />
                                        </div>
                                        }
                                      </div>
                                    ))}
                                  </div>
                                  {!disabled &&
                                  <Button 
                                    className='mt-2'
                                    variant="outlined" 
                                    color="primary"
                                    onClick={() => {
                                      const fields = Object.assign([], submitData['extra_field'])
                                      const item = fields.find(e => e.id == field.id)
                                      const keys = Object.assign([], item.keys)
                                      const op = keys.find(e => e.id == f.id)
                                      const options = Object.assign([], op['options'])
                                      options.push({id: makeId(12)})
                                      op['options'] = options
                                      item['keys'] = keys
                                      const event = {
                                        target: {
                                          name: 'extra_field',
                                          value: fields
                                        }
                                      }
                                      handleChange(event)
                                    }}
                                  >
                                    Add
                                  </Button>
                                  }
                                </div>
                              }

                            </>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                  {!disabled && 
                  <ButtonDropdown
                    className="mt-2"
                    variant="outlined"
                    actions={[
                      {
                        title: 'Select Field',
                        onClick: () => addChildField(field.id, 'select', submitData, handleChange),
                      },
                      {
                        title: 'Text Field',
                        onClick: () => addChildField(field.id, 'text', submitData, handleChange),
                      },
                      {
                        title: 'Number Field',
                        onClick: () => addChildField(field.id, 'number', submitData, handleChange),
                      },
                      {
                        title: 'Multiselect Field',
                        onClick: () => addChildField(field.id, 'multiselect', submitData, handleChange),
                      }
                    ]}
                  >
                    Add
                  </ButtonDropdown>
                  }
                </div>
              }
            </div>
          </div>
        ))}
      </div>
      {!disabled &&
      <ButtonDropdown
        className="mt-2"
        variant="outlined"
        actions={[
          {
            title: 'Select Field',
            onClick: () => addField('select', submitData, handleChange),
          },
          {
            title: 'Text Field',
            onClick: () => addField('text', submitData, handleChange),
          },
          {
            title: 'Number Field',
            onClick: () => addField('number', submitData, handleChange),
          },
          {
            title: 'Checkbox Field',
            onClick: () => addField('checkbox', submitData, handleChange),
          },
          {
            title: 'Radio Field',
            onClick: () => addField('radio', submitData, handleChange),
          },
          {
            title: 'Array Field',
            onClick: () => addField('array', submitData, handleChange),
            disabled: submitData['extra_field']?.find(e => ['multiselect', 'array'].includes(e.type)) ? true : false
          },
          {
            title: 'Multiselect Field',
            onClick: () => addField('multiselect', submitData, handleChange),
            disabled: submitData['extra_field']?.find(e => ['multiselect', 'array'].includes(e.type)) ? true : false
          }
        ]}
      >
        Add
      </ButtonDropdown>
      }
    </div>
  )
}

export default ExtraField