import React, { Component } from 'react';
import { withRouter } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';

const fields = [
  {
    type: 'text',
    name: 'name',
    label: 'Service ID',
    disabled: true,
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item.team_id?.team_search
  },
  {
    type: 'text',
    name: 'service_code',
    label: 'Service code',
    disabled: true,
    renderValue: (item) => item.product_id?.code
  },
  {
    type: 'text',
    name: 'product_id',
    label: 'Service',
    disabled: true,
    renderValue: (item) => item.product_id?.title
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Fee',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })
  },
  {
    type: 'text',
    name: 'end_date',
    label: 'End date',
    disabled: true,
    renderValue: (item) => item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : ''
  },
  {
    type: 'text',
    name: 'sale_convert',
    label: 'Sale convert',
    disabled: true,
    renderValue: (item) => item.create_uid?.email
  },
  {
    type: 'text',
    name: 'type_acc',
    label: 'Type Acc',
    disabled: true,
    renderValue: (item) => item.team_id?.type_acc
  },
]

class ServiceForm extends Component {

  render () {
    return (
      <ResourceForm 
        mode="view"
        fields={fields}
        route="app1/service"
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/service/${id}`).then(res => {
              if (res.data.success) {
                resolve(res.data.data)
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="Service Detail"
        actionBack={() => this.props.navigate('/app1/services')}
        {...this.props}
      />
    )
  }
}

export default withRouter(ServiceForm);