import { request } from '../../../_services_';
import icon10 from '../../../assets/icon10.png'
import icon3 from '../../../assets/icon3.png'
import icon11 from '../../../assets/icon11.png'
import icon12 from '../../../assets/icon12.png'
import icon13 from '../../../assets/icon13.png'
import icon14 from '../../../assets/icon14.jpeg'
import { Checkbox, Tooltip, Box, IconButton, Button, TextField, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { customStyles, errorStyles, disabledStyles, capitalizeStr, loadOptions, handleInputChange } from '../../../utils'
import { IconBrandMessenger, IconTrash } from '@tabler/icons';
import moment from 'moment';
import { withStyles } from "@mui/styles";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { validate } from 'validate.js';
import NumberFormat from 'react-number-format';
import { Uploader } from 'src/components';

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})(TextField);

const formsSchema = {
  domain: { 
    presence: { message: '^Required' },
  },
  platform: {
    presence: { message: '^Required' }
  },
  page_id: {
    presence: { message: '^Required' }
  },
  page_link: {
    presence: { message: '^Required' }
  },
  page_name: {
    presence: { message: '^Required' }
  },
}

export const listItems = [
  {
    id: 1,
    title: '4 SIN to create REF',
    content: 'SIN cần tạo REF',
    route: 'app3/sin_ref',
    icon: <img src={icon10} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 2,
    title: '6.1 RAC number',
    content: 'RAC khả dụng',
    route: 'app3/rac_numbers',
    icon: <img src={icon11} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 3,
    title: '6.31 REF to review',
    content: 'REF cần duyệt',
    route: 'app3/ref_reviews',
    icon: <img src={icon3} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 4,
    title: '6.37 REF web page qualified',
    content: 'REF cần submit',
    route: 'app3/ref_web',
    icon: <img src={icon12} style={{ width: 70, height: 70 }} />,
  },
  // {
  //   id: 5,
  //   title: '7.1 RAC Acc ID new',
  //   content: 'Acc ID được tạo',
  //   route: 'app3/rac_id_new',
  //   icon: <img src={icon13} style={{ width: 70, height: 70 }} />,
  // },
  // {
  //   id: 6,
  //   title: '7.2 RAC Warehouse',
  //   content: 'Acc RAC tổng',
  //   route: 'app3/rac_warehouses',
  //   icon: <img src={icon14} style={{ width: 70, height: 70 }} />,
  // },
  {
    id: 5,
    title: '7.2 RAC All',
    content: 'Acc RAC tổng',
    route: 'app3/rac_all',
    icon: <img src={icon14} style={{ width: 70, height: 70 }} />,
  }

]

export const partnerCodes = [
  { id: 'PD', name: 'PD' },
  { id: 'YL', name: 'YL' },
  { id: 'TD', name: 'TD' },
]

export const timeZoneAcc = [
  { id: 'GMT+7', name: 'GMT+7' },
  { id: 'GMT-7', name: 'GMT-7' },
]

export const refStatus = [
  { id: 'reseller_review', name: 'Reseller Review' },
  { id: 'changes_required', name: 'Changes Required' },
  { id: 'rejected', name: 'Rejected' },
]

export const accStatus = [
  {id: 'disabled', name: 'Disabled'},
  {id: 'active', name: 'Active'},
  {id: 'closed', name: 'Closed'},
  {id: 'peding_close', name: 'Pending Close'}
]

export const listSinRefFields = [
  {
    name: 'sin_number',
    label: 'SIN number',
    renderValue: (item) => item?.sin_id ? `${item?.sin_number}` : '',
    sort: true,
    filter:true,
    width: '8rem'
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? 
      <a 
        onClick={(e) => e.stopPropagation()}
        className='d-flex align-items-center'
        href={item?.team_id?.mess_group_link} 
        target="_blank" 
        rel="noreferrer"
      >
        <IconBrandMessenger size={20} stroke={2} />
      </a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item.creator ? `Team_${item.creator.team_id || ''} ${item.creator.shortname || ''}` : '',
    sort: true,
    sortName: 'teams.team_search',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1',
    width: '16rem'
  },
  {
    name: 'spent_per_month',
    label: 'Spent USD/ month',
    sort: true,
    renderValue: (item) => item.spent_per_month?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    width: '10rem'
  }, 
  {
    name: 'total_acc_request',
    label: 'Total Acc request',
    sort: true,
    width: '10rem'
  }, 
  {
    name: 'request_acc_timezone',
    label: 'Request Acc Timezone',
    sort: true
  },
  {
    name: 'product_type_id',
    label: 'Product Type',
    renderValue: (item) => item.product_type_id?.name,
    sort: true,
    sortName: 'product_type.name',
    width: '10rem'
  },
]

export const listSinFormFields = [
  {
    type: 'text',
    disabled: true,
    name: 'sin_number',
    label: 'SIN number',
    renderValue: (item) => item?.sin_id ? `${item?.sin_number}` : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item.creator ? `Team_${item.creator.team_id || ''} ${item.creator.shortname || ''}` : ''
  },
  {
    type: 'checkbox',
    disabled: true,
    name: 'pause',
    label: 'Pause',
    checked: (item) => item.pause == 1
  }, {
    type: 'text',
    disabled: true,
    name: 'other_note',
    label: 'Other note'
  }, {
    type: 'text',
    disabled: true,
    name: 'spent_per_month',
    label: 'Spent USD/ month',
    renderValue: (item) => item.spent_per_month?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
  }, {
    type: 'text',
    disabled: true,
    name: 'total_acc_request',
    label: 'Total Acc request'
  }, {
    type: 'text',
    disabled: true,
    name: 'request_acc_timezone',
    label: 'Request Acc Timezone'
  },
  {
    type: 'text',
    disabled: true,
    name: 'product_type',
    label: 'Product Type',
    renderValue: (item) => item.product_type_id?.name
  },
  {
    type: 'custom',
    disabled: true,
    name: 'domains',
    label: 'Domains',
    renderValue: (items, ctx) => 
      <div>
        <div>
          <Box fontSize={15} fontWeight='bold'>
            Domains (Domain, Platform)
          </Box>
        </div>
        {items?.domains?.map((item, index) =>
          <div className='d-flex' key={index}>
            <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
              <div className="flex-1">
                <CustomTextField
                  fullWidth
                  disabled={true}
                  margin='dense'
                  name='domain'
                  value={item.domain || ''}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      disabled: ctx.props.classes.input
                    }
                  }}
                />
              </div>
              <div className="flex-1">
                <CustomTextField
                  fullWidth
                  disabled={true}
                  margin='dense'
                  name='domain'
                  value={item.platform?.title || ''}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      disabled: ctx.props.classes.input
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
  },
  {
    type: 'custom',
    name: 'exists_at_another_agency',
    label: 'Have you ever created ad account with this domain and fanpage at other Agency?',
    renderValue: (item, ctx) => (
      <div className="mt-2">
        <Box fontSize={15} fontWeight="bold">Have you ever created ad account with this domain and fanpage at other Agency?</Box>
        <FormControl>
          <RadioGroup
            row
            name="exists_at_another_agency"
            type="radio"
            value={item.exists_at_another_agency ? 1 : 0}
            onChange={ctx.handleChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={true} />
            <FormControlLabel value={0} control={<Radio />} label="No" disabled={true} />
          </RadioGroup>
        </FormControl>
      </div>
    )
  },
  {
    type: 'custom',
    name: 'change_dns',
    label: 'Nếu khách hàng chạy Vpcs thì có thể tạm thời chỉnh sửa DNS của website không?',
    renderValue: (item, ctx) => (
      <div className="mt-2">
        <Box fontSize={15} fontWeight="bold">Nếu khách hàng chạy Vpcs thì có thể tạm thời chỉnh sửa DNS của website không?</Box>
        <FormControl>
          <RadioGroup
            row
            name="change_dns"
            type="radio"
            value={item.change_dns ? 1 : 0}
            onChange={ctx.handleChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={true} />
            <FormControlLabel value={0} control={<Radio />} label="No" disabled={true} />
          </RadioGroup>
        </FormControl>
      </div>
    )
  },
  {
    type: 'custom',
    disabled: true,
    name: 'pages',
    label: 'Pages',
    renderValue: (items, ctx) => 
    <div>
    <div style={{ marginTop: 10 }}>
      <div>
        <Box fontSize={15} fontWeight='bold'>
          Pages (Page Link, Page ID, Page Name)
        </Box>
      </div>
      {items?.pages?.map((item, index) => 
        <div className='d-flex' key={index}>
          <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
            <div className="flex-1">
              <CustomTextField
                fullWidth
                disabled={true}
                margin='dense'
                name="page_link"
                value={item.page_link || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    disabled: ctx.props.classes.input
                  }
                }}
              />
            </div>
            <div className="flex-1">
              <CustomTextField
                fullWidth
                disabled={true}
                margin='dense'
                name="page_name"
                value={item.page_name || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    disabled: ctx.props.classes.input
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  },
  {
    type: 'custom',
    name: 'bm_id',
    renderValue: (item, ctx) => 
      <div className='d-flex' style={{ marginTop: 10, gap: 10 }}>
        <div style={{ width: '50%' }}>
          <Box fontSize={15} fontWeight='bold'>
            BM ID
          </Box>
          <CustomTextField
            disabled
            fullWidth
            margin='dense'
            name="bm_id"
            onChange={() => ctx.handleChange()}
            value={item?.bm_id}
            variant="outlined"
            type="number"
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              }
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Box fontSize={15} fontWeight='bold'>
            BM Name
          </Box>
          <CustomTextField
            disabled
            fullWidth
            margin='dense'
            name="bm_name"
            onChange={() => ctx.handleChange()}
            value={item?.bm_name}
            variant="outlined"
            type="text"
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              }
            }}
          />
        </div>
      </div>
  }, 
  {
    type: 'text',
    name: 'bm_admin_link',
    label: 'BM ID 2 (preventive)'
  },
  {
    type: 'text',
    name: 'other_note',
    label: 'Note'
  },
]

export const listRacFields = [
  {
    name: 'name',
    label: 'RAC ID',
    renderValue: (item) => `${item?.name}`,
    sort: true,
    filter:true,
  },
  {
    name: 'cmn_number',
    label: 'CMN Number',
    renderValue: (item) =>  item.cmn_id ? `CMN ${item.cmn_id?.id}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'br_number',
    label: 'BR Number', 
    renderValue: (item) => item.br_id ? `BR ${item.br_id?.id}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'email_number',
    label: 'Email Number', 
    renderValue: (item) => item.email_id ? `Email ${item.email_id?.id}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'active',
    label: 'Disabled RAC', sort: true,

    renderValue: (item) => <Checkbox checked={!item.active} disabled color="primary" />
  },
  {
    name: 'batch',
    label: 'Batch', 
    renderValue: (item) => item.br_id?.batch,
    sort: true,
    filter:true,
  },
  {
    name: 'br_name',
    label: 'BR Name',
    renderValue: (item) => item.br_id?.name, 
    sort: true,
    filter:true,
  },
  {
    name: 'license_no',
    label: 'BR Unified Social Credit Number', 
    renderValue: (item) => item.br_id?.license_no,
    sort: true,
  },
  {
    name: 'chinese_name',
    label: 'BR Bussiness Name', 
    renderValue: (item) => item.br_id?.chinese_name,
    sort: true,
    filter:true,
    filterName:'chinese_name@ilike'

  },
  {
    name: 'english_name',
    label: 'BR Bussiness Name in English', 
    renderValue: (item) => item.br_id?.english_name,
    sort: true,
    filter:true,
    filterName:'br_translate@ilike'
  },
  {
    name: 'address',
    label: 'BR Registered Business Address',
    renderValue: (item) => item.br_id?.address,
    sort: true,
    filter: true,
    filterName: 'address@ilike'
  },
  {
    name: 'postal_code',
    label: 'BR Registered Postal Code', 
    renderValue: (item) => item.br_id?.postal_code,
    sort: true,
    filter:true,
    filterName:'postal_code@ilike'

  },
  {
    name: 'image_link',
    label: 'BR image link',
    renderValue: (item) => <Tooltip title={item.br_id?.image_link}>
      <div>{item.br_id?.image_link}</div>
    </Tooltip>,
    filter: true,
    sort: true
  },
  {
    name: 'city',
    label: 'BR English City Name of Your Registered Business Address',
    renderValue: (item) => item.br_id ? item.br_id?.english_name?.split(' ')[0] : null,
    filter: true,
    sort: true
  },
  {
    name: 'address_eng',
    label: 'BR Address (Eng)', 
    renderValue: (item) => item.br_id?.address_eng,
    sort: true,
    filter:true,
    filterName:'br_address@ilike'
  },
  {
    name: 'china_mobile_number',
    label: 'China Mobile Number', 
    renderValue: (item) => item.cmn_id?.china_mobile_number,
    sort: true,
    filter:true,
  },
  {
    name: 'email_number',
    label: '@gmail.com',
    renderValue: (item) => item.email_id?.email,
    sort: true,
    filter: true,
  },
  {
    name: 'web_page',
    label: 'Web & Page info need to change',
    renderValue: (item) => item.cmn_id && item.br_id &&
    <Tooltip title={
      <div>
        <div>{`Phone: ${item.cmn_id?.china_mobile_number}`}</div>
        <div>{`Company: ${item.br_id?.english_name}`}</div>
        <div>{`Address: ${item.br_id?.address_eng} ${item.br_id?.postal_code}`}</div>
      </div>
    } arrow placement='top'>
      <div>
        <div>{`Phone: ${item.cmn_id?.china_mobile_number}`}</div>
        <div>{`Company: ${item.br_id?.english_name}`}</div>
        <div>{`Address: ${item.br_id?.address_eng} ${item.br_id?.postal_code || ''}`}</div>
      </div>
    </Tooltip>
  },
]

export const listRacNumberFormFields = [
  {
    type: 'text',
    name: 'name',
    label: 'RAC ID',
    renderValue: (item) => `${item?.name}`,
    disabled: true,
    filter:true,
    sort:true
  },
  {
    type: 'select',
    name: 'cmn_id',
    label: 'CMN number',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/cmn_numbers', { search: inputValue, active: true }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.id, name: `CMN ${e.id}` })))
          }
        })
      })
    },
    renderValue: (item) => item.cmn_id ? { id: item.cmn_id?.id, name: `CMN ${item.cmn_id.id}` } : null
  },
  {
    type: 'select',
    name: 'br_id',
    label: 'BR number',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/br_numbers', { search: inputValue, active: true }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.id, name: `BR ${e.id}` })))
          }
        })
      })
    },
    renderValue: (item) => item.br_id ? { id: item.br_id?.id, name: `BR ${item.br_id.id}` } : null
  },
  {
    type: 'select',
    name: 'email_id',
    label: 'Email number',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/email_numbers', { search: inputValue, active: true }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.id, name: `Email ${e.id}` })))
          }
        })
      })
    },
    renderValue: (item) => item.email_id ? { id: item.email_id?.id, name: `Email ${item.email_id.id}` } : null
  },
  {
    type: 'checkbox',
    name: 'active',
    label: 'Disabled RAC',
    checked: (item) => !item.active,
    onChange: (event, ctx) => {
      var e = {
        target: {
          name: 'active',
          value: !event.target.checked
        }
      }
      ctx.handleChange(e)
    }
  },
  {
    type: 'text',
    name: 'batch',
    label: 'Batch',
    renderValue: (item) => item.br_id?.batch,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'br_name',
    label: 'BR Name',
    renderValue: (item) => item.br_id?.name, 
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'license_no',
    label: 'BR Unified Social Credit Number', 
    renderValue: (item) => item.br_id?.license_no, 
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'chinese_name',
    label: 'BR Bussiness Name', 
    renderValue: (item) => item.br_id?.chinese_name,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'english_name',
    label: 'BR Bussiness Name in English', 
    renderValue: (item) => item.br_id?.english_name,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'address',
    label: 'BR Registered Business Address',
    renderValue: (item) => item.br_id?.address,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'postal_code',
    label: 'BR Registered Postal Code', 
    renderValue: (item) => item.br_id?.postal_code,
    disabled: true,
    filter:true,
  },
  {
    type: 'custom',
    name: 'image_link',
    label: 'BR image link', 
    renderValue: (item, ctx) => 
    <div style={{ marginTop: 10 }}>
      <Box fontSize={15} fontWeight='bold'>
        BR Image Link
      </Box>
      <CustomTextField 
        fullWidth
        disabled={true}
        margin='dense'
        name='image_link'
        value={item.br_id?.image_link || ''}
        variant="outlined"
        InputProps={{
          classes: {
            disabled: ctx.props.classes.input
          }
        }}
      />
    </div>
    ,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'city',
    label: 'BR English City Name of Your Registered Business Address',
    renderValue: (item) => item.br_id ? item.br_id?.english_name?.split(' ')[0] : null,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'address_eng',
    label: 'BR Address (Eng)', 
    renderValue: (item) => item.br_id?.address_eng,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'china_mobile_number',
    label: 'China Mobile Number', 
    renderValue: (item) => item.cmn_id?.china_mobile_number,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'email_number',
    label: '@gmail.com',
    renderValue: (item) => item.email_id?.email,
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'web_page',
    label: 'Web & Page info need to change',
    renderValue: (item) => 
    item.cmn_id && item.br_id && 
      `Phone: ${item.cmn_id?.china_mobile_number}\nCompany: ${item.br_id?.english_name}\nAddress: ${item.br_id?.address_eng} ${item.br_id?.postal_code || ''}`
    ,
    disabled: true,
    rows: 1.5,
    multiline: true,
    filter:true,
  },
]

export const listRef2ReviewFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD-MM-YYYY HH:mm:ss') : '',
    sort: true
  },
  {
    name: 'name',
    label: 'REF number',
    renderValue: (item) => `${item.name}`,
    sort: true,
    filter:true,
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `${item.team_id.title}` : '',
    sort: true,
    filter: true
  },
  {
    name: 'signup_webinfo.sin_number',
    label: 'SIN number',
    renderValue: (item) => item.sin_id ? `${item.sin_id.sin_number}` : '',
    sort: true,
    filter:true,
    filterName:'signup_webinfo.sin_number',
  },
  {
    name: 'rac_id.name',
    label: 'RAC number',
    renderValue: (item) => item.rac_id ? `${item.rac_id.name}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'partner_code',
    label: 'Partner code', 
    sort: true,
    filter:true,
  },
  {
    name: 'onboard_link',
    label: 'Onboard link', 
    sort: true,
    filter:true,
  },
  {
    name: 'via_id',
    label: 'VIA ID',
    renderValue: (item) => item.via_id,
    sort: true,
    filter: true
  },
  {
    name: 'advise_id',
    label: 'Advise', sort: true,
    renderValue: (item) => item.advise_id?.name
  },
  {
    name: 'total_acc_request',
    label: 'Total Acc request',
    renderValue: (item) => item.sin_id?.total_acc_request,
    sort: true,
    filter: true
  },
  {
    name: 'acc_timezone',
    label: 'Request Acc Timezone',
    renderValue: (item) => item.sin_id?.request_acc_timezone,
    sort: true
  },
]

export const formRef2ReviewFields = [
  {
    type: 'text',
    disabled: true,
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD-MM-YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'id',
    label: 'REF number',
    renderValue: (item) => `REF ${item.id}`
  },
  {
    type: 'text',
    disabled: true,
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `${item.team_id.title}` : '',
  },
  {
    type: 'text',
    disabled: true,
    name: 'sin_id',
    label: 'SIN number',
    renderValue: (item) => item.sin_id ? `${item.sin_id.sin_number}` : '',
  },
  {
    type: 'text',
    disabled: true,
    name: 'rac_id',
    label: 'RAC number',
    renderValue: (item) => item.rac_id ? `${item.rac_id.name}` : '',
  },
  {
    type: 'select',
    name: 'partner_code',
    label: 'Partner code',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/partner_code', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({id: e.id, name: e.name})))
          }
        })
      })
    },
    renderValue: (item) => item.partner_code ? item.partner_code?.id ? item.partner_code : {id: item.partner_code, name: item.partner_code} : ''
  },
  {
    name: 'via_id',
    label: 'VIA ID',
    type: 'text',
    renderValue: (item) => item.via_id,
  },
  {
    name: 'onboard_link',
    label: 'Onboard link',
    type: 'text',
    renderValue: (item) => item.onboard_link,
    disabled: true
  },
  {
    type: 'select',
    name: 'advise_id',
    label: 'Advise',
    loadOptions: (inputValue) => { 
      return new Promise(resolve => {
        request.get('/admin/v1/users', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.uid, name: e.shortname })))
          }
        })
      })
    },
    renderValue: (item) => item.advise_id
  },
  {
    name: 'note',
    label: 'Note',
    type: 'text',
  },
  {
    name: 'temp_review',
    type: 'checkbox',
    label: 'Duyệt sơ bộ',
    checked: (item) => item.temp_review
  },
  {
    type: 'checkbox',
    name: 'webpage_qualified_content',
    label: 'Webs & Pages qualified content',
    checked: (item) => item.webpage_qualified_content,
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'pause',
    label: 'Pause',
    checked: (item) => item.pause
  },
  {
    type: 'checkbox',
    name: 'cancel',
    label: 'Cancel',
    checked: (item) => item.cancel
  },
  {
    type: 'text',
    name: 'total_acc_request',
    label: 'Total Acc request',
    renderValue: (item) => item.sin_id?.total_acc_request,
    disabled: true
  },
  {
    name: 'acc_timezone',
    label: 'Request Acc Timezone',
    renderValue: (item) => item.sin_id?.request_acc_timezone,
    type: 'text',
    disabled: true
  },
  // {
  //   name: 'bm_admin_link',
  //   label: 'BM Link Admin Role',
  //   renderValue: (item) => item.sin_id?.bm_admin_link,
  //   type: 'text',
  //   disabled: true
  // },
  {
    type: 'text',
    name: 'web_page',
    label: 'Web & Page info need to change',
    renderValue: (item) => 
    item.rac_id && 
    item.rac_id.cmn_id && item.rac_id.br_id && 
      `Phone: ${item.rac_id.cmn_id?.china_mobile_number}\nCompany: ${item.rac_id.br_id?.english_name}\nAddress: ${item.rac_id.br_id?.address_eng} ${item.rac_id.br_id?.postal_code || ''}`
    ,
    disabled: true,
    rows: 1.5,
    multiline: true,
  },
  // {
  //   type: 'text',
  //   name: 'id_facebook',
  //   label: 'ID FaceBook',
  //   renderValue: (item) => item.id_facebook,
  //   disabled: true
  // },
  // {
  //   type: 'text',
  //   name: 'share_admin_page',
  //   label: 'Share admin rights of  your Pages to Drebest by?',
  //   disabled: true
  // },
  {
    type: 'custom',
    name: 'domains',
    renderValue: (items, ctx) => 
    <div>
      <div>
        <Box fontSize={15} fontWeight='bold'>
          {`Domains`}
        </Box>
      </div>
      {items?.sin_id?.domains?.map((item, index) =>
        <div className='d-flex' key={index}>
          <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
            <div style={{ width: '50%' }}>
              <CustomTextField
                fullWidth
                disabled={true}
                margin='dense'
                name='domain'
                onChange={(event) => {
                  item.domain = event.target.value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  const e = {
                    target: {
                      name: 'domains',
                      value: [...items.sin_id?.domains]
                    }
                  }
                  ctx.handleChange(e)
                }}
                value={item.domain || ''}
                variant="outlined"
                placeholder='https://'
                InputProps={{
                  classes: {
                    disabled: ctx.props.classes.input
                  }
                }}
                error={item.errors?.domain}
                helperText={item.errors?.domain ? item.errors.domain[0] : ''}
              />
            </div>
            <div style={{ width: '50%' }}>
              <AsyncCreatableSelect
                isDisabled={true}
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/platforms', { name: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      } else {
                        resolve([])
                      }
                    }, error => {
                      resolve([])
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name='platform'
                onChange={(value) => {
                  item.platform = value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  var e = {
                    target: {
                      name: "domains",
                      value: [...items.sin_id?.domains]
                    },
                  };
                  ctx.handleChange(e)
                }}
                placeholder={`Platform of domain ${index + 1}`}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ platform_id }) => platform_id}
                valueKey={"platform_id"}
                value={item?.platform || null}
                styles={disabledStyles}
                getNewOptionData={(inputValue, optionLabel) => ({
                  platform_id:inputValue,
                  name: 'Create \"' + inputValue + '\"...',
                  __isNew__: true
                })}
                onCreateOption={(inputValue) => {
                  request.post('/api/v1/platforms', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      var e = {
                        target: {
                          name: "platform",
                          value:res.data.data
                        },
                      };
                      ctx.handleChange(e)
                    } else {
                      toastr.error(res.data.msg)
                    }
                  }, err => toastr.error(err))
                }}
              />
              {item.errors?.platform ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{item.errors.platform[0]}</span> : null}
            </div>
          </div>
        </div>
      )}
    </div>,
  },
  {
    type: 'custom',
    name: 'pages',
    label: 'Pages',
    renderValue: (items, ctx) => 
      <div>
        <div style={{ marginTop: 10 }}>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              {`Pages`}
            </Box>
          </div>
          {items?.sin_id?.pages?.map((item, index) => 
            <div className='d-flex' key={index}>
              <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
                <div style={{ width: '50%' }}>
                  <CustomTextField
                    fullWidth
                    disabled={true}
                    margin='dense'
                    name="page_link"
                    onChange={(event) => {
                      item.page_link = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_link || ''}
                    variant="outlined"
                    placeholder='https://'
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_link}
                    helperText={item.errors?.page_link ? item.errors.page_link[0] : ''}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <CustomTextField
                    fullWidth
                    disabled={true}
                    placeholder={`Page ${index + 1} - Name`}
                    margin='dense'
                    name="page_name"
                    onChange={(event) => {
                      item.page_name = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items?.sin_id?.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_name || ''}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_name}
                    helperText={item.errors?.page_name ? item.errors.page_name[0] : ''}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  },
  {
    type: 'text',
    name: 'bm_id',
    label: 'BM ID',
    renderValue: (item) => item?.sin_id?.bm_id,
    disabled: true
  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name',
    renderValue: (item) => item?.sin_id?.bm_name,
    disabled: true
  },
  {
    type: 'text',
    name: 'ty_acc',
    label: 'Type ACC',
    renderValue: (item) => item?.team_id?.type_acc,
    disabled: true
  },
  {
    type: 'text',
    name: 'license_no',
    label: 'BR Unified Social Credit Number',
    renderValue: (item) => item?.rac_id?.br_id?.license_no,
    disabled: true
  },
  {
    type: 'text',
    name: 'chinese_name',
    label: 'BR Business Name',
    renderValue: (item) => item?.rac_id?.br_id?.chinese_name,
    disabled: true
  },
  {
    name: 'br_translate',
    label: 'BR Business Name in English',
    renderValue: (item) => item.rac_id?.br_id?.english_name,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_email',
    label: '@gmail.com',
    renderValue: (item) => item.rac_id?.email_id?.email,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_address',
    label: 'BR Registered Business Address',
    renderValue: (item) => item.rac_id?.br_id?.address,
    type: 'text',
    disabled: true
  },
  {
    name: 'postal_code',
    label: 'BR Registered Postal Code',
    renderValue: (item) => item.rac_id?.br_id?.postal_code,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_city',
    label: 'BR English City Name of Your Registered Business Address',
    renderValue: (item) => item.rac_id?.br_id?.address_eng,
    type: 'text',
    disabled: true
  },
  {
    name: 'time_approve',
    label: 'Time Approved',
    type: 'text',
    disabled: true,
    renderValue: (item) => item.time_approve ? moment(item.time_approve).utcOffset('+0700').format('ddd, DD MMM YYYY HH:mm:ss [GMT]ZZ') : ''
  },
  {
    name: 'month_approve',
    label: 'Month of REF status',
    type: 'text',
    renderValue:(item) => item.time_approve ? moment(item.time_approve).utcOffset('+0700').format('YYYY-MM') : '',
    disabled: true
  },
  {
    name: 'business_type',
    label: 'Business Type',
    type: 'text',
    renderValue: (item) => `Ecommerce`,
    disabled: true
  },
  {
    type: 'text',
    name: 'account_code',
    label: 'Account Name',
    renderValue: (item) => item.account_code && item.account_code.join('\n'),
    rows: (item) => (item.account_code?.length)/2 || 1,
    multiline: true,
    disabled: true
  }
]

export const listRefWebFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD-MM-YYYY HH:mm:ss') : '',
    sort: true
  },
  {
    name: 'name',
    label: 'REF number',
    renderValue: (item) => `${item.name}`,
    sort: true,
    filter:true,
  },
  {
    name: 'status',
    label: 'Status of REF',
    renderValue: (item) => refStatus.find(e => e.id === item.status)?.name || item.status,
    sort: true,
    filter: true
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `${item.team_id.title}` : '',
    sort: true,
    filter: true
  },
  {
    name: 'signup_webinfo.sin_number',
    label: 'SIN number',
    renderValue: (item) => item.sin_id ? `${item.sin_id.sin_number}` : '',
    sort: true,
    filter:true,
    filterName:'signup_webinfo.sin_number',
  },
  {
    name: 'rac_id.name',
    label: 'RAC number',
    renderValue: (item) => item.rac_id ? `${item.rac_id.name}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'partner_code',
    label: 'Partner code', 
    sort: true,
    filter:true,
  },
  {
    name: 'onboard_link',
    label: 'Onboard link', 
    sort: true,
    filter:true,
  },
  {
    name: 'via_id',
    label: 'VIA ID',
    renderValue: (item) => item.via_id,
    sort: true,
    filter: true
  },
  {
    name: 'advise_id',
    label: 'Advise', sort: true,
    renderValue: (item) => item.advise_id?.name
  },
  {
    name: 'user_submit_form_id',
    label: 'Submit Form',
    renderValue: (item) => item.user_submit_form_id?.name,
    sort: true,
    filter: true
  },
  {
    name: 'manager_id',
    label: 'Manager',
    renderValue: (item) => item.manager_id?.name,
    sort: true,
    filter: true
  },
  {
    name: 'total_acc_request',
    label: 'Total Acc request',
    renderValue: (item) => item.sin_id?.total_acc_request,
    sort: true,
    filter: true
  },
  {
    name: 'acc_timezone',
    label: 'Request Acc Timezone',
    renderValue: (item) => item.sin_id?.request_acc_timezone,
    sort: true
  },
]

export const formRefWebFields = [
  {
    type: 'text',
    disabled: true,
    name: 'create_date',
    label: 'Timestamp',
    renderValue:(item) => item.create_date ? moment(item.create_date).format('DD-MM-YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'id',
    label: 'REF number',
    renderValue: (item) => `REF ${item.id}`
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status of REF',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = refStatus.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.status == 'approved' ? { id: item.status, name: item.status } : refStatus.find(e => e.id == item.status),
    disabled: (item) => item.status == 'approved' ? true : false
  },
  {
    type: 'text',
    disabled: true,
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `${item.team_id.title}` : '',
  },
  {
    type: 'text',
    disabled: true,
    name: 'sin_id',
    label: 'SIN number',
    renderValue: (item) => item.sin_id ? `${item.sin_id.sin_number}` : '',
  },
  {
    type: 'text',
    disabled: true,
    name: 'rac_id',
    label: 'RAC number',
    renderValue: (item) => item.rac_id ? `${item.rac_id.name}` : '',
  },
  {
    type: 'text',
    name: 'partner_code',
    label: 'Partner code',
    renderValue: (item) => item.partner_code || '',
    disabled: true
  },
  {
    type: 'text',
    name: 'onboard_link',
    label: 'Onboard link',
    renderValue: (item) => item.onboard_link,
    disabled: true
  },
  {
    name: 'via_id',
    label: 'VIA ID',
    type: 'text',
    renderValue: (item) => item.via_id,
  },
  {
    type: 'select',
    name: 'advise_id',
    label: 'Advise',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/users', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.uid, name: e.shortname })))
          }
        })
      })
    },
    valueKey: 'id',
    renderValue: (item) => item.advise_id
  },
  {
    type: 'select',
    name: 'user_submit_form_id',
    label: 'Submit Form',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/users', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.uid, name: e.shortname })))
          }
        })
      })
    },
    valueKey: 'id',
    renderValue: (item) => item.user_submit_form_id
  },
  {
    type: 'select',
    name: 'manager_id',
    label: 'Manager',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/users', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({ id: e.uid, name: e.shortname })))
          }
        })
      })
    },
    valueKey: 'id',
    renderValue: (item) => item.manager_id
  },
  {
    name: 'note',
    label: 'Note',
    type: 'text',
  },
  {
    name: 'temp_review',
    type: 'checkbox',
    label: 'Duyệt sơ bộ',
    checked: (item) => item.temp_review
  },
  {
    type: 'checkbox',
    name: 'webpage_qualified_content',
    label: 'Webs & Pages qualified content',
    checked: (item) => item.webpage_qualified_content,
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'pause',
    label: 'Pause',
    checked: (item) => item.pause
  },
  {
    type: 'checkbox',
    name: 'cancel',
    label: 'Cancel',
    checked: (item) => item.cancel
  },
  {
    type: 'text',
    name: 'total_acc_request',
    label: 'Total Acc request',
    renderValue: (item) => item.sin_id?.total_acc_request,
    disabled: true
  },
  {
    name: 'acc_timezone',
    label: 'Request Acc Timezone',
    renderValue: (item) => item.sin_id?.request_acc_timezone,
    type: 'text',
    disabled: true
  },
  // {
  //   name: 'bm_admin_link',
  //   label: 'BM Link Admin Role',
  //   renderValue: (item) => item.sin_id?.bm_admin_link,
  //   type: 'text',
  //   disabled: true
  // },
  {
    type: 'text',
    name: 'web_page',
    label: 'Web & Page info need to change',
    renderValue: (item) => 
    item.rac_id && 
    item.rac_id.cmn_id && item.rac_id.br_id && 
      `Phone: ${item.rac_id.cmn_id?.china_mobile_number}\nCompany: ${item.rac_id.br_id?.english_name}\nAddress: ${item.rac_id.br_id?.address_eng} ${item.rac_id.br_id?.postal_code || ''}`
    ,
    disabled: true,
    rows: 1.5,
    multiline: true,
  },
  // {
  //   type: 'text',
  //   name: 'id_facebook',
  //   label: 'ID FaceBook',
  //   renderValue: (item) => item.id_facebook,
  //   disabled: true
  // },
  // {
  //   type: 'text',
  //   name: 'share_admin_page',
  //   label: 'Share admin rights of  your Pages to Drebest by?',
  //   disabled: true
  // },
  {
    type: 'custom',
    name: 'domains',
    renderValue: (items, ctx) => 
    <div>
      <div>
        <Box fontSize={15} fontWeight='bold'>
          {`Domains`}
        </Box>
      </div>
      {items?.sin_id?.domains?.map((item, index) =>
        <div className='d-flex' key={index}>
          <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
            <div style={{ width: '50%' }}>
              <CustomTextField
                fullWidth
                disabled={true}
                margin='dense'
                name='domain'
                onChange={(event) => {
                  item.domain = event.target.value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  const e = {
                    target: {
                      name: 'domains',
                      value: [...items.sin_id?.domains]
                    }
                  }
                  ctx.handleChange(e)
                }}
                value={item.domain || ''}
                variant="outlined"
                placeholder='https://'
                InputProps={{
                  classes: {
                    disabled: ctx.props.classes.input
                  }
                }}
                error={item.errors?.domain}
                helperText={item.errors?.domain ? item.errors.domain[0] : ''}
              />
            </div>
            <div style={{ width: '50%' }}>
              <AsyncCreatableSelect
                isDisabled={true}
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/platforms', { name: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      } else {
                        resolve([])
                      }
                    }, error => {
                      resolve([])
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name='platform'
                onChange={(value) => {
                  item.platform = value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  var e = {
                    target: {
                      name: "domains",
                      value: [...items.sin_id?.domains]
                    },
                  };
                  ctx.handleChange(e)
                }}
                placeholder={`Platform of domain ${index + 1}`}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ platform_id }) => platform_id}
                valueKey={"platform_id"}
                value={item?.platform || null}
                styles={disabledStyles}
                getNewOptionData={(inputValue, optionLabel) => ({
                  platform_id:inputValue,
                  name: 'Create \"' + inputValue + '\"...',
                  __isNew__: true
                })}
                onCreateOption={(inputValue) => {
                  request.post('/api/v1/platforms', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      var e = {
                        target: {
                          name: "platform",
                          value:res.data.data
                        },
                      };
                      ctx.handleChange(e)
                    } else {
                      toastr.error(res.data.msg)
                    }
                  }, err => toastr.error(err))
                }}
              />
              {item.errors?.platform ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{item.errors.platform[0]}</span> : null}
            </div>
          </div>
        </div>
      )}
    </div>,
  },
  {
    type: 'custom',
    name: 'pages',
    label: 'Pages',
    renderValue: (items, ctx) => 
      <div>
        <div style={{ marginTop: 10 }}>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              {`Pages`}
            </Box>
          </div>
          {items?.sin_id?.pages?.map((item, index) => 
            <div className='d-flex' key={index}>
              <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
                <div style={{ width: '50%' }}>
                  <CustomTextField
                    fullWidth
                    disabled={true}
                    margin='dense'
                    name="page_link"
                    onChange={(event) => {
                      item.page_link = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_link || ''}
                    variant="outlined"
                    placeholder='https://'
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_link}
                    helperText={item.errors?.page_link ? item.errors.page_link[0] : ''}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <CustomTextField
                    fullWidth
                    disabled={true}
                    placeholder={`Page ${index + 1} - Name`}
                    margin='dense'
                    name="page_name"
                    onChange={(event) => {
                      item.page_name = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items?.sin_id?.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_name || ''}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_name}
                    helperText={item.errors?.page_name ? item.errors.page_name[0] : ''}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  },
  {
    type: 'text',
    name: 'bm_id',
    label: 'BM ID',
    renderValue: (item) => item?.sin_id?.bm_id,
    disabled: true
  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name',
    renderValue: (item) => item?.sin_id?.bm_name,
    disabled: true
  },
  {
    type: 'text',
    name: 'ty_acc',
    label: 'Type ACC',
    renderValue: (item) => item?.team_id?.type_acc,
    disabled: true
  },
  {
    type: 'text',
    name: 'license_no',
    label: 'BR Unified Social Credit Number',
    renderValue: (item) => item?.rac_id?.br_id?.license_no,
    disabled: true
  },
  {
    type: 'text',
    name: 'chinese_name',
    label: 'BR Business Name',
    renderValue: (item) => item?.rac_id?.br_id?.chinese_name,
    disabled: true
  },
  {
    name: 'br_translate',
    label: 'BR Business Name in English',
    renderValue: (item) => item.rac_id?.br_id?.english_name,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_email',
    label: '@gmail.com',
    renderValue: (item) => item.rac_id?.br_id?.email,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_credit_number',
    label: 'BR Unified Social Credit Number',
    renderValue: (item) => item.rac_id?.br_id?.license_no,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_address',
    label: 'BR Registered Business Address',
    renderValue: (item) => item.rac_id?.br_id?.address,
    type: 'text',
    disabled: true
  },
  {
    name: 'postal_code',
    label: 'BR Registered Postal Code',
    renderValue: (item) => item.rac_id?.br_id?.postal_code,
    type: 'text',
    disabled: true
  },
  {
    name: 'br_city',
    label: 'BR English City Name of Your Registered Business Address',
    renderValue: (item) => item.rac_id?.br_id?.address_eng,
    type: 'text',
    disabled: true
  },
  {
    name: 'time_approve',
    label: 'Time Approved',
    type: 'text',
    disabled: true,
    renderValue: (item) => item.time_approve ? moment(item.time_approve).utcOffset('+0700').format('ddd, DD MMM YYYY HH:mm:ss [GMT]ZZ') : ''
  },
  {
    name: 'month_approve',
    label: 'Month of REF status',
    type: 'text',
    renderValue:(item) => item.time_approve ? moment(item.time_approve).utcOffset('+0700').format('YYYY-MM') : '',
    disabled: true
  },
  {
    name: 'business_type',
    label: 'Business Type',
    type: 'text',
    renderValue: (item) => `Ecommerce`,
    disabled: true
  },
  {
    type: 'text',
    name: 'account_code',
    label: 'Account Name',
    renderValue: (item) => item.account_code && item.account_code.join('\n'),
    rows: (item) => (item.account_code?.length)/2 || 1,
    multiline: true,
    disabled: true
  }
]

export const listRacIdNewFields = [
  {
    name: 'code',
    label: 'RAC ID',
    sort: true,
    filter:true,

  },
  {
    name: '"REF_number"',
    label: 'REF number manual',
    sort: true,
    filter:true,
    filterName: 'REF_number',

  },
  {
    name: 'sin_number',
    label: 'SIN number',
    sort: true,
    filter:true,

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item.team_id?.team_search} ${item.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'teams.team_search',
    filter:true,
    filterName: 'teams.team_search|teams.owner_name*1',
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort: true,
    filter:true,

  },
  {
    name: 'name',
    label: 'Acc Name',
    sort: true,
    filter:true,

  },
  {
    name: 'timezone',
    label: 'Acc Timezone',
    sort: true,

  },
  {
    name: 'time_zone2',
    label: 'BM ID 2 (preventive)',
    sort: true,

  },
  {
    name: 'timezone3',
    label: 'Acc Timezone (manual)',
    sort: true,

  },
  {
    name: 'name3',
    label: 'Manual Acc Name',
    sort: true,

  },
  {
    name: 'spent_check_fb',
    label: 'Cần check số Spent trên FB',
    sort: true,

  },
  {
    name: 'time_zone4',
    label: 'Page 1 - Name Link',
    sort: true,

  },
  {
    name: 'time_zone5',
    label: 'Page 2 - Name Link',
    sort: true,

  },
  {
    name: 'time_zone6',
    label: 'Spent 1 (before)_87',
    sort: true,

  },
  {
    name: 'spent_panda7',
    label: 'Spent by Panda',
    sort: true,

  },
  {
    name: 'time_zone8',
    label: 'Chênh Tool & PD',
    sort: true,

  },
  {
    name: 'time_zone9',
    label: 'Prefix',
    sort: true,

  }
]


export const formRacIdNewFields = [
  {
    type: 'text',
    name: 'account_id',
    label: 'RAC ID',
    renderValue: (item) => `RAC ${item.account_id}`,
    disabled: true
  },
  {
    type: 'select',
    name: 'ref_id',
    label: 'REF number manual',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/ref_reviews', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    getOptionLabel: (item) => `REF ${item.id}`,
    valueKey: 'id',
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'ACC ID',
  },
  {
    type: 'select',
    name: 'timezone',
    label: 'Acc Time zone',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timeZoneAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.timezone ? timeZoneAcc.find(e => e.id == item.timezone) : null,
    labelKey: 'name',
    valueKey: 'id'
  },
  {
    type: 'text',
    name: 'name',
    label: 'Manual Acc Name',
  },
  {
    type: 'number',
    name: 'spent_check_fb',
    label: 'Cần check số spent trên FB',
  },
  ,
  {
    type: 'number',
    name: 'spent_panda',
    label: 'Spent by Panda',
    disabled: true
  }
]

export const listRacAllFields = [
  {
    name: 'time_approve',
    label: 'Timestamp',
    renderValue: (item) => item.ref_id?.time_approve ? moment(item.ref_id?.time_approve).format('DD-MM-YYYY HH:mm:ss') : '',
    sort: true,
    filter: true
  },
  {
    name: 'acc_code',
    label: 'Acc Code',
    sort:true,
    filter:true,
  },
  {
    name: 'ref_id',
    label: 'REF number',
    renderValue: (item) => item.ref_id ? `${item.ref_id?.title}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.ref_id?.team_id ? `${item.ref_id?.team_id?.title}` : '',
    sort: true,
    filter:true,
  },
  {
    name: 'signup_webinfo.sin_number',
    label: 'SIN number',
    renderValue: (item) => item.ref_id?.sin_id ? `${item.ref_id?.sin_id?.sin_number}` : '',
    sort: true,
    filter:true,
    filterName:'signup_webinfo.sin_number',
  },
  {
    name: 'acc_id',
    label: 'ACC ID',
    renderValue: (item) => item?.acc_id,
    sort: true,
    filter: true
  },
  {
    name: 'timezone',
    label: 'ACC Timezone',
    renderValue: (item) => item.ref_id?.sin_id ? `${item.ref_id?.sin_id?.request_acc_timezone}` : '',
    sort:true,
    sortName:'team_id',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1',

  },
  {
    name: 'partner_code',
    label: 'Partner Code',
    renderValue: (item) => item.ref_id?  item.ref_id.partner_code : '',
    sort:true,
    sortName:'ref_reviews.signup_webinfo.partner_code',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.partner_code',
  },
  {
    name: 'acc_name',
    label: 'ACC Name',
    renderValue: (item) => item.ref_id?.team_id && item.ref_id?.rac_id ? `Drebest ${item.ref_id?.team_id?.team_search} ${item.name}` : '',
    sort:true,
    sortName:'ref_reviews.signup_webinfo.sin_number',
    filter:true,
    filterName:'ref_reviews.signup_webinfo.sin_number',

  },
  {
    name: 'status',
    label: 'Status',
    sort:true,
    filter:true,

  },
  {
    name: 'keep_deposit',
    label: 'Keep Deposit',
    renderValue: (item) => item.keep_deposit ? <NumberFormat value={item.keep_deposit || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'end_spent_proof',
    label: 'End spend proof',
    sort:true,
  },
  {
    name: 'end_spent_number',
    label: 'End Spend Number',
    renderValue:(item) => item.end_spent_number ? <NumberFormat value={item.end_spent_number || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'delta_spending_limit',
    label: 'Delta Spending Limit',
    sort: true
  },
  {
    name: 'spent',
    label: 'Spent',
    renderValue:(item) => item.end_spent_number ? <NumberFormat value={item.end_spent_number || 0} displayType="text" thousandSeparator={','} /> : '',
    sort:true,
  },
  {
    name: 'delta_remaining',
    label: 'Delta Remaining',
    renderValue:(item) => item.delta_remaining ? <NumberFormat value={item.delta_remaining || 0} displayType="text" thousandSeparator={','} /> : '',
    sort: true
  },
  {
    name: 'revoke_done',
    label: 'Thu hồi Done',
    renderValue: (item) => <Checkbox disabled checked={item.revoke_done} color="primary" />,
    sort:true,
  },
]

export const listRacAllFormFields = [
  {
    type: 'text',
    disabled: true,
    name: 'time_approve',
    label: 'Timestamp',
    renderValue: (item) => item.ref_id?.time_approve ? moment(item.ref_id.time_approve).format('DD-MM-YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    name: 'acc_code',
    label: 'Acc Code',
    renderValue: (item) => item.acc_code,
    disabled: true,
  },
  {
    type: 'text',
    name: 'ref_id',
    label: 'REF number',
    renderValue: (item) => item.ref_id ? `${item.ref_id?.title}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.ref_id?.team_id ? `${item.ref_id?.team_id?.title}` : '',
    disabled: true,
  },
  {
    type: 'text',
    name: 'signup_webinfo.sin_number',
    label: 'SIN number',
    renderValue: (item) => item.ref_id?.sin_id ? `${item.ref_id?.sin_id?.sin_number}` : '',
    disabled: true,
  },
  {
    type: 'custom',
    name: 'acc_id',
    renderValue: (item, ctx) => <div style={{ marginTop: 10 }}>
      <Box fontSize={15} fontWeight='bold'>ACC ID</Box>
      <CustomTextField
        disabled={ ctx.props.mode == 'view' }
        value={item.acc_id || ''}
        onChange={ctx.handleChange}
        inputProps={{ 
          inputMode: "numeric", 
          pattern: "[0-9]*"
        }}
        type="number"
        fullWidth
        margin='dense'
        name='acc_id'
        variant="outlined"
      />
    </div>,
  },
  {
    type: 'text',
    name: 'timezone',
    label: 'ACC Timezone',
    renderValue: (item) => item?.ref_id?.sin_id ? `${item?.ref_id?.sin_id?.request_acc_timezone}` : '',
    disabled:true,
  },
  {
    type: 'text',
    name: 'partner_code',
    label: 'Partner Code',
    renderValue: (item) =>  item?.ref_id?.partner_code || '',
    disabled:true,
  },
  {
    type: 'text',
    name: 'acc_name',
    label: 'ACC Name',
    renderValue: (item) => item.ref_id?.team_id && item.ref_id?.rac_id ? `Drebest ${item.ref_id?.team_id?.team_search} ${item.name}` : '',
    disabled:true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    renderValue: (item) => item.status ? item.status?.id ? item.status : accStatus.find(e => e.id == item.status) || '' : '',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = accStatus.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    }
  },
  {
    type: 'number',
    name: 'keep_deposit',
    label: 'Keep Deposit',
    renderValue: (item) => item.keep_deposit,
  },
  {
    type: 'text',
    name: 'end_spent_proof',
    label: 'End spend proof',
    renderValue: (item) => item.end_spent_proof
  },
  {
    type: 'number',
    name: 'end_spent_number',
    label: 'End Spend Number',
    renderValue:(item) => item?.end_spent_number,
  },
  {
    type: 'number',
    name: 'delta_spending_limit',
    label: 'Delta Spending Limit',
    disabled: true
  },
  {
    type: 'number',
    name: 'spent',
    label: 'Spent',
    renderValue:(item) => item?.delta_spent,
    disabled:true,
  },
  {
    type: 'number',
    name: 'delta_remaining',
    label: 'Delta Remaining',
    renderValue:(item) => item?.delta_remaining,
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'revoke_done',
    label: 'Thu hồi Done',
    checked: (item) => item.revoke_done,
  },
]
