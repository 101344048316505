import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';

class TeamList extends Component {

  render () {
    const { navigate, onDetail } = this.props;
    return (
      <ResourceList 
        route="/admin/v1/teams"
        titlePage="Drebest | Teams"
        title="Teams"
        fields={listFields}
        valueKey="team_id"
        onClickRow={(item) => {
          onDetail(item)
        }}
      />
    )
  }

}

export default TeamList;