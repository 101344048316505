import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';

const fields = [
  {
    type: 'text',
    name: 'name',
    label: 'Package name',
  },
  {
    type: 'number',
    name: 'total_topup_30days',
    label: 'Total topup',
  },
  {
    type: 'number',
    name: 'FS2',
    label: 'FS2 (%)',
  },
  {
    type: 'number',
    name: 'FSN',
    label: 'FSN (%)',
  },
  {
    type: 'number',
    name: 'FV2',
    label: 'FV2 (%)',
  },
  {
    type: 'number',
    name: 'FVN',
    label: 'FVN (%)',
  },
  {
    type: 'number',
    name: 'GSK',
    label: 'GSK (%)',
  },
  {
    type: 'number',
    name: 'GSC',
    label: 'GSC (%)',
  },
  {
    type: 'number',
    name: 'GVK',
    label: 'GVK (%)',
  },
  {
    type: 'number',
    name: 'GVC',
    label: 'GVC (%)',
  },
]

const RateExForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    request.put(`/api/v1/rate_ex/${formState.values.package_id}`, formState.changes).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={fields}
      route="app1/rate_ex"
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/rate_ex/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      title="Package Detail"
      actionBack={() => props.navigate('/app1/rate_ex')}
      {...props}
    />
  )
}

export default withRouter(RateExForm);