import axios from 'axios';
import { store, authHeaders } from '../_helpers_';
import { userActions } from '../_actions_';

export const request = {
  post,
  get,
  put,
  delete: _delete
};

function post(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_API_URL + url,
    data,
    headers: authHeaders()
  }).then((res) => res).catch((err) => {
    try {
      if (err.response != null && err.response.status === 401) {
        store.dispatch(userActions.logout());
      }
    } catch (error) {}
    throw err;
  });
}

function get(url, queryObject) {
  const searchParams = '?' + Object.keys(queryObject || {}).map((key) => (key + '=' + queryObject[key])).join('&');
  return axios({
    method: 'GET',
    url: process.env.REACT_APP_API_URL + url + searchParams,
    headers: authHeaders()
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}

      throw err;
    });
}

function put(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_API_URL + url,
    headers: authHeaders(),
    data
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout())
        }
      } catch (error) {}

      throw err;
    });
}

function _delete(url, data) {
  // const data = Object.keys(queryObject).map((key) => {
  //   return encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key]);
  // }).join('&');
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_URL + url,
    headers: authHeaders(),
    data
  })
    .then((res) => res)
    .catch((err) => {
      try {
        if (err.response != null && err.response.status === 401) {
          store.dispatch(userActions.logout());
        }
      } catch (error) {}
      throw err;
    });
}
