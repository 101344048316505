// routes
import React from 'react';
import { connect } from 'react-redux';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { history } from './_helpers_';
import { alertActions } from './_actions_';
import Router from './routes';
import sidebarConfig from './layouts/dashboard/SidebarConfig';
// ----------------------------------------------------------------------

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
    const current_app = localStorage.getItem('app')
    if (!current_app) {
      localStorage.setItem('app', 'app1')
    }
  }

  render() {
    return (
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router user={this.props.user} />
      </ThemeConfig>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    alert: state.alert,
    lang: state.locale.lang,
    loadingBar: state.loadingBar,
    user
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
