import React, { Component } from 'react'
import { listAllFields } from './fields';
import { ResourceList } from '../../../components';

class RequestAll9b extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="Drebest | 9.2 Request All"
        title="9.2 Request All"
        fields={listAllFields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/request_support/${item.request_id}`)}
      />
    )
  }

}

export default RequestAll9b;