import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, toastr } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields';

const RaiAvailForm = (props) => {

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/newids/${formState.values.public_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  const [mode, setMode] = useState('view')
  return (
    <ResourceForm 
      mode={mode}
      fields={formFields}
      route="app1/rai_avail"
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/account/${id}`,{
            type_acc: 'RAI',
            arr_status: JSON.stringify(['Active']),
            avai: 1
          }).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="RAI avail Detail"
      actionBack={() => props.navigate('/app1/rai_avail')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default withRouter(RaiAvailForm);