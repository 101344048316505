import { useState, useRef } from 'react';

import {
  Menu,
  Button,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  List,
} from '@mui/material';

const ButtonDropdown = (props) => {
  const { children, actions } = props;
  const [onMenuOpen, menuOpen] = useState(false);
  const moreRef = useRef(null);

  const openMenu = () => {
    menuOpen(true);
  };

  const closeMenu = () => {
    menuOpen(false);
  };

  return (
    <>
      <Button
        onClick={openMenu}
        ref={moreRef}
        sx={{ p: 1 }}
        {...props}
      >
        {children}
      </Button>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List component="nav">
          {actions?.map((ac, index) => (
            <ListItemButton 
              key={index}
              disabled={ac.disabled}
              onClick={() => {
                ac.onClick(props.item)
                closeMenu()
              }}
            >
              {ac.icon &&
                <ListItemIcon>
                  {ac.icon}
                </ListItemIcon>
              }
              <ListItemText primary={ac.title} />
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}

export default ButtonDropdown;
