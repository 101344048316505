import React from 'react'
import { Checkbox } from '@mui/material'
import NumberFormat from "react-number-format"
import moment from 'moment'
import { IconBrandMessenger } from '@tabler/icons';
import { CustomNumberFormat } from 'src/components';
import { request } from '../../../_services_'
import { acc_status } from '../AccountPool/constants';

export const fields = [
  // {
  //   name: 'create_date',
  //   label: 'Time Created Acc',
  //   renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss'):'',
  //   sort: true,
  //   width: '11rem',
  // },
  {
    name: 'acc_code',
    label: 'Acc Code',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'acc_name',
    label: 'Acc Name',
    renderValue: (item) => item.acc_pool_id?.acc_name || '',
    sort: true,
    filter: true,
    width: '12rem',
  },
  {
    name: 'team_seller_usename',
    label: 'Team_Seller Usename',
    sort: true,
    filter: true,
    width: '8rem',
  },
  {
    name: 'time_zone',
    label: 'Timezone',
    renderValue: (item) => item.acc_pool_id?.time_zone || '',
    sort: true,
    filter: true,
    width: '5rem',
  },
  // {
  //   name: 'acc_id',
  //   label: 'Acc ID',
  //   renderValue: (item) => item.acc_pool_id?.acc_id || '',
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  {
    name: 'status',
    label: 'Status',
    renderValue: (item) => item.status ? acc_status.find(e => e.id == item.status)?.name : '',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'using',
    label: 'Using',
    renderValue: (item) => <Checkbox checked={item.using === 1} color="primary" disabled disableRipple />,
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'available',
    label: 'Available',
    renderValue: (item) => <Checkbox checked={item.available === 1} color="primary" disabled disableRipple />,
    sort: true,
    filter: true,
    width: '4rem',
  },
  // {
  //   name: 'keep_deposit',
  //   label: 'Keep Deposit',
  //   renderValue: (item) => item.keep_deposit?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'start_spent_proof',
  //   label: 'Start Spent Proof',
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  {
    name: 'start_spent_number',
    label: 'Start Spent Number',
    renderValue: (item) => item.start_spent_number?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort: true,
    filter: true,
    width: '8rem',
  },
  // {
  //   name: 'end_spent_proof',
  //   label: 'End Spent Proof',
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  {
    name: 'end_spent_number',
    label: 'End Spent Number',
    renderValue: (item) => item.end_spent_number?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort: true,
    filter: true,
    width: '8rem',
  },
  // {
  //   name: 'delta_spending_limit',
  //   label: 'Delta Spending Limit',
  //   renderValue: (item) => item.delta_spending_limit?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'delta_spent',
  //   label: 'Delta Spent',
  //   renderValue: (item) => item.delta_spent?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'delta_remaining',
  //   label: 'Delta Remaining',
  //   renderValue: (item) => item.delta_remaining?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'revoke_confirm',
  //   label: 'Chốt thu hồi',
  //   renderValue: (item) => <Checkbox checked={item.revoke_confirm === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'delete_camp',
  //   label: 'Delete Camp',
  //   renderValue: (item) => <Checkbox checked={item.delete_camp === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'delete_rules',
  //   label: 'Delete Rules',
  //   renderValue: (item) => <Checkbox checked={item.delete_rules === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'unbind_bm',
  //   label: 'Unbind BM',
  //   renderValue: (item) => <Checkbox checked={item.unbind_bm === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'transfer_remaining',
  //   label: 'Transfer Remaining',
  //   renderValue: (item) => <Checkbox checked={item.transfer_remaining === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
  // {
  //   name: 'revoke_done',
  //   label: 'Thu hồi Done',
  //   renderValue: (item) => <Checkbox checked={item.revoke_done === 1} color="primary" disabled disableRipple />,
  //   sort: true,
  //   filter: true,
  //   width: '6rem',
  // },
]

export const formfields = [
  // {
  //   type: 'text',
  //   disabled: true,
  //   sort: true,
  //   width: '11rem',
    
  //   name: 'create_date',
  //   label: 'Time Created Acc',
  //   renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    
  // },
  {
    type: 'text',
    disabled: true,
    name: 'acc_code',
    label: 'Acc Code',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'select',
    name: 'acc_pool_id',
    label: 'Acc Name',
    disabled: true,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/account_pool', { search: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    renderValue: (item) => item.acc_pool_id ? {id: item.acc_pool_id?.id, name: item.acc_pool_id?.name} : '',
    valueKey: 'id',
    labelKey: 'name',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'select',
    name: 'user_id',
    label: 'Team_Seller Usename',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/users', { search: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    renderValue: (item) => item.user_id ? {id: item.user_id.id, name: item.user_id.name} : '',
    valueKey: 'id',
    labelKey: 'name',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'time_zone',
    label: 'Timezone',
    renderValue: (item) => item.acc_pool_id?.time_zone || '',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.acc_pool_id?.acc_id || '',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'status',
    label: 'Status',
    renderValue: (item) => item.status ? acc_status.find(e => e.id == item.status)?.name : '',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    disabled: true,
    name: 'using',
    label: 'Using',
    checked: (item) => item.using === 1 || item.using === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    disabled: true,
    name: 'available',
    label: 'Available',
    checked: (item) => item.available === 1 || item.available === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    name: 'keep_deposit',
    label: 'Keep Deposit',
    // renderValue: (item) => <CustomNumberFormat value={item.keep_deposit} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'start_spent_proof',
    label: 'Start Spent Proof',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    name: 'start_spent_number',
    label: 'Start Spent Number',
    // renderValue: (item) => <CustomNumberFormat value={item.start_spent_number} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'end_spent_proof',
    label: 'End Spent Proof',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    name: 'end_spent_number',
    label: 'End Spent Number',
    // renderValue: (item) => <CustomNumberFormat value={item.end_spent_number} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    disabled: true,
    name: 'delta_spending_limit',
    label: 'Delta Spending Limit',
    // renderValue: (item) => <CustomNumberFormat value={item.delta_spending_limit} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    disabled: true,
    name: 'delta_spent',
    label: 'Delta Spent',
    // renderValue: (item) => <CustomNumberFormat value={item.delta_spent} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    disabled: true,
    name: 'delta_remaining',
    label: 'Delta Remaining',
    // renderValue: (item) => <CustomNumberFormat value={item.delta_remaining} displayType="text" thousandSeparator={','} />,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'revoke_confirm',
    label: 'Chốt thu hồi',
    checked: (item) => item.revoke_confirm === 1 || item.revoke_confirm === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'delete_camp',
    label: 'Delete Camp',
    checked: (item) => item.delete_camp === 1 || item.delete_camp === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'delete_rules',
    label: 'Delete Rules',
    checked: (item) => item.delete_rules === 1 || item.delete_rules === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'unbind_bm',
    label: 'Unbind BM',
    checked: (item) => item.unbind_bm === 1 || item.unbind_bm === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'transfer_remaining',
    label: 'Transfer Remaining',
    checked: (item) => item.transfer_remaining === 1 || item.transfer_remaining === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'checkbox',
    name: 'revoke_done',
    label: 'Thu hồi Done',
    checked: (item) => item.revoke_done === 1 || item.revoke_done === true,
    sort: true,
    filter: true,
    width: '6rem',
  },
]