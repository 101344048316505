import { request } from '../../../_services_';
import { Avatar, Box, Button, Checkbox, CircularProgress } from '@mui/material';
import { capitalizeStr } from '../../../utils'
import { Uploader } from 'src/components';
import Dropzone from 'react-dropzone';
import { IconArrowUp } from '@tabler/icons';

export const listCmnFields = [
  {
    name: 'title',
    label: 'CMN Number',
    sort: true,
    filter:true,
  },
  {
    name: 'china_mobile_number',
    label: 'China Mobile Number', sort: true,
    filter:true,
  },
  {
    name: 'used',
    label: 'Used', sort: true,
    renderValue: (item) => <Checkbox checked={item.used == 1 ? true : false} disabled color="primary" />,
    filter:true,
  },
]

export const listCmnNumberFormFields = [
  {
    type: 'text',
    name: 'china_mobile_number',
    label: 'China Mobile Number',
    filter:true,
  },
  {
    type: 'checkbox',
    name: 'used',
    label: 'Used',
    checked: (item) => item.used == 1 || item.used == true,
  }
]

export const listBrFields = [
  {
    name: 'br_number',
    label: 'BR Number', 
    renderValue: (item) => `BR ${item.id}`,
    sort: true,
    filter:true,
  },
  {
    name: 'batch_br_name',
    label: 'Batch - BR Name',
    renderValue: (item) => `${item.batch} - ${item.name}`,
    sort: true,
    filter:true,
  },
  {
    name: 'batch',
    label: 'Batch',
    renderValue: (item) => item.batch,
    sort: true,
    filter:true,
  },
  {
    name: 'name',
    label: 'BR Name', 
    sort: true,
    filter:true,
  },
  {
    name: 'license_no',
    label: 'BR Unified Social Credit Number', 
    sort: true,
    filter:true,
  },
  {
    name: 'chinese_name',
    label: 'BR Business Name', 
    sort: true,
    filter:true,
  },
  {
    name: 'english_name',
    label: 'BR Business Name in English', 
    sort: true,
    filter:true,
  },
  {
    name: 'address',
    label: 'BR Registered Business Address', 
    sort: true,
    filter:true,
  },
  {
    name: 'address_eng',
    label: 'BR Address (Eng)', 
    sort: true,
    filter:true,
  },
  {
    name: 'postal_code',
    label: 'BR Registered Postal Code', 
    sort: true,
    filter:true,
  },
  {
    name: 'image_link',
    label: 'BR Image Link',
    sort: true,
    filter:true,
  },
  {
    name: 'city_manual',
    label: 'BR City (manual)', 
    sort: true,
    filter:true,
  },
  {
    name: 'city',
    label: 'BR English City Name of Your Registered Business Address', 
    renderValue: (item) =>  item.english_name?.split(' ')[0] || null,
    sort: true,
    filter:true,
  },
  {
    name: 'img_thumb',
    label: 'BR Image Thumb', 
    renderValue: (item) => <div style={{ overflow: 'hidden', width: '100px', height: '100px' }}>
        <img src={item.image_link} alt='img_thumb' style={{ width: 100, height: 100 }} />
      </div>,
    sort: true,
    filter:true,
  },
]

export const listBrNumberFormFields = [
  {
    type: 'text',
    name: 'br_number',
    label: 'BR Number',
    renderValue: (item) => `BR ${item.id}`, 
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'batch_br_name',
    label: 'Batch - BR Name',
    renderValue: (item) => `${item.batch} - ${item.name}`, 
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'batch',
    label: 'Batch',
    renderValue: (item) => item.batch,
    filter:true,
  },
  {
    type: 'text',
    name: 'name',
    label: 'BR Name',
    filter:true,
  },
  {
    type: 'text',
    name: 'license_no',
    label: 'BR Unified Social Credit Number',
    filter:true,
  },
  {
    type: 'text',
    name: 'chinese_name',
    label: 'BR Business Name', 
    filter:true,
  },
  {
    type: 'text',
    name: 'english_name',
    label: 'BR Business Name in English', 
    filter:true,
  },
  {
    type: 'text',
    name: 'address',
    label: 'BR Registered Business Address', 
    filter:true,
  },
  {
    type: 'text',
    name: 'address_eng',
    label: 'BR Address (Eng)', 
    filter:true,
  },
  {
    type: 'text',
    name: 'postal_code',
    label: 'BR Registered Postal Code', 
    filter:true,
  },
  {
    type: 'text',
    name: 'image_link',
    label: 'BR Image Link', 
    renderValue: (item) => item.image_link,
    filter:true,
  },
  {
    type: 'custom',
    name: 'img_thumb',
    label: 'BR Image Thumb', 
    renderValue: (item) => 
    <a href={item.image_link} target='_blank'>
      <div style={{ overflow: 'hidden', width: '100px', height: '100px' }}>
        <img src={item.image_link} alt='img_thumb' style={{ width: 100, height: 100 }} />
      </div>
    </a>,
    filter:true,
  },
  {
    type: 'text',
    name: 'city_manual',
    label: 'BR City (manual)', 
    filter:true,
  },
  {
    type: 'text',
    name: 'city',
    label: 'BR English City Name of Your Registered Business Address', 
    renderValue: (item) => item.english_name?.split(' ')[0] || null,
    disabled: true,
    filter:true,
  },
]

export const listEmailFields = [
  {
    name: 'title',
    label: 'Email Number',
    sort: true,
    filter:true,
  },
  {
    name: 'email',
    label: 'Gmail', 
    sort: true,
    filter:true,
  },
]

export const listEmailNumberFormFields = [
  { 
    type: 'text',
    name: 'title',
    label: 'Email Number', 
    disabled: true,
    filter:true,
  },
  {
    type: 'text',
    name: 'email',
    label: 'Gmail',
    filter:true,
  },
]