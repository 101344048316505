import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'

const AllUserForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'paygate') {
        query[key] = formState.changes[key]
      } else if (key == 'groups') {
        query[key] = formState.changes[key].map(e => e.code)
      } else if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/user/${formState.values.public_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={formFields}
      route={'app1/need_fud'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/user/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Need FUD Detail"
      actionBack={() => props.navigate('/app1/need_fuds')}
      // actions={[
      //   {
      //     text: 'Edit',
      //     variant: 'contained',
      //     color: 'primary',
      //     action: () => setMode('edit'),
      //     visible: mode == 'view'
      //   },
      //   {
      //     text: 'Save',
      //     variant: 'contained',
      //     color: 'primary',
      //     action: (ctx, formState) => handleSave(ctx, formState),
      //     visible: mode == 'edit'
      //   },
      //   {
      //     text: 'Cancel',
      //     variant: 'outlined',
      //     color: 'error',
      //     action: (ctx, formState) => handleCancel(ctx),
      //     visible: mode == 'edit'
      //   },
      // ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(AllUserForm))