import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm, ModalForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';

const fields = [
  {
    type: 'text',
    name: 'code',
    label: 'Acc code',
    disabled: true
  },
  {
    type: 'text',
    name: 'RAC_number',
    label: 'RAC group',
    disabled: true
  },
  {
    type: 'select',
    name: 'ref_id',
    label: 'REF number',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/ref_reviews').then(res => {
          if (res.data.success) {
            resolve(res.data.data.items?.map(e => ({id: e.id, name: `REF ${e.id}`})))
          }
        })
      })
    },
    renderValue: (item) => item.ref_id ? {id: item.ref_id.id, name: `REF ${item.ref_id.id}`} : null
  },
  {
    type: 'text',
    disabled: true,
    name: 'username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.team_id ? `${item.team_id?.team_search||''} ${item.team_id.owner_name||''}` : ''
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'Acc ID',
  },
  {
    type: 'text',
    disabled: true,
    name: 'br_business',
    label: 'BR Business Name',
    renderValue: (item) => item.ref_id?.rac_id?.br_business
  },
  {
    type: 'text',
    disabled: true,
    name: 'timezone',
    label: 'Acc Timezone',
  },
  {
    type: 'text',
    disabled: true,
    name: 'sin_id',
    label: 'SIN Number',
    renderValue: (item) => item.ref_id?.sin_id ? `SIN ${item.ref_id.sin_id.sin_id}` : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'partner_code',
    label: 'Partner code',
    renderValue: (item) => item.ref_id?.sin_id?.partner_code
  },
  {
    type: 'text',
    disabled: true,
    name: 'team_mess_group',
    label: 'Team Mess Group',
    renderValue: (item) => item.ref_id?.team_id?.mess_group_link
  },
  {
    type: 'text',
    disabled: true,
    name: 'name',
    label: 'Acc Name'
  },
  {
    type: 'text',
    disabled: true,
    name: 'create_date',
    label: 'Time created Acc',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'bm_id',
    label: 'BM ID',
    renderValue: (item) => item.ref_id?.sin_id?.bm_id
  },
  {
    type: 'text',
    disabled: true,
    name: 'bm2_id',
    label: 'BM ID Link 2',
    renderValue: (item) => item.ref_id?.sin_id?.bm2_id
  },
  {
    type: 'checkbox',
    name: 'share_acc',
    label: 'Share acc to BM',
    checked: (item) => item.share_acc
  },
  {
    type: 'checkbox',
    name: 'permanently_disabled',
    label: 'Permanently Disabled',
    checked: (item) => item.permanently_disabled
  },
  {
    type: 'text',
    name: 'disable_type',
    label: 'Disabled type',
  },
  {
    type: 'text',
    name: 'disable_reason',
    label: 'Disabled reason',
  },
  {
    type: 'number',
    name: 'keep_deposit',
    label: 'Keep deposit',
  },
  {
    type: 'text',
    name: 'spent_text',
    label: 'Spent',
    placeholder: 'http://'
  },
  {
    type: 'number',
    name: 'spending_limit',
    label: 'Acc Spending limit',
    disabled: true,
  },
  {
    type: 'text',
    name: 'status',
    label: 'Status',
    disabled: true,
  },
  {
    type: 'datetime',
    name: 'disable_date',
    label: 'Disabled date',
    renderValue: (item) => item.disable_date ? new Date(item.disable_date) : null
  },
  {
    type: 'number',
    disabled: true,
    name: 'spending_limit_1',
    label: 'Spending limit 1 (Before)',
  },
  {
    type: 'number',
    disabled: true,
    name: 'spent_1',
    label: 'Spent 1 (Before)',
  },
  {
    type: 'number',
    disabled: true,
    name: 'remaining_1',
    label: 'Remaining 1 (Before)',
  },
  {
    type: 'number',
    name: 'image_payment_setting',
    label: 'Image payment setting',
  },
  {
    type: 'number',
    disabled: true,
    name: 'spending_limit_2',
    label: 'Spending limit 2 (After)',
  },
  {
    type: 'number',
    disabled: true,
    name: 'spent_2',
    label: 'Spent 2 (After)',
  },
  {
    type: 'number',
    disabled: true,
    name: 'remaining_2',
    label: 'Remaining 2 (After)',
  },
  {
    type: 'number',
    name: 'spent_2_manual',
    label: 'Spent 2 manual',
  },
  {
    type: 'number',
    name: 'remaining',
    label: 'Remaining',
  },
  {
    name: 'cannot_access',
    label: "Can't access",
    type: 'checkbox',
    checked: (item) => item.cannot_access
  },
  {
    name: 'stop',
    label: "Stop",
    type: 'checkbox',
    checked: (item) => item.stop
  },
  {
    name: 'time_stop',
    label: 'Time stop',
    type: 'datetime',
    renderValue: (item) => item.time_stop ? new Date(item.time_stop) : null
  },
  {
    name: 'note',
    label: 'Note',
    type: 'text',
    multiline: true
  },
  {
    name: 'solution',
    label: 'Giải pháp',
    type: 'text',
    multiline: true
  },
  {
    name: 'revoke',
    label: 'Thu hồi',
    type: 'checkbox',
    checked: (item) => item.revoke
  },
  {
    name: 'spent_check_fb',
    label: 'Cần check số spent trên FB',
    type: 'number',
  }
]

const RacWarehouseForm = (props) => {
  const [mode, setMode] = useState('view')
  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'date' || formState.changes[key] instanceof Date) {
        query[key] = moment(formState.changes[key]).utc().format('YYYY-MM-DD HH:mm:ss')
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/newids/${formState.values.public_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  const qualifiedWebpage = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: 'Web & page qualified?',
      data: {},
      customView: () => (
        <div className='p-3'>
          Are you sure?
        </div>
      ),
      action: {
        onAction: () => {
          const query = {
            webpage_qualified_content: 1
          }
          return new Promise((resolve) => {
            request.put(`/admin/v1/ref_review/${formState.values.id}`, query).then(res => {
              if (res.data.success) {
                const form = Object.assign({}, formState)
                form.changes = {}
                form.values = res.data.data
                ctx.setState({ formState: form })
                toastr.success("Update successful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error("There was an error updating data")
              resolve(false)
            })
          })
        }
      }
    })
    
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={fields}
      route={'app3/rac_warehouse'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/newids/${id}`, { type_acc: 'RAC', list:'warehouse'}).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="RAC Warehouse detail"
      actionBack={() => props.navigate('/app3/rac_warehouses')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(RacWarehouseForm))