import React, { Component } from 'react'
import { listCmnFields, listCmnNumberFormFields } from './fields';
import { ModalForm, ResourceList, DrawerForm } from '../../../components'
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_'
import {
  TextField, Box, FormControlLabel, Checkbox,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';

class CmnNumberList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newCmnNumber = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              China Mobile Number <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              type='number'
              margin='dense'
              name="china_mobile_number"
              value={submitData.values.china_mobile_number}
              onChange={handleChange}
              placeholder="China Mobile Number ..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              error={submitData?.errors?.china_mobile_number?true:false}
              helperText={submitData.errors?.china_mobile_number ? submitData.errors.china_mobile_number[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox checked={submitData.values.used} onChange={handleChange} name="used" />
              }
              label={
                <Box fontSize={15} fontWeight='bold'>
                  Used
                </Box>
              }
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          china_mobile_number: {presence: {message: '^Required'}},
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            const data = Object.assign({}, submitData.values)
            if(data['used']){
              data['used'] = submitData.values['used'] ? 1 : 0
            } else {
              data['used'] = 0
            }
            request.post('/admin/v1/cmn_numbers', data).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = Object.assign({}, formState.changes)
    if(query['used']){
      query['used'] = formState.changes['used'] ? 1 : 0
    } else {
      query['used'] = 0
    }
    request.put(`/admin/v1/cmn_number/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/cmn_numbers"
          titlePage="Drebest | CMN Number"
          title="CMN Number"
          fields={listCmnFields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newCmnNumber(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={listCmnNumberFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/cmn_number/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="CMN Number Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
      
    )
  }

}

export default CmnNumberList;