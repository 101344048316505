import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'
import { ModalForm } from '../../../components';
import { Box } from '@mui/material';

const CommissionForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/commissions/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const markAsPaid = (ctx, formState) => {
    const query = {paid_com_ids: [formState.values.id]}
    request.put('/admin/v1/commissions', query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.values.is_payment = true
        ctx.setState({ formState: form })
        toastr.success("Mark as paid successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating status")
    })
  }

  const handlePaidCommission = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Confirm action',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Mark this commission as paid?
            </Box>
          </div>
        </div>
      ),
      action: {
        schema: {
        },
        titleAction: 'YES',
        onAction: (submitData) => {
          markAsPaid(ctx, formState)
          return new Promise(resolve => {
            resolve(true)
          })
        }
      }
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={formFields}
      route={'app1/commission'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/admin/v1/commissions/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Commission Detail"
      actionBack={() => props.navigate('/app1/commission')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Paid',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handlePaidCommission(ctx, formState),
          visible: mode == 'view',
          invisible: (formState) =>  formState.values.is_payment
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(CommissionForm))