import NumberFormat from 'react-number-format';
import React from 'react';

const CustomNumberFormat = React.forwardRef(function CustomNumberFormat(props, ref) {
  const { onChange, allowNegative, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={allowNegative}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

export default CustomNumberFormat;