import moment from 'moment';
import { Box } from '@mui/material'
import { request } from '../../../_services_'

const PAYMENT_METHODS = [
  {value: 'paypal', label: 'Paypal'},
  {value: 'po', label: 'PO'},
  {value: 'bank_vnd', label: 'Bank VND'},
  {value: 'transfer_from_phoenix', label: 'Transfer from Phoenix'},
  {value: 'bank_wire_usd', label: 'Bank wire USD'},
  {value: 'transfer', label: 'Transfer'},
]

export const fields = [
  {
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.id}`,
    width: '6rem',
  },
  {
    name: 'payment_date',
    label: 'Transaction Time',
    renderValue: (item) => item.payment_date ? moment(item.payment_date).format('DD/MM/YYYY HH:mm:ss') : '',
    width: '10rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_id ? `Team_${item.team_id?.team_id}` : '',
    sort: true,
    width: '6rem'
  },
  {
    name: 'payment_method',
    label: 'Payment Method',
    renderValue: (item) => PAYMENT_METHODS.find(e => e.value == item.payment_method)?.label,
    width: '10rem'
  },
  {
    name: 'sent_to',
    label: 'Send to',
    renderValue: (item) => <div dangerouslySetInnerHTML={{__html: item.sent_to}}/>,
    width: '12rem'
  },
  {
    name: 'apply_rate',
    label: 'Apply Rate',
    width: '8rem',
    renderValue: (item) => item.apply_rate 
  },

  {
    name: 'amount_vnd',
    label: 'Total Money VND',
    width: '10rem',
    renderValue: (item) => item.payment_method == 'bank_vnd' ? item.amount_vnd?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 }) : ''
  },
  {
    name: 'amount',
    label: 'Amount USD',
    width: '8rem',
    renderValue: (item) => item.amount?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 })
  },
]

export const formfields = [
  {
    type: 'text',
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.id}`,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'payment_date',
    label: 'Transaction Time',
    renderValue: (item) => item.payment_date ? moment(item.payment_date).format('DD/MM/YYYY HH:mm:ss') : '',
    width: '10rem'
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_search,
    sort: true,
    width: '6rem'
  },
  {
    type: 'text',
    name: 'payment_method',
    label: 'Payment Method',
    renderValue: (item) => PAYMENT_METHODS.find(e => e.value == item.payment_method)?.label,
    width: '10rem'
  },
  {
    type: 'custom',
    name: 'sent_to',
    label: 'Send to',
    renderValue: (item) => 
      <div>
        <Box fontWeight="bold" fontSize={15}>
          Sent to
        </Box>
        <div className='mt-2' style={{ padding: '12px 14px', background: '#ECECEC', color: '#000', border: '1px solid rgba(145, 158, 171, 0.24)', borderRadius: 8 }}>
          <div dangerouslySetInnerHTML={{ __html: item.sent_to }} />
        </div>
      </div>
    ,
    width: '12rem'
  },
  {
    type: 'number',
    name: 'apply_rate',
    label: 'Apply Rate',
    width: '8rem',
    renderValue: (item) => item.apply_rate 
  },

  {
    type: 'number',
    name: 'amount_vnd',
    label: 'Total Money VND',
    width: '10rem',
    renderValue: (item) => {
      if (item.payment_method == 'bank_vnd') {
        return(item.amount_vnd?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 }))
      }
    }
  },
  {
    type: 'number',
    name: 'amount',
    label: 'Amount USD',
    width: '8rem',
    renderValue: (item) => 
      item.amount_vnd ? ((item.amount_vnd)/(item.apply_rate))?.toLocaleString({ style: 'currency', currency: '', maximumFractionDigits: 2 }) : ''
  },
]

export const filters = [
  {
    valueKey: 'code',
    key: 'payment_method',
    defaultAriaLabel: 'Payment Method',
    labelKey: 'title',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/payment_methods', { name: inputValue || '' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  }
]