import React, { Component } from 'react'
import { ModalForm, Uploader, ResourceList, DrawerForm, CustomNumberFormat } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import { listRacAllFields, listRacAllFormFields, accStatus } from './fields'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import AsyncSelect from 'react-select/async'

class RacAllList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (typeof formState.changes[key] == 'boolean' || formState.changes[key] instanceof Boolean) {
        query[key] = formState.changes[key] ? 1 : 0
      } else if (typeof formState.changes[key] == 'date' || formState.changes[key] instanceof Date) {
        query[key] = moment(formState.changes[key]).utc().format('YYYY-MM-DD HH:mm:ss')
      } else if(key == 'files') {
        query['end_spent_proof'] = formState.changes[key][0]?.url
      } else if (typeof formState.changes[key] == 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else if (['end_spent_number', 'keep_deposit'].includes(key)){
        query[key] = Number(parseFloat(formState.changes[key]).toFixed(2))
      } else if (['spent', 'delta_remaining'].includes(key)) {
        return
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/newids/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/newids"
          titlePage="Drebest | 7.2 RAC All"
          title="7.2 RAC All"
          fields={listRacAllFields}
          defaultFilters={{type_acc: 'RAC'}}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
        />
        <DrawerForm 
          fields={listRacAllFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/newids/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default RacAllList;
