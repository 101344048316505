import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'

const RaiBMSharingForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/signup_web/${formState.values.sin_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm
      mode={mode}
      fields={formFields}
      route={'app3/rai_bm_sharing'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/admin/v1/request/${id}`, {
            codes: JSON.stringify(['B5']),
            acc_type: 'RAI'
          }).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Request Supports Detail"
      actionBack={() => props.navigate('app3/rai_bm_sharing')}
      actions={[
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(RaiBMSharingForm))