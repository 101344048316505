import ExtraField from './ExtraField'
import { request } from '../../../_services_'
export const fields = [
  {
    name: 'code',
    label: 'Service code',
    sort:true,
    width: '5rem'
  },
  {
    name: 'title',
    label: 'Service name',
    sort: true,
    width: '20rem',
    renderValue: (item) => <span className="line-clamp-1">{item.title}</span>
  },
  {
    name: 'platform',
    label: 'Platform',
    sort: true,
    width: '12rem',
    renderValue: (item) => item.platforms ? item.platforms.map(e => e.title).join(', ') : ''
  },
  {
    name: 'price',
    label: 'Price',
    sort: true,
    width: '6rem',
    renderValue: (item) => item.price?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })
  },
  {
    name: 'commission_rate',
    label: "Commission rate",
    renderValue: (item) => `${item?.commission_rate || ''}%`,
    width: '8rem'
  }
]

export const formFields = [
  {
    type: 'text',
    name: 'code',
    label: 'Service code',
  },
  {
    type: 'text',
    name: 'title',
    label: 'Service name',
  },
  {
    type: 'multiselect',
    name: 'platforms',
    label: 'Platforms',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/team_platforms', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          }
        })
      })
    },
    valueKey: "id",
    labelKey: "title"
  },
  {
    type: 'number',
    name: 'price',
    label: 'Price',
  },
  {
    type: 'number',
    name: 'commission_rate',
    label: 'Commission Rate',
  },
  {
    type: 'checkbox',
    name: 'auto_deduct',
    label: 'Auto deduct = 0',
    checked: (item) => item.auto_deduct
  },
  {
    type: 'checkbox',
    name: 'can_be_cancelled',
    label: 'Can be cancelled',
    checked: (item) => item.can_be_cancelled
  },
  {
    type: 'custom',
    name: 'extra_field',
    label: 'Extra Field',
    renderValue: (item, ctx) => <ExtraField submitData={item} handleChange={ctx.handleChange} disabled={ctx.props.mode == 'view' ? true : false} />
  }
]

export const platforms = [
  {id: 'google', name: 'Google' },
  {id: 'facebook', name: 'Facebook' },
  {id: 'tiktok', name: 'Tiktok' },
]

export const listExtraFields = [
  {
    "key": "",
    "label": "",
    "type": "select",
    "route": "",
    "options": [],
    "valueKey": "id",
    "labelKey": "title",
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "multiselect",
    "route": "",
    "options": [],
    "valueKey": "id",
    "labelKey": "title",
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "text",
    "placeholder": "",
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "number",
    "placeholder": "",
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "checkbox",
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "radio",
    "options": [],
    "required": 1
  },
  {
    "key": "",
    "label": "",
    "type": "array",
    "keys": [],
    "required": 1
  }
]

export const tables = [
  {value: '/api/v1/accounts', label: 'Accounts'},
  {value: '/api/v1/team_users', label: 'Team Users'},
  {value: '/api/v1/banks', label: 'Banks'},
]