import TypeAcc from "./TypeAcc"
export const listFields = [
  {
    name: 'code',
    label: 'Code',
    sort: true,
  },
  {
    name: 'title',
    label: 'Platform name',
    sort: true
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    sort: true,
    renderValue: (item) => item.type_acc?.length > 0 ? item.type_acc.map(e => e.name).join(', ') : ''
  }
]

export const formFields = [
  {
    type: 'text',
    name: 'code',
    label: 'Code',
  },
  {
    type: 'text',
    name: 'title',
    label: 'Platform name',
  },
  {
    type: 'custom',
    name: 'Type Acc',
    label: 'Type Acc',
    renderValue: (item, ctx) => <TypeAcc disabled={ctx.props.mode == 'view'} item={item} ctx={ctx} />
  }
]