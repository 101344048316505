import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Checkbox } from '@mui/material';
import { request } from '../../../_services_';
import { ImagePreview } from '../../../components';
import { IconBrandMessenger } from '@tabler/icons';
export const listFields = [
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `${item.team_id?.team_search || ''}`,
    width: '8rem',
    sort: true,
    sortName: 'teams.team_search',
    filter: true,
    filterName:'teams.team_search'
  },
  {
    name: 'gmc_code',
    label: 'GMC code',
    width: '6rem',
    sort: true,
    filter: true,
  },
  {
    name: 'name',
    label: 'Topup ID',
    width: '8rem',
    renderValue: (item) => `${item.name}`,
    sort: true,
    filter: true,
  },
  {
    name: 'send_date',
    label: 'Send date',
    width: '8rem',
    renderValue: (item) => item.created_at ? moment(item.create_date).format('DD/MM/YYYY') : '',
    sort: true,
    
  },
  {
    name: 'team_name',
    label: 'Team ID & Name',
    renderValue: (item) => item.team_id ? `${item.team_id.team_search} ${item.team_id?.owner_name|| ''}` : '',
    sort: true,
    sortName: 'teams.team_search',
    filter: true,
    filterName:'teams.team_search|teams.owner_name*1'
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    renderValue: (item) => item.team_id ? item.team_id?.type_acc : '',
    width: '6rem',
    sort: true,
    sortName: 'teams.type_acc',
    filter: true,
    filterName:'teams.type_acc'
  },
  {
    name: 'payment_method',
    label: 'Payment Method',
    renderValue: (item) => item.payment_method_id?.name,
    sort: true,
    sortName: 'payment_methods.name',
    filter: true,
    filterName:'payment_methods.name'
  },
  {
    name: 'email_from',
    label: 'Send from email',
    sort: true,
    filter: true,

  },
  {
    name: 'email_to',
    label: 'Send to email',
    renderValue: (item) => <div className='line-clamp-3' dangerouslySetInnerHTML={{ __html: item.email_to }} />,
    sort: true,
    filter: true,

  },
  {
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => item.fud_id ? `${item.fud_id.name}` : '',
    width: '5rem',
    sort: true,
    sortName: 'fud_wallet.name',
    filter: true,
    filterName:'fud_wallet.name'

  },
  {
    name: 'money_to_wallet',
    label: 'A - Money to Wallet',
    renderValue: (item) => <NumberFormat value={item.money_to_wallet || 0} thousandSeparator="," displayType="text" />,
    sort: true,
  },
  {
    name: 'actual_fee',
    label: 'B - Actual Fee',
    renderValue: (item) => <NumberFormat value={item.actual_fee || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort: true,
  },
  {
    name: 'creation_fee',
    label: 'B - Creation Fee',
    renderValue: (item) => <NumberFormat value={item.ad_acc_per_issuance_fee || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort: true,
  },
  {
    name: 'acc_deposit',
    label: 'D - Acc Deposit',
    renderValue: (item) => <NumberFormat value={item.acc_deposit || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort: true,
  },
  {
    name: 'paypal_fee',
    label: 'E - Paypal Fee',
    renderValue: (item) => <NumberFormat value={item.paypal_fee || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort: true,
  },
  {
    name: 'total_money',
    label: 'Total money USD',
    renderValue: (item) => <NumberFormat value={item.total_money || 0} thousandSeparator="," displayType="text" />,
    width: '10rem',
    sort: true,
  },
  {
    name: 'rate_value',
    label: 'Apply rate',
    renderValue: (item) => <NumberFormat value={item.rate_value || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort: true,
  },
  {
    name: 'total_money_vnd',
    label: 'Total money VND',
    renderValue: (item) => <NumberFormat value={(item.rate_value || 0) * (item.total_money || 0)} thousandSeparator="," displayType='text' />,
  },
  {
    name: 'total_money_vnd_actual',
    label: 'Total money VND actual',
    renderValue: (item) => <NumberFormat value={(item.rate_value || 0) * (item.total_money_actual || 0)} thousandSeparator="," displayType='text' />,
  },
  {
    name: 'total_money_actual',
    label: 'Total money',
    renderValue: (item) => <NumberFormat value={item.total_money_actual || 0} thousandSeparator="," displayType='text' />,
    sort: true,
  },
  {
    name: 'fee_topup',
    label: '% Fee of topup',
    sort: true,
  },
  {
    name: 'voucher_code',
    label: 'Voucher Code',
    width: '8rem',
    sort: true,
    filter: true,
  },
  {
    name: 'drebest_received',
    label: 'Drebest received',
    width: '10rem',
    renderValue: (item) => <Checkbox checked={item.drebest_received} color="primary" disabled={true} />,
    sort: true,
  },
  {
    name: 'image_thumb',
    label: 'Image thumb',
    renderValue: (item) => item.screenshot_transaction_type == 'direct_link' ?
      <ImagePreview url={item.screenshot_transaction_link} />
      :
      (item.extra && item.extra.length > 0 ?
        <ImagePreview url={`${process.env.REACT_APP_API_URL}/${item.extra[0].url}`} />
        : ''),
    width: '10rem',
  },
  {
    name: 'payone_transection_id',
    label: 'Payoneer transection ID',
    width: '10rem',
    sort: true,
    filter: true,
  },
  {
    name: 'paypal_transection_id',
    label: 'Paypal transection ID',
    width: '10rem',
    sort: true,
    filter: true,
  },
  {
    name: 'bank_transection_id',
    label: 'Bank transection ID',
    width: '10rem',
    sort: true,
    filter: true,
  },
  {
    name: 'tranfer_content',
    label: 'Transfer contents',
    sort: true,
    filter: true,
  },
  {
    name: 'note',
    label: 'Long note',
    sort: true,
    filter: true,
    filterName:'note@ilike'
  },
  {
    name: 'package_money',
    label: 'Package Money',
    renderValue: (item) => item.fud_id ? <NumberFormat value={item.fud_id?.package_money || 0} thousandSeparator displayType='text' /> : '',
    sort: true,
    sortName: 'fud_wallet.package_money'
  },
  {
    name: 'lack_of_fee',
    label: 'Lack of fee',
    renderValue: (item) => item.fud_id ? <NumberFormat value={item.actual_fee || 0} thousandSeparator displayType='text' /> : '',
    sort: true,
    sortName: 'actual_fee'
  },
  {
    name: 'total_topup_now',
    label: 'A - Total topup -> Now',
    renderValue: (item) => item.fud_id ? <NumberFormat value={item.fud_id?.total_topup_to_now || 0} thousandSeparator displayType='text' /> : '',
    sort: true,
    sortName: 'fud_wallet.total_topup_to_now'
  },
  {
    name: 'remaining_topup',
    label: 'The remaining topup',
    renderValue: (item) => item.fud_id ? <NumberFormat value={item.fud_id?.remaining_topup || 0} thousandSeparator displayType='text' /> : '',
    sort: true,
    sortName: 'fud_wallet.remaining_topup'
  },
  {
    name: 'topup_1st_date',
    label: '1st topup date',
    renderValue: (item) => item.fud_id?.topup_1st_date ? moment(item.fud_id?.topup_1st_date).format('DD/MM/YYYY') : '',
    sort: true,
    sortName: 'fud_wallet.topup_1st_date'
  },
  {
    name: 'cycle_end_date',
    label: 'Cycle end date',
    renderValue: (item) => item.fud_id?.cycle_end_date ? moment(item.fud_id?.cycle_end_date).format('DD/MM/YYYY') : '',
    sort: true,
    sortName: 'fud_wallet.cycle_end_date'
  },
  {
    name: 'count_down_day',
    label: 'Countdown day',
    sort: true,
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.topup_id}`,
    disabled: true,
  },
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Team - Mess Group',
    disabled: true,
  },
  {
    type: 'text',
    name: 'team_name',
    label: 'Team ID & Name',
    renderValue: (item) => item.team_id ? `${item.team_id?.team_search} ${item.team_id?.company_id?.code || ''}` : '',
    disabled: true,
  },
  {
    type: 'select',
    name: 'payment_method_id',
    label: 'Payment Method',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/payment_methods', { name: inputValue }).then(res => {
          resolve(res.data.data.items.map(e => ({ id: e.payment_method_id, name: e.name, info: e.info })))
        })
      })
    },
  },
  {
    type: 'custom',
    name: 'email_to',
    label: 'Send to',
    disabled: true,
    renderValue: (item) =>
      <div style={{ background: '#F8F9FA', padding: '16px 25px', border: '1px solid #C2C7CD', borderRadius: 8, maxHeight: 150, overflow: 'auto' }}>
        <div dangerouslySetInnerHTML={{ __html: item.payment_method_id?.info }} />
      </div>
  },
  {
    type: 'select',
    name: 'fud_id',
    label: 'FUD ID',
    loadOptions: (inputValue, item) => {
      return new Promise(resolve => {
        request.get('/api/v1/fuds', { name: inputValue, team_id: item.team_id }).then(res => {
          resolve(res.data.data.items.map(e => ({ id: e.fud_id, name: `FUD ${e.fud_id}` })))
        })
      })
    },
  },
  {
    type: 'number',
    name: 'money_to_wallet',
    label: 'A - Money to wallet',
  },
  {
    type: 'number',
    name: 'actual_fee',
    label: 'B - Actual fee',
  },
  {
    type: 'number',
    name: 'ad_acc_per_issuance_fee',
    label: 'C - Creation fee',
  },
  {
    type: 'number',
    name: 'acc_deposit',
    label: 'D - Acc Deposite',
  },
  {
    type: 'number',
    name: 'total_money',
    label: 'Total money USD',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_money_actual',
    label: 'Total Money',
    disabled: true,
  },
  {
    type: 'number',
    name: 'custom_fee',
    label: '% Fee of topup',
    disabled: true,
    renderValue: (item) => item.custom_fee ? `${item.custom_fee}%` : ''
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'Remaining Topup (Số tiền được nạp miễn phí còn lại)',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'drebest_received',
    label: 'Drebest received',
    checked: (item) => item.drebest_received == 1
  },
  {
    type: 'text',
    name: 'payone_transection_id',
    label: 'Payoneer transection ID',
    width: '10rem',
    visible: (item) => item.payment_method_id?.code === 'po'
  },
  {
    type: 'text',
    name: 'paypal_transection_id',
    label: 'Paypal transection ID',
    width: '10rem',
    visible: (item) => item.payment_method_id?.code === 'paypal'
  },
  {
    type: 'text',
    name: 'bank_transection_id',
    label: 'Bank transection ID',
    width: '10rem',
    visible: (item) => item.payment_method_id?.code === 'bank_vnd'
  },
  {
    type: 'checkbox',
    name: 'recheck',
    label: 'Recheck 2',
    checked: (item) => item.recheck == 1
  },
  {
    type: 'text',
    name: 'cancel',
    label: 'Cancel'
  },
  {
    type: 'text',
    name: 'tranfer_content',
    label: 'Transfer contents'
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note'
  },

]