import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';

class Request9bList extends Component {

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList 
        route="/admin/v1/requests"
        titlePage="Drebest | 2c Acc Using"
        title="9.2 Request"
        fields={listFields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/request_support/${item.request_id}`)}
      />
    )
  }

}

export default Request9bList;