import { filter } from 'lodash';
import * as React from 'react';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Avatar,
  Container,
  Typography,
  Grid,
  CardContent
} from '@mui/material';
// components
import Page from '../../components/Page';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';

// ----------------------------------------------------------------------



export default function Profile() {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };





  return (
    <Page title="User | Minimal-UI">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Account Settings
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab icon={<AccountBoxIcon />}
            iconPosition="start"
            label="General" />
          <Tab icon={<VpnKeyRoundedIcon />}
            iconPosition="start"
            label="Change password" />
        </Tabs>
        <Grid container spacing={3} style={{ marginTop: 10 }} >
          <Grid item xs={12} sm={4} >
            <Card>
              <CardContent />

            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent />

            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
