import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box, FormControlLabel, Checkbox,
} from '@mui/material';
import { listFields, formFields } from './fields';
import { ModalForm, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

class RateExList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }

  createPackage = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "Create package",
      data: {
        total_topup_30days: 0,
        FS2: 0, FSN: 0, FV2: 0, FVN: 0,
        GSK: 0, GSC: 0, GVK: 0, GVC: 0
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Package name <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="name"
              value={submitData.values.name || ''}
              onChange={handleChange}
              variant="outlined"
              type="name"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors.name ? true : false}
              helperText={submitData.errors.name ? submitData.errors.name[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Total topup <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="total_topup_30days"
              value={submitData.values.total_topup_30days}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors.total_topup_30days ? true : false}
              helperText={submitData.errors.total_topup_30days ? submitData.errors.total_topup_30days[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FS2 (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="FS2"
              value={submitData.values['FS2']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FSN (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="FSN"
              value={submitData.values['FSN']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FV2 (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="FV2"
              value={submitData.values['FV2']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FVN (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="FVN"
              value={submitData.values['FVN']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              GSK (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="GSK"
              value={submitData.values['GSK']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              GSC (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="GSC"
              value={submitData.values['GSC']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              GVK (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="GVK"
              value={submitData.values['GVK']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              GVC (%)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="GVC"
              value={submitData.values['GVC']}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Hidden</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.hidden}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'hidden',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'hidden'}
                />
              }
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          name: {
            presence: {allowEmpty: false, message: '^Required'}
          },
          total_topup_30days: {
            presence: {allowEmpty: false, message: '^Required'},
            numericality: {
              greaterThanOrEqualTo: 0,
              message: '^Value must be greater than or equal to %{count}'
            }
          }
        },
        onAction: (submitData) => {
          const allKeys = Object.keys(submitData.values)
            const query = {}
            allKeys.map(key => {
              if (key == 'hidden') {
                query[key] = submitData.values[key] ? 1 : 0
              } else {
                query[key] = submitData.values[key]
              }
            })
          return new Promise((resolve) => {
            request.post('/api/v1/rate_ex', query).then(res => {
              if (res.data.success) {
                toastr.success("Buy service successfully")
                resolve(true)
                ctx.getListItems(ctx.state.searchQuery)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              resolve(false)
              toastr.error(err)
            })            
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const allKeys = Object.keys(formState.changes)
    const query = {}
    allKeys.map(key => {
      if (key == 'hidden') {
        query[key] = formState.changes[key] ? 1 : 0
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/rate_ex/${formState.values.package_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render () {
    const { navigate } = this.props;
    const { mode, open, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/rate_ex"
          titlePage="Drebest | Packages"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.createPackage(ctx),
              visible: true
            }
          ]}
          defaultFilters={{type: 'all'}}
          title="Packages"
          fields={listFields}
          valueKey="package_id"
          onClickRow={(item) => this.setState({ open: true, mode: 'view', objectId: item.package_id })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/rate_ex/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Package Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RateExList)