import { request } from '../../../_services_';
import moment from 'moment';
import { IconBrandMessenger } from '@tabler/icons';
import NumberFormat from 'react-number-format';

const listStatus = [
  { id: 'new', name: 'New' },
  { id: 'processing', name: 'Processing' },
  { id: 'done', name: 'Done' },
  { id: 'cancel', name: 'Cancel' }
]

const getObjectByKey = (field, key) => {
  if (!field) {
    return ''
  }
  const obj = field.keys?.find(e => e.key == key)
  if (obj.value) {
    return obj.value
  }
  if (field.value && field.value.length > 0) {
    return field.value[0][key]
  }
  return null
}

export const listFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY HH:mm:SS') : '',
    sort: true,
    width: '10rem',
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item.name}`,
    sort: true,
    filter:true,
    width: '8rem',
  },
  {
    name: 'acc_code',
    label: 'Acc code',
    width: '8rem',
    renderValue: (item) => item.extra_fields?.length > 0 ? getObjectByKey(item.extra_fields[0], 'account_id').name : ''
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
    align: 'center'
  },
  {
    name: 'team_seller_username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.create_uid ? `${item.team_id?.team_search} ${item.create_uid.shortname || ''}` : '',
    sort: true,
    sortName: 'teams.owner_name',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1'
  },
  {
    name: 'name',
    label: 'Acc Name',
    renderValue: (item) => item.extra_fields?.length > 0 ? getObjectByKey(item.extra_fields[0], 'account_id').title : '',
    width: '16rem',
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.extra_fields?.length > 0 ? getObjectByKey(item.extra_fields[0], 'account_id').acc_id : '',
  },
  {
    name: 'money_topup_to_acc',
    label: 'Money Request',
    sort: true,
    width: '8rem',
    renderValue: (item) => item.extra_fields?.length > 0 ? <NumberFormat value={getObjectByKey(item.extra_fields[0], 'money')} displayType="text" thousandSeparator={','} /> : '',
  },
  {
    name: 'wallet_remaining',
    label: 'Wallet Remaining (after)',
    sort: true,
  },
  {
    name: 'status',
    label: 'Status Final',
    sort: true,
    width: '8rem'
  },
  {
    name: 'partner_code',
    label: 'Partner Code',
    renderValue: (item) => item.account_id?.ref_id?.partner_code,
    sort: true,
    sortName: 'accounts.ref_reviews.partner_code',
    filter:true,
    filterName:'accounts.ref_reviews.partner_code',
    width: '8rem',
  },
  {
    label: 'Wallet Remaining',
    name: 'wallet_remaining',
    sort: true
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'timestamp',
    label: 'Timestamp',
    renderValue: (item) => moment(item.create_date).format('DD/MM/YYYY HH:mm:ss'),
    disabled: true
  },
  {
    type: 'text',
    name: 'request_id',
    label: 'Request ID',
    renderValue: (item) => `Request ${item.request_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'partner_code',
    label: 'Partner code',
    renderValue: (item) => item.account_id?.ref_id?.partner_code,
    disabled: true
  },
  {
    type: 'select',
    name: 'account_id',
    label: 'Acc code',
    renderValue: (item) => item.account_id ? { id: item.account_id.account_id, name: item.account_id.code } : null,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/newids', { search: inputValue, status: 'active', type_acc: 'RAC' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({ id: e.account_id, name: e.code })))
          }
        })
      })
    },
  },
  {
    type: 'text',
    name: 'team_seller_username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.team_id ? `${item.team_id?.team_search} ${item.team_id.owner_name || ''}` : '',
    disabled: true,
  },
  {
    type: 'text',
    name: 'acc_name',
    label: 'Acc name',
    renderValue: (item) => item.account_id?.name,
    disabled: true,
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.account_id?.acc_id,
    disabled: true
  },
  {
    type: 'number',
    name: 'money_topup_to_acc',
    label: 'Money Request',
  },
  {
    type: 'text',
    name: 'request_type_code',
    label: 'Request Type Code',
    renderValue: (item) => item.request_type_id?.code,
    disabled: true
  },
  {
    type: 'text',
    name: 'request_type',
    label: 'Request Type',
    renderValue: (item) => `${item.request_type_id?.name || ''}`,
    disabled: true
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = listStatus.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
        resolve(result)
      })
    },
    renderValue: (item) => listStatus.find(e => e.id == item.status),
  },
  {
    type: 'number',
    name: 'wallet_remaining',
    label: 'Wallet Remaining',
    disabled: true,
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address',
    disabled: true,
    renderValue: (item) => item.team_id?.email
  },
  {
    type: 'text',
    name: 'other_note',
    label: 'Other Note',
    multiline: true,
    filterName:'other_note@ilike'

  },
  {
    type: 'text',
    name: 'content_file',
    label: 'Teams content link Google sheets',
    renderValue: (item) => item.team_id?.content_file,
    disabled: true,
  },
  {
    type: 'text',
    name: 'preliminary_approve',
    label: 'Duyệt sơ bộ',
  },
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Team Mess Group',
    renderValue: (item) => item.team_id?.mess_group_link,
    disabled: true,
  },
  {
    type: 'number',
    name: 'amount_spent',
    label: 'Số tiền đã tiêu thụ / Amount spent',
  },
  {
    type: 'number',
    name: 'amount_tranferred',
    label: 'Số tiền đã chuyển khoản / Amount tranferred',
  },
  {
    type: 'text',
    name: 'reason_stop',
    label: 'Nguyên nhân dừng hợp tác',
  },
]
