import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'

const TeamReportForm = (props) => {
  
  return (
    <ResourceForm 
      mode={'view'}
      fields={formFields}
      route={'app1/team_report'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/team/${id}`, {type: 'report'}).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Team Report Detail"
      actionBack={() => props.navigate('/app1/team_reports')}
      {...props}
    />
  )
}

export default connect(null)(withRouter(TeamReportForm))