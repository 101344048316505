import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'

const Acc2cApp1Form = (props) => {
  const [mode, setMode] = useState('view')

  return (
    <ResourceForm 
      mode={mode}
      fields={formFields}
      route={'app1/accounts'}
      onLoadData={(public_id) => {
        return new Promise((resolve) => {
          request.get(`/admin/v1/account/${public_id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Acc Detail"
      actionBack={() => props.navigate('/app1/accounts')}
      actions={[]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(Acc2cApp1Form))