import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';

export const timeZoneAcc = [
  { id: 'GMT+7', name: 'GMT+7' },
  { id: 'GMT-7', name: 'GMT-7' },
]

export const listFields = [
  {
    name: 'sin_number',
    label: 'SIN number',
    renderValue: (item) => item?.sin_id ? `${item?.sin_number}` : '',
    sort:true,
    filter:true,

  },
  {
    name: 'sin_id',
    label: 'Have REF number',
    sort:true,
  },

  {
    name: 'team_username',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item?.team_id?.team_search} ${item?.team_id?.owner_name}` : '',
    sort:true,
    sortName:'teams.team_search',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1',

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_search',
    label: 'Team ID',
    renderValue: (item) => item?.team_id?`${item?.team_id?.team_search}`:'',
    sort:true,
    sortName:'teams.team_search',
    filter:true,
    filterName:'teams.team_search',
  },
  {
    name: 'RAC',
    label: 'RAC (select)',
    sort:true,

  },
  {
    name: 'seller_username',
    label: 'Seller Username',
    renderValue: (item) => item?.team_id?`${item?.team_id?.owner_name}`:'',
    sort:true,
    sortName:'teams.owner_name',
    filter:true,
    filterName:'teams.owner_name',
  },
  {
    name: 'other_note',
    label: 'Other note',
    sort:true,
    filter:true,
    filterName:'other_note@ilike'

  },
  {
    name: 'your_demand',
    label: 'Your demand',
    sort:true,
    filter:true,

  },
  {
    name: 'RAI',
    label: 'RAI ID',
    sort:true,

  },
  {
    name: 'Request',
    label: 'Request type of Acc',
    sort:true,

  },
  {
    name: 'spent_per_month',
    label: 'Spent USD/ month (estimated)',
    sort:true,

  },
  {
    name: 'total_acc_request',
    label: 'Total Acc request',
    sort:true,

  },
  {
    name: 'request_acc_timezone',
    label: 'Request Acc Timezone',
    sort:true,

  },
  {
    name: 'product_type_id',
    label: 'Product Type',
    renderValue: (item) => item?.product_type_id ? `${item?.product_type_id?.name}`:'',
    sort:true,
    sortName:"product_type.name",
    filter:true,
    filterName:"product_type.name",

  },
  {
    name: 'product_info',
    label: 'Product Info',
    sort:true,

  },
  {
    name: 'platform1',
    label: 'Platform of domain 1',
    renderValue: (item) => item?.platform1 ?`${item?.platform1?.name}`:'',
    sort:true,
    sortName:"platform_web.name",
    filter:true,
    filterName:"platform_web.name",
  },
  {
    name: 'platform2',
    label: 'Platform of domain 2',
    renderValue: (item) => item?.platform2 ?`${item?.platform2?.name}`:'',
    sort:true,
    sortName:"platform_web.name",
  },
  {
    name: 'other_page_link',
    label: 'Ever created Acc at another Agency?',
    sort:true,

  },
  {
    name: 'edit_company_info',
    label: 'Can your website temporarily edit company information?',
    renderValue: (item) => `${item?.edit_company_info ? 'Yes' : 'No'}`,
    sort:true,
  },
  {
    name: 'change_dns',
    label: 'Can your domain change DNS temporarily?',
    renderValue: (item) => `${item?.change_dns ? 'Yes' : 'No'}`,
    sort:true,
  },
  {
    name: 'page1_id',
    label: 'Page 1 - ID',
    sort:true,
    filter:true,
  },
  {
    name: 'page1_name',
    label: 'Page 1 - Name',
    sort:true,
    filter:true,

  },
  {
    name: 'page2_id',
    label: 'Page 2 - ID',
    sort:true,
    filter:true,

  },
  {
    name: 'page2_name',
    label: 'Page 2 - Name',
    sort:true,
    filter:true,

  },
  {
    name: 'page3_id',
    label: 'Page 3 - ID',
    sort:true,
    filter:true,

  },
  {
    name: 'page3_name',
    label: 'Page 3 - Name',
    sort:true,
    filter:true,

  },
  {
    name: 'page4_id',
    label: 'Page 4 - ID',
    sort:true,
    filter:true,

  },
  {
    name: 'page4_name',
    label: 'Page 4 - Name',
    sort:true,
    filter:true,

  },
  {
    name: 'share_admin_page',
    label: 'Share admin rights of  your Pages to Drebest by?',
    sort:true,
  },
  {
    name: 'finish_setting',
    label: 'Finished setting up the pages according to ther instructions?',
    sort:true,
  },
  {
    name: 'bm_name',
    label: 'BM Name',
    sort:true,
    filter:true,

  },
  {
    name: 'bm_id',
    label: 'BM ID',
    sort:true,
    filter:true,

  },
  {
    name: 'acc_in_bm_screenshot',
    label: 'Acc in BM screenshot',
    sort:true,
  },
  {
    name: 'VIA',
    label: 'VIA Name',
    sort:true,
  },
  {
    name: 'VIA_ID',
    label: 'VIA ID',
    sort:true,
  },
  {
    name: 'bm2_id',
    label: 'BM ID 2 (preventive)',
    sort:true,
    filter:true,

  },
  {
    name: 'correct_info',
    label: 'Correct info',
    sort:true,
  },
  {
    name: 'tt_done',
    label: 'Đổi TT xong',
    sort:true,
  },
  {
    name: 'canceled',
    label: 'Canceled',
    sort:true,
  },
  {
    name: 'note',
    label: 'Note',
    sort:true,
    filter:true,
    filterName:'note@ilike'
  },
  {
    name: 'grant_acc_done',
    label: 'Đủ ĐK cấp Acc',
    sort:true,
  },
  {
    name: 'Acc',
    label: 'Cấp Acc loại A',
    sort:true,
  },
  {
    name: 'grant_acc',
    label: 'Cấp xong loại A',
    sort:true,
  },
  {
    name: 'pause',
    label: 'Pause',
    sort:true,
  },
  {
    name: 'Bank',
    label: 'Bank Name',
    sort:true,
  },
  {
    name: 'page1_name',
    label: 'Page 1 - Name Link',
    sort:true,
  },
  {
    name: 'page2_name',
    label: 'Page 2 - Name Link',
    sort:true,
  },
  {
    name: 'page3_name',
    label: 'Page 3 - Name Link',
    sort:true,
  },
  {
    name: 'page4_name',
    label: 'Page 4 - Name Link',
    sort:true,
  },
  {
    name: 'REF',
    label: 'REF number',
    sort:true,
  },
  {
    name: 'SIN',
    label: 'SIN number lastest',
    sort:true,
  },
  {
    name: 'REF',
    label: 'REF ID lastest',
    sort:true,
  },
  {
    name: 'REF',
    label: 'REF number lastest',
    sort:true,
  },
  {
    name: 'team_id',
    label: 'Team ID Shortname Seller',
    renderValue: (item) => item?.team_id?`${item?.team_id?.team_search} ${item?.team_id?.owner_name||''}`:'',
    sort:true,
    sortName:"teams.team_search",
    filter:true,
    filterName:"teams.team_search|teams.owner_name*1"
  },
  {
    name: 'time_zone',
    label: 'Số Acc đã được cấp',
    sort:true,

  },
  {
    name: 'SUM',
    label: 'SUM of A - Money to Wallet',
    sort:true,

  },
  {
    name: 'REF',
    label: 'REF number_83',
    sort:true,

  },
  {
    name: 'Acc',
    label: 'Acc code',
    sort:true,

  },
  {
    name: 'Feedback',
    label: 'Feedback',
    sort:true,

  },
  {
    name: 'Acc',
    label: 'Cấp xong Acc',
    sort:true,

  },

  {
    name: 'form',
    label: 'Điền form BM',
    sort:true,

  },
  {
    name: 'countif',
    label: 'BM info countif',
    sort:true,

  },
  {
    name: 'Link',
    label: 'BM ID Link',
    sort:true,

  },
  {
    name: 'Link',
    label: 'BM Name Link',
    sort:true,

  },
  {
    name: 'Acc_92',
    label: 'Đủ điều kiện cấp Acc_92',
    sort:true,

  },
  {
    name: 'Status',
    label: 'Status of REF',
    sort:true,

  }
]

export const formFields = [
  {
    type: 'select',
    name: 'team_id',
    label: 'Team ID manual',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/teams', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'team_id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'other_note',
    label: 'Other note',
  },
  {
    type: 'text',
    name: 'request_type_of_acc',
    label: 'Request type of Acc',
  },
  {
    type: 'number',
    name: 'total_acc_request',
    label: 'Total Acc request',
  },
  {
    type: 'select',
    name: 'request_acc_timezone',
    label: 'Request Acc Timezone',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timeZoneAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.time_zone ? timeZoneAcc.find(e => e.id == item.time_zone) : null,
    labelKey: 'name',
    valueKey: 'id'
  },
  {
    type: 'text',
    name: 'product_info',
    label: 'Product type'
  },
  {
    type: 'text',
    name: 'domain1',
    label: 'Domain1 *'
  },
  {
    type: 'select',
    name: 'platform1',
    label: 'Platform of domain 1',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/platforms', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'platform_id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'domain2',
    label: 'Domain2'
  },
  {
    type: 'select',
    name: 'platform2',
    label: 'Platform of domain 2',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/platforms', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'platform_id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'other_website_link',
    label: 'Other website link'
  },
  {
    type: 'checkbox',
    name: 'exists_at_another_agency',
    label: 'Ever created Acc at another Agency?',
    checked: (item) => item?.exists_at_another_agency ? true : false
  },
  {
    type: 'text',
    name: 'edit_company_info',
    label: 'Can your website temporarily edit company infomation?'
  },
  {
    type: 'text',
    name: 'change_dns',
    label: 'Can your domain change DNS temporatily?'
  },
  {
    type: 'text',
    name: 'page1_link',
    label: 'Page 1 - Link*'
  },
  {
    type: 'text',
    name: 'page1_id',
    label: 'Page 1 - ID*'
  },
  {
    type: 'text',
    name: 'page1_name',
    label: 'Page 1 - Name'
  },
  {
    type: 'text',
    name: 'page2_link',
    label: 'Page 2 - Link'
  },
  {
    type: 'text',
    name: 'page2_id',
    label: 'Page 2 - ID'
  },
  {
    type: 'text',
    name: 'page2_name',
    label: 'Page 2 - Name'
  },
  {
    type: 'text',
    name: 'page3_link',
    label: 'Page 3 - Link'
  },
  {
    type: 'text',
    name: 'page3_id',
    label: 'Page 3 - ID'
  },
  {
    type: 'text',
    name: 'page3_name',
    label: 'Page 3 - Name'
  },
  {
    type: 'text',
    name: 'page4_link',
    label: 'Page 4 - Link'
  },
  {
    type: 'text',
    name: 'page4_id',
    label: 'Page 4 - ID'
  },
  {
    type: 'text',
    name: 'page4_name',
    label: 'Page 4 - Name'
  },
  {
    type: 'text',
    name: 'other_page_link',
    label: 'Other Page - Link'
  },
  {
    type: 'text',
    name: 'share_admin_page',
    label: 'Share admin rights of your Page to Drebest by?'
  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name'
  },
  {
    type: 'text',
    name: 'bm_id',
    label: 'BM ID'
  },
  {
    type: 'text',
    name: 'bm_admin_link',
    label: 'BM admin link invite'
  },
  {
    type: 'text',
    name: 'acc_in_bm_screenshot',
    label: 'Acc in BM screenshot'
  },
  {
    type: 'text',
    name: 'bm2_id',
    label: 'BM ID 2 (preventive)'
  },
  {
    type: 'number',
    name: 'correct_info',
    label: 'Correct info'
  },
  {
    type: 'number',
    name: 'tt_done',
    label: 'Đổi TT xong'
  },
  {
    type: 'number',
    name: 'canceled',
    label: 'Canceled'
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note'
  },
  {
    type: 'number',
    name: 'grant_acc_done',
    label: 'Đủ ĐK cấp Acc'
  },
  {
    type: 'number',
    name: 'pause',
    label: 'Pause'
  },
  {
    type: 'number',
    name: 'grant_acc',
    label: 'Cấp Acc xong'
  }

]