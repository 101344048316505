import React from 'react'
import NumberFormat from "react-number-format"
import moment from 'moment'
import { Label } from '../../../components';
import { capitalizeStr } from '../../../utils';

export const fields = [
  {
    name: 'sent_date',
    label: 'Timestamp',
    sort: true,
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:SS') : ''
  },
  {
    name: 'team_from_id',
    label: 'Transfer From Team ID',
    sort: true,
    filter: true,
    renderValue: (item) => item.team_from_id ? `Team ${item.team_from_id}` : ''
  },
  {
    name: 'team_to_id',
    label: 'To Team ID',
    sort: true,
    filter: true,
    renderValue: (item) => item.team_to_id ? `Team ${item.team_to_id}` : '',
  },
  {
    name: 'amount',
    label: 'Amount',
    sort: true,
    renderValue: (item) => <NumberFormat value={item.amount} displayType="text" thousandSeparator={','} />,
  },
]

export const formfields = [
  {
    type: 'text',
    disabled: true,
    name: 'sent_date',
    label: 'Timestamp',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:SS') : ''
  },
  {
    type: 'text',
    disabled: true,
    name: 'team_from_id',
    label: 'Transfer From Team ID',
    renderValue: (item) => item.team_from_id ? `Team ${item.team_from_id}` : ''
  },
  {
    name: 'team_to_id',
    label: 'To Team ID',
    type: 'text',
    renderValue: (item) => item.team_to_id ? `Team ${item.team_to_id}` : '',
    disabled: true,
  },
  {
    type: 'number',
    disabled: true,
    name: 'amount',
    label: 'Amount',
  },
]