import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import personFill from '@iconify/icons-eva/person-fill';
import unlockFill from '@iconify/icons-eva/unlock-fill';
import filetextFill from '@iconify/icons-eva/file-text-fill';
import { useNavigate } from 'react-router-dom';

import youtubeFilled from '@iconify/icons-ant-design/youtube-filled';
import { connect } from 'react-redux';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, TextField, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { store, toastr } from '../../_helpers_';
import account from '../../_mocks_/account';
import { ModalForm } from '../../components';
import { request } from '../../_services_';
import { userActions } from '../../_actions_';
import { TUTORIAL_URL } from '../../_constants_';

const AccountPopover = (props) => {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    store.dispatch(userActions.logout());
  }

  const changePassword = () => {
    setOpen(false);
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Change password',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Old Password <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="old_password"
              autoComplete='new-password'
              onChange={handleChange}
              value={submitData.values.old_password || ""}
              variant="outlined"
              type="password"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.old_password}
              helperText={submitData.errors?.old_password ? submitData.errors.old_password[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              New Password <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="new_password"
              autoComplete='new-password'
              onChange={handleChange}
              value={submitData.values.new_password || ""}
              variant="outlined"
              type="password"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.new_password}
              helperText={submitData.errors?.new_password ? submitData.errors.new_password[0] : ''}
            />
          </div>
        </div>
      ),
      action: {
        schema: {
          old_password: {presence: {message: '^Required'}},
          new_password: {presence: {message: '^Required'}},
        },
        titleAction: 'Change',
        onAction: (submitData) => {
          const query = submitData.values
          return new Promise((resolve) => {
            request.post('/auth/change_password', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Change password successful")
              } else {
                resolve(false);
                toastr.error(res.data.msg)
              }
            }, err => {
              toastr.error(err);
              resolve(false);
            })
          })
        }
      }
    })
  }

  const openTutorial = () => {
    setOpen(false);
    ModalForm.instance.current.openForm({
      data: {},
      size: "md",
      title: 'Drebest App Tutorial',
      customView: (submitData, handleChange) => (
        <div className='player-wrapper'>
          <ReactPlayer
            url={TUTORIAL_URL}
            className='react-player'
            playing
            controls
            width='100%'
            height='100%'
          />
        </div>
      )
    })
  }

  return (
    <>
      <ModalForm />
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {props.user?.display_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {props.user?.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem
          onClick={openTutorial}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={youtubeFilled}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />

          Tutorial
        </MenuItem>
        {/* {props.user?.is_owner &&
          <MenuItem
            onClick={()=>{
              navigate('/register')
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={filetextFill}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            Register new team
          </MenuItem>
        } */}
        {/* <MenuItem
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={personFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />

          Profile
        </MenuItem> */}
        <MenuItem
          onClick={changePassword}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={unlockFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />

          Change Password
        </MenuItem>
        

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button 
            fullWidth 
            color="inherit" 
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(AccountPopover)
