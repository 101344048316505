import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList } from '../../../components';

class Acc2cApp1List extends Component {

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList 
        route="/admin/v1/accounts"
        titlePage="Drebest | 7.0 Acc All (DB7)"
        title="7.0 Acc All (DB7)"
        fields={listFields}
        valueKey="public_id"
        onClickRow={(item) => navigate(`/app1/accounts/${item.public_id}`)}
      />
    )
  }

}

export default Acc2cApp1List;