import { request } from '../../../_services_';

const timeZoneAcc = [
  {id: 'GMT+7', name: 'GMT+7'},
  {id: 'GMT-7', name: 'GMT-7'},
]

export const listFields = [
  {
    name: 'code',
    label: 'Acc code',
    width: '8rem',
    sort:true,
    filter:true,
  },
  {
    name: 'name',
    label: 'Acc Name',
    width: '8rem',
    sort:true,
    filter:true,
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort:true,
    filter:true,
  },
  {
    name: 'timezone',
    label: 'Acc Timezone',
    sort:true
  },
  {
    name: 'spending_limit',
    label: 'Delta Spending Limit',
    sort:true
  },
  {
    name: 'spent_current',
    label: 'Delta Spent',
    sort:true
  },
  {
    name: 'remaining',
    label: 'Delta Remaining',
    sort:true
  },
  {
    name: 'status',
    label: 'Acc Status',
    sort:true
  },
  {
    name: 'spending_limit_fb',
    label: 'Spending limit on FB',
    sort:true
  },
  {
    name: 'using',
    label: 'Using',
    sort:true
  },
  {
    name: 'team_seller_username',
    label: 'Team_Seller Username',
    sort:true,
    renderValue: (item) => item.team_id ? `${item.team_id?.team_search} ${item.team_id?.owner_name}` : null,
    sortName:'teams.team_search',
    filter:true,
    filterName:'teams.team_search|teams.owner_name*1'
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'acc_code',
    label: 'Acc Code',
    renderValue: (item) => item.code,
    disabled: true
  },
  {
    type: 'text',
    name: 'name',
    label: 'Acc Name',
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'Acc ID',
    disabled: true
  },
  {
    type: 'select',
    name: 'timezone',
    label: 'Acc Timezone',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timeZoneAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => timeZoneAcc.find(e => e.id == item.timezone)
  },
  {
    type: 'number',
    name: 'spending_limit',
    label: 'Delta Spending Limit',
    disabled: true
  },
  {
    name: 'spent_current',
    label: 'Delta Spent',
    type: 'number',
    disabled: true
  },
  {
    name: 'remaining',
    label: 'Delta Remaining',
    type: 'number',
    disabled: true
  },
  {
    type: 'text',
    name: 'payment_settings',
    label: 'Payment Settings',
    disabled: true,
  },
  {
    name: 'spent_check_fb',
    label: 'Spending limit on FB',
    type: 'number',
    disabled: true
  },
  {
    type: 'text',
    name: 'via_people',
    label: 'Via People',
    disabled: true,
  },
  {
    type: 'text',
    name: 'ads_manager',
    label: 'Ads Manager',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'status',
    label: 'Using',
    disabled: true,
    checked: (item) => item.status === 'active' ? true : false
  },
  {
    type: 'select',
    name: 'user_id',
    label: 'Team_Seller Username',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/users', {search: inputValue, has_account: 0}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.uid, name: `${e.team_id?.team_search} ${e.name}`, team_id: e.team_id, uid: e.uid, shortname: e.shortname})))
          }
        })
      })
    },
    renderValue: (item) => item.user_id ? {id: item.user_id.uid, name: `${item.user_id.team_id?.team_search} ${item.user_id.name}`} : null
  },
  {
    name: 'available_acc',
    label: 'Available Acc',
    type: 'number',
    disabled: true
  },
]