import React, { Component } from 'react'
import { listOtherFields } from './fields';
import { ResourceList } from '../../../components';

class RequestC1C2B3 extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="Drebest | 9.2 Request Other"
        title="9.2 Request Other"
        fields={listOtherFields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/request_support/${item.request_id}`)}
        defaultFilters={
          {without:JSON.stringify(['C1','C2','B3','B5'])}
        }
      />
    )
  }

}

export default RequestC1C2B3;