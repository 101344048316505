import { Drawer, TextField, Box, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect } from 'react';
import { request } from '../../_services_';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { hideSettings } from '../../_actions_';
import { makeStyles } from '@mui/styles';
import { toastr } from '../../_helpers_';
import { capitalizeStr, makeId } from '../../utils';
import { CustomCircularProgress, CustomNumberFormat } from '../../components';
import { IconTrash } from '@tabler/icons'

const useStyles = makeStyles(theme => ({
  loading_container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 200,
    width: '100%',
    height: '100%',
    '& .loading': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  header: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E6E8EB',
    padding: '0 20px',
  },
  body: {
    height: 'calc(100vh - 128px)',
    overflow: 'auto',
    padding: 20,
  },
  footer: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #E6E8EB',
    padding: '0 20px',
  },
  button: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: 15,
    borderRadius: 8,
    transition: 'all 0.3s ease',
    '&.save': {
      background: '#00AB55',
      color: '#FFF',
      padding: '0 24px',
      '&:hover': {
        background: '#014f28',
        transition: 'all 0.3s ease'
      }
    },
    '&.cancel': {
      border: '1px solid red',
      color: 'red',
      padding: '0 15px',
      '&:hover': {
        background: '#ffe3e3',
        transition: 'all 0.3s ease'
      }
    },
    
  },
  textJson: {
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0
    }
  }
}))

const SettingDrawer = (props) => {
  const openSetting = useSelector(state => state.setting)
  const dispatch = useDispatch()
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false)
  const [formState, setFormState] = React.useState({values: {settings: []}})

  const handleSave = () => {
    const query = {}
    formState.values.settings.map(e => {
      query[e.key] = e.value 
    })
    request.put('/api/v1/configs', query).then(res => {
      if (res.data.success) {
        toastr.success("Update successfully")
        dispatch(hideSettings())
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err)
    })
  }

  const addArray = (id) => {
    const items = Object.assign([], JSON.parse(formState.values['settings'].find(e => e.id == id).value))
    if (items.lenth <= 0) {
      return
    }
    const keys = Object.keys(items[0])
    let new_obj = {}
    keys.map(key => new_obj[key] = '')
    items.push(new_obj)

    const setting = Object.assign({}, formState.values['settings'].find(e => e.id == id))
    setting.value = JSON.stringify(items)
    const settings = formState.values.settings.map(e => e.id == setting.id ? setting : e)
    const form = Object.assign({}, formState)
    form.values['settings'] = settings
    setFormState(form)
  }

  const removeArray = (id, index) => {
    const items = Object.assign([], JSON.parse(formState.values['settings'].find(e => e.id == id).value))
    if (items.lenth <= 0) {
      return
    }
    items.splice(index, 1)

    const setting = Object.assign({}, formState.values['settings'].find(e => e.id == id))
    setting.value = JSON.stringify(items)
    const settings = formState.values.settings.map(e => e.id == setting.id ? setting : e)
    const form = Object.assign({}, formState)
    form.values['settings'] = settings
    setFormState(form)
  }

  useEffect(() => {
    if (openSetting) {
      setLoading(true)
      request.get('/api/v1/configs', {}).then(res => {
        setLoading(false)
        if (res.data.success) {
          const form = Object.assign({}, formState)
          form.values['settings'] = res.data.data
          setFormState(form)
        } else {
          toastr.error(res.data.msg)
        }
      }, err => {
        setLoading(false)
        toastr.error(err)
      })
    }
  }, [openSetting])
  
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openSetting}
        onClose={() => dispatch(hideSettings())}
        className="setting-drawer"
      >
        {isLoading && 
          <div className={classes.loading_container}>
            <div className='loading'>
              <CustomCircularProgress />
            </div>
          </div>
        }
        <div className={classes.header}>
          <Box fontSize={18} fontWeight="bold">Settings</Box>
        </div>
        <div className={classes.body}>
          {formState.values.settings.map(setting => {
            if (['number', 'string'].includes(setting.data_type)) {
              return (
                <div key={setting.id} className="mb-3">
                  <Box fontSize={16} fontWeight="bold" mb={'8px'}>{setting.title}</Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={setting.key}
                    value={setting.value}
                    onChange={(event) => {
                      const item = Object.assign({}, setting)
                      item.value = event.target.value
                      const settings = formState.values.settings.map(e => e.id == item.id ? item : e)
                      const form = Object.assign({}, formState)
                      form.values['settings'] = settings
                      setFormState(form)
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'notchedOutline'
                      },
                      inputComponent: setting.data_type == 'number' ? CustomNumberFormat : null
                    }}
                  />
                </div>
              )
            } else if (setting.data_type == 'json') {
              return (
                <div key={setting.id} className="mb-3">
                  <Box fontSize={16} fontWeight="bold" mb={'8px'}>{setting.title}</Box>
                  {Object.keys(JSON.parse(setting.value)).map(key => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      className={classes.textJson}
                      name={key}
                      value={JSON.parse(setting.value)[key]}
                      onChange={(event) => {
                        const item = Object.assign({}, JSON.parse(setting.value))
                        item[key] = event.target.value
                        const value = Object.assign({}, setting)
                        value.value = JSON.stringify(item)
                        const settings = formState.values.settings.map(e => e.id == value.id ? value : e)
                        const form = Object.assign({}, formState)
                        form.values['settings'] = settings
                        setFormState(form)
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'notchedOutline'
                        },
                        inputComponent: CustomNumberFormat,
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ backgroundColor: '#ccc', padding: '10px 16px', borderRadius: '8px 0px 0px 8px' }}>
                              {key}
                            </div>
                          </InputAdornment>
                        )
                      }}
                    />
                  ))}
                </div>
              )
            } else if (setting.data_type == 'array') {
              return (
                <div key={setting.id} className="mb-3">
                  <Box fontSize={16} fontWeight="bold" mb={'8px'}>{setting.title}</Box>
                  {JSON.parse(setting.value)?.map((obj,index) => 
                    (Object.keys(obj).map((key, i) => (
                      <TextField
                        key={i}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        className={classes.textJson}
                        name={key}
                        value={obj[key]}
                        onChange={(event) => {
                          const items = Object.assign([], JSON.parse(setting.value))
                          items[index][key] = event.target.value
                          const value = Object.assign({}, setting)
                          value.value = JSON.stringify(items)
                          const settings = formState.values.settings.map(e => e.id == value.id ? value : e)
                          const form = Object.assign({}, formState)
                          form.values['settings'] = settings
                          setFormState(form)
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'notchedOutline'
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ backgroundColor: '#ccc', padding: '10px 16px', borderRadius: '8px 0px 0px 8px',  minWidth: '90px'}}>
                                {`${key} ${index + 1}`}
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconTrash 
                              style={{cursor: 'pointer', marginRight: '10px'}}
                              onClick={() => removeArray(setting.id, index)}
                            />
                          )
                        }}
                      />
                    )))
                  )}
                  <button 
                    style={{borderRadius: '8px', padding: '2px 10px', fontSize: '15px', marginTop: '5px'}}
                    onClick={() => addArray(setting.id)}
                  >
                    Add
                  </button>
                </div>
              )
            }
          })}
        </div>
        <div className={classes.footer}>
          <div 
            className={clsx(classes.button, 'save')}
            onClick={handleSave}
          >
            Save
          </div>
          <div 
            className={clsx(classes.button, 'cancel')}
            onClick={() => dispatch(hideSettings())}
          >
            Cancel
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  )

}

export default SettingDrawer;
