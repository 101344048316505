import React, { Component } from 'react'
import { listRacFields, listRacNumberFormFields } from './fields';
import { ModalForm, Uploader, ResourceList, CustomNumberFormat, DrawerForm } from '../../../components'
import { request } from '../../../_services_';
import moment from 'moment';
import { toastr } from '../../../_helpers_'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {
  TextField, Box, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'

class RacNumberList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newRacNumber = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              CRM Number
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/cmn_numbers', {search: inputValue, active: 1}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.id, name: e.title})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"cmn_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "cmn_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"CMN Number"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.cmn_id || null}
              styles={submitData.errors?.cmn_id ? errorStyles : customStyles}
            />
            {submitData.errors?.cmn_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.cmn_id[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Number
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/br_numbers', {search: inputValue, active: 1, map_rac: 0}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.id, name: `BR ${e.id}`})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"br_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "br_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"BR Number"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.br_id || null}
              styles={submitData.errors?.br_id ? errorStyles : customStyles}
            />
            {submitData.errors?.br_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.br_id[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Email Number
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/admin/v1/email_numbers', {search: inputValue, active: 1}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items?.map(e => ({id: e.id, name: e.title})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"email_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "email_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Email Number"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.email_id || null}
              styles={submitData.errors?.email_id ? errorStyles : customStyles}
            />
            {submitData.errors?.email_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.email_id[0]}</span>}
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox checked={submitData.values?.active} 
                  onChange={(e) => {
                    var event = {
                      target: {
                        name: 'active',
                        value: e.target.checked
                      }
                    }
                    handleChange(event)
                  }} name="active" />
              }
              label={<Box fontSize={15} fontWeight='bold'>Disabled RAC</Box>}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          cmn_id: {presence: {message: '^Required'}},
          br_id: {presence: {message: '^Required'}},
          email_id: {presence: {message: '^Required'}},
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            const allKeys = Object.keys(submitData.values)
            const query = {}
            allKeys.map(key => {
              if (typeof submitData.values[key] === 'object' || submitData.values[key] instanceof Object) {
                query[key] = submitData.values[key].id
              } else if(key == 'active') {
                query[key] = submitData.values[key] ? 0 : 1 
              } else {
                query[key] = submitData.values[key]
              }
            })
            request.post('/admin/v1/rac_numbers', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err?.response?.data?.msg);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const allKeys = Object.keys(formState.changes)
    const query = {}
    allKeys.map(key => {
      if (typeof formState.changes[key] === 'object' || formState.changes[key] instanceof Object) {
        query[key] = formState.changes[key].id
      } else if(key == 'active') {
        query[key] = formState.changes[key] ? 1 : 0
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/admin/v1/rac_number/${formState.values.rac_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err)
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/rac_numbers"
          titlePage="Drebest | 6.1 RAC number"
          title="6.1 RAC number"
          fields={listRacFields}
          valueKey="rac_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.rac_id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newRacNumber(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={listRacNumberFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/rac_number/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="RAC Number Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default RacNumberList;