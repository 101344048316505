import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { listRacNumberFormFields } from './fields'

const RacNumberForm = (props) => {

  const toggleRacNumber = (ctx, formState, type) => {
    const query = {
      active: type === 'disable' ? 0 : 1
    }
    request.put(`/admin/v1/rac_number/${formState.values.rac_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err)
    })
  }

  return (
    <ResourceForm 
      mode={'view'}
      fields={listRacNumberFormFields}
      route={'app3/rac_number'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/admin/v1/rac_number/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="RAC Number Detail"
      actionBack={() => props.navigate('/app3/rac_numbers')}
      actions={[
        {
          text: 'Disable',
          variant: 'contained',
          color: 'secondary',
          action: (ctx, formState) => toggleRacNumber(ctx, formState, 'disable'),
          visible: (item) => item.active === true
        },
        {
          text: 'Enable',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => toggleRacNumber(ctx, formState, 'enable'),
          visible: (item) => !item.active
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(RacNumberForm))