import React from 'react';
import { Box, TextField } from '@mui/material';
import { IconTrash } from '@tabler/icons';
import { CustomNumberFormat } from '../../../components';
import validate from 'validate.js';
import { v4 as uuidv4 } from 'uuid';

const PriceSharings = (props) => {
  const { handleChange, disabled, submitData } = props;
  const schema_line = {
    key: {
      presence: { allowEmpty: false, message: '^Required' }
    },
    value: {
      presence: { allowEmpty: false, message: '^Required' },
      numericality: {
        greaterThanOrEqualTo: 0,
        message: '^Value must be greater than or equal to %{count}'
      }
    }
  }
  return (
    <div style={{ marginTop: 10 }}>
      <div className='mt-2'>
        <div className='d-flex align-items-center price-sharing-line'>
          <div style={{ flexBasis: '50%' }} className="p-1"><Box fontSize={15} fontWeight='bold'>Title</Box></div>
          <div style={{ flexBasis: '40%' }} className="p-1"><Box fontSize={15} fontWeight='bold'>Value</Box></div>
          <div style={{ flexBasis: '10%' }} className="p-1"></div>
        </div>
        {submitData.sharings?.map((sharing, index) => (
          <div className='d-flex align-items-center price-sharing-line' key={sharing.id}>
            <div style={{ flexBasis: '50%' }} className="p-1">
              <TextField 
                disabled={disabled}
                fullWidth
                margin='dense'
                name="key"
                style={{ marginTop: 4 }}
                value={sharing.key}
                onChange={(event) => {
                  const item = Object.assign({}, sharing)
                  item[event.target.name] = event.target.value
                  const sharings = submitData.sharings.map(e => e.id == item.id ? item : e)
                  const e = {
                    target: {
                      name: 'sharings',
                      value: sharings
                    }
                  }
                  handleChange(e)
                }}
                placeholder="Title..."
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline',
                    input: 'input-line'
                  },
                }}
                error={sharing.errors?.key ? true : false}
              />
            </div>
            <div style={{ flexBasis: disabled ? '50%' : '40%' }} className="p-1">
              <TextField 
                disabled={disabled}
                fullWidth
                margin='dense'
                name="value"
                style={{ marginTop: 4 }}
                value={sharing.value}
                placeholder="Value..."
                variant="outlined"
                onChange={(event) => {
                  const item = Object.assign({}, sharing)
                  item[event.target.name] = event.target.value
                  const sharings = submitData.sharings.map(e => e.id == item.id ? item : e)
                  const e = {
                    target: {
                      name: 'sharings',
                      value: sharings
                    }
                  }
                  handleChange(e)
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline',
                    input: 'input-line'
                  },
                  inputComponent: CustomNumberFormat
                }}
                error={sharing.errors?.value ? true : false}
              />
            </div>
            {!disabled &&
            <div style={{ flexBasis: '10%' }} className="p-1">
              <div 
                style={{ border: '1px solid red', borderRadius: 6, padding: 10 }} 
                className="d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() => {
                  const sharings = submitData.sharings.filter(e => e.id !== sharing.id)
                  const e = {
                    target: {
                      name: 'sharings',
                      value: sharings
                    }
                  }
                  handleChange(e)
                }}
              >
                <IconTrash color="red" size={20} stroke={2} />
              </div>
            </div>
            }
          </div>
        ))}
        {!disabled &&
        <div className="mt-2">
          <div 
            className="button-add-line"
            onClick={() => {
              const sharings = Object.assign([], submitData.sharings)
              if (sharings.length == 0) {
                sharings.push({id: uuidv4(), errors: {}})
              } else {
                var sum_error = 0
                sharings.forEach(item => {
                  const errors = validate(item, schema_line)
                  item.errors = errors || {}
                  if (errors) {
                    sum_error += 1
                  }
                })
                if (sum_error == 0) {
                  sharings.push({id: uuidv4(), errors: {}})
                }
              }
              const event = {
                target: {
                  name: 'sharings',
                  value: sharings
                }
              }
              handleChange(event)
            }}
          >
            Add
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default PriceSharings