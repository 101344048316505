import { Checkbox } from '@mui/material';
import { IconBrandMessenger } from '@tabler/icons';
import moment from 'moment'
import { request } from '../../../_services_'

export const filterRAI = [
  {
    valueKey: 'id',
    key: 'partner_code',
    defaultAriaLabel: 'Partner Code',
    getOptionLabel: (item) => item.name,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/partner_code', { search: inputValue || '' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  }
]

export const listFields = (type) => ([
  {
    name: 'name',
    label: type == 'rai' ? 'TAR ID' : 'TAG ID',
    sort: true,
    width: '6rem',
  },
  {
    name: 'done_date',
    label: 'Time Topup Acc',
    renderValue: (item) => item['done_date'] ? moment(item['done_date']).format('DD/MM/YYYY HH:mm:ss') : '',
    sort: true,
    width: '10rem',
  },
  {
    name: 'acc_name',
    label: 'Acc Name',
    renderValue: (item) => item.account_id?.acc_name || item.account_id?.acc_pool_id?.acc_name || '',
    sort: true,
    filter: true,
    width: '20rem',
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.account_id?.acc_pool_id?.acc_id || '',
    sort: true,
    filter: true,
    width: '10rem',
  },
  {
    name: 'acc_code',
    label: 'Acc Code',
    renderValue: (item) => item.account_id?.acc_code || '',
    sort: true,
    filter: true,
    width: '8rem',
  },
  {
    name: 'amount',
    label: 'Topup Amount',
    renderValue: (item) => item.money_topup ? item.money_topup?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) : '',
    sort: true,
    filter: true,
    width: '8rem',
  },
  {
    name: 'status',
    label: 'Topup Done',
    renderValue: (item) => <Checkbox checked={item.status === 'done'} color="primary" disabled disableRipple />,
    sort: true,
    filter: true,
    width: '8rem',
  },
  {
    name: 'request_id',
    label: 'Request ID',
    sort: true,
    filter: true,
    width: '10rem',
    renderValue: (item) => item.request_id ? `Request ${item.request_id}` : ''
  },
  {
    name: 'note',
    label: 'Note',
    sort: true,
    filter: true,
    width: '10rem',
  },
])

export const formFields = [
  {
    type: 'text',
    disabled: true,
    name: 'done_date',
    label: 'Time Topup Acc',
    renderValue: (item) => item['done_date'] ? moment(item['done_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    sort: true,
    width: '11rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'acc_name',
    label: 'Acc Name',
    renderValue: (item) => item.account_id?.acc_pool_id?.acc_name || '',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.account_id?.acc_pool_id?.acc_id || '',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'select',
    name: 'account_id',
    label: 'Acc Code',
    disabled: true,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/accounts', { search: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    renderValue: (item) => item.account_id,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    name: 'money_topup',
    label: 'Topup Amount',
    sort: true,
    filter: true,
    width: '6rem',
    disabled: true
  },
  {
    type: 'checkbox',
    name: 'status',
    label: 'Topup Done',
    checked: (item) => item.status == 'done' || item.status == true,
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'request_id',
    label: 'Request ID',
    disabled: true,
    sort: true,
    filter: true,
    width: '6rem',
    renderValue: (item) => item.request_id ? `Request ${item.request_id}` : ''
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
    sort: true,
    filter: true,
    width: '6rem',
    disabled: true
  },
]