import React, { Component } from 'react'
import { listFields, formFields } from './constant';
import { ResourceList, DrawerForm, ModalForm } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import { TextField, Box } from '@mui/material';
import { request } from '../../../_services_'
import { toastr } from '../../../_helpers_'

class TeamPlatformList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: 'view',
      objectId: null,
      open: false
    }
  }

  newPlatform = (ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New Platform',
      data: {},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Code
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="code"
              onChange={handleChange}
              value={submitData.values?.code}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.code ? true : false}
              helperText={submitData.errors?.code ? submitData.errors.code[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="title"
              onChange={handleChange}
              value={submitData.values?.title}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.title ? true : false}
              helperText={submitData.errors?.title ? submitData.errors.title[0] : ''}
            />
          </div>
        </div>
      ),
      action: {
        schema: {
          code: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          title: {
            presence: { allowEmpty: false, message: '^Required' }
          }
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.post('/api/v1/team_platforms', submitData.values).then(res => {
              if (res.data.success) {
                const items = Object.assign([], ctx.state.items)
                items.push(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
                resolve(true);
                toastr.success("Create platform successful")
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })

        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'type_acc') {
        query[key] = formState.changes[key].filter(e => e.name != '').map(e => e.name).join(', ')
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/team_platform/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render () {
    const { navigate } = this.props;
    const { mode, objectId } = this.state;
    return (
      <>
        <ResourceList 
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/api/v1/team_platforms"
          titlePage="Drebest | Platform"
          title="Platform"
          fields={listFields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, mode: 'view', objectId: item.id })}
          actions={[
            {
              text: 'New Platform',
              icon: <AddIcon />,
              variant: 'contained',
              visible: true,
              action: (ctx) => this.newPlatform(ctx)
            }
          ]}
        />
        <DrawerForm
          fields={formFields}
          mode={this.state.mode}
          open={this.state.open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={this.state.objectId}
          onLoadData={(public_id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/team_platform/${public_id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Platform Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default TeamPlatformList;