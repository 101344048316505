export function openSnackbar(state = {}, action) {
  switch (action.type) {
    case 'OPEN_SNACKBAR': {
      return {
        data: action
      }
    }
    default:
      return state
  }
}