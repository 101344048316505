import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList, DrawerForm, ModalForm } from '../../../components';
import { fields, formfields, filters } from './constants';
import { request } from '../../../_services_';
import { Box } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { toastr } from '../../../_helpers_';
import { customStyles, errorStyles, handleInputChange, loadOptions } from '../../../utils';

class PayoneerTransactionList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  getRibbon = (item) => {
    if (item.status == 'success') {
      return {
        text: item.status?.toUpperCase(),
        textColor: '#FFF',
        bgColor: '#00AB55',
      }
    }
    return {
      text: item.status?.toUpperCase(),
      textColor: '#B78103',
      bgColor: '#FFC107',
    }
  }

  addToTeam = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: "Topup To Team",
      data: {
        id: formState.values.id
      },
      customView: (submitData, handleChange) => (
        <div className='p-3'>
          <Box fontSize={16} fontWeight="bold">Team</Box>
          <AsyncSelect
            className="MuiFormControl-marginDense"
            cacheOptions
            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
              return new Promise(resolve => {
                request.get('/admin/v1/teams', {search: inputValue}).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items)
                  }
                })
              })
            })}
            defaultOptions
            onInputChange={handleInputChange}
            isSearchable
            name={"team_id"}
            onChange={(value) => {
              const event = {
                target: {
                  name: 'team_id',
                  value
                }
              }
              handleChange(event)
            }}
            placeholder={"Team"}
            menuPortalTarget={document.body}
            getOptionLabel={({ team_search }) => team_search}
            getOptionValue={({ team_id }) => team_id }
            valueKey={"team_id"}
            value={submitData?.values.team_id || null}
            styles={submitData.errors?.team_id ? errorStyles : customStyles}
          />
          {submitData.errors.team_id && <span className='field-error'>{submitData.errors.team_id[0]}</span>}
        </div>
      ),
      action: {
        title: 'Apply',
        schema: {
          team_id: {
            presence: {allowEmpty: false, message: '^Required'}
          }
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.put(`/admin/v1/payoneer_transaction/${submitData.values.id}`, {team_id: submitData.values.team_id.team_id}).then(res => {
              if (res.data.success) {
                const form = Object.assign({}, formState)
                formState.values['team_id'] = submitData.values.team_id
                formState.values['status'] = 'success'
                ctx.setState({ formState: form })
                this.setState({ reload: true })
                toastr.success('Successful')
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleTopup = (item, ctx) => {
    ModalForm.instance.current.openForm({
      data: {
        id: item.id
      },
      title: 'Team topup',
      customView: (submitData, handleChange) => (
        <div className='p-3'>
          <Box fontSize={15} fontWeight='bold'>
            Team
          </Box>
          <AsyncSelect
            className="MuiFormControl-marginDense"
            cacheOptions
            loadOptions={(inputValue) => {
              return new Promise(resolve => {
                request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items)
                  }
                })
              })
            }}
            defaultOptions
            onInputChange={(newValue) => newValue}
            isSearchable
            name={"team_id"}
            onChange={(value) => {
              var e = {
                target: {
                  name: "team_id",
                  value
                },
              };
              handleChange(e)
            }}
            placeholder={"Team"}
            menuPortalTarget={document.body}
            getOptionLabel={({ team_search }) => team_search}
            getOptionValue={({ team_id }) => team_id}
            valueKey={"team_id"}
            value={submitData.values.team_id}
            styles={submitData.errors?.team_id ? errorStyles : customStyles}
          />
          {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id[0]}</span>}
        </div>
      ),
      action: {
        schema: {
          team_id: { presence: { allowEmpty: false, message: '^Required' } }
        },
        titleAction: 'Confirm',
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.put(`/admin/v1/payoneer_transaction/${submitData.values.id}`, {team_id: submitData.values.team_id.team_id}).then(res => {
              if (res.data.success) {
                const items = ctx.state.items.map(e => e.id == item.id ? {...e, team_id: submitData.values.team_id, status: 'success'} : e)
                ctx.setState({ items })
                this.setState({ reload: true })
                toastr.success('Successful')
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  render() {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/payoneer_transactions"
          titlePage="Drebest | Payoneer Transactions"
          title="Payoneer Transactions"
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id, mode: 'view' })}
          // defaultFilters={{}}
          actionRow={{
            title: 'Topup',
            action: (item, ctx) => this.handleTopup(item, ctx),
            visibled: (item) => item.status != 'success'
          }}
          filters={filters}
        />
        <DrawerForm 
          fields={formfields}
          ribbon={(item) => this.getRibbon(item)}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/payoneer_transaction/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Transaction Detail"
          actions={[
            {
              text: 'Topup',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.addToTeam(ctx, formState),
              visible: (item) => item.status != 'success'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(PayoneerTransactionList)