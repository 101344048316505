import React, { Component } from 'react'
import { listRaiBmFields } from './fields';
import { ResourceList } from '../../../components';

class RaiBMSharing extends Component {

  render() {
    const { navigate } = this.props;
    return (
      <ResourceList
        route="/admin/v1/requests"
        titlePage="Drebest | RAI BM Sharing"
        title="RAI BM Sharing"
        fields={listRaiBmFields}
        valueKey="request_id"
        onClickRow={(item) => navigate(`/app3/rai_bm_sharing/${item.request_id}`)}
        defaultFilters={
          {
            codes: JSON.stringify(['B5']),
            acc_type: 'RAI'
          }
        }
      />
    )
  }

}

export default RaiBMSharing;