import React, { Component } from 'react'
import { listBrFields, listBrNumberFormFields } from './fields';
import { ModalForm, ResourceList,DrawerForm, Uploader } from '../../../components'
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_'
import {
  TextField, Box, 
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';

class BrNumberList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  newBrNumber = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Batch <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="batch"
              type="text"
              onChange={handleChange}
              value={submitData.values.batch}
              placeholder="Batch..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData?.errors?.batch?true:false}
              helperText={submitData.errors?.batch ? submitData.errors.batch[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Name <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="name"
              type="text"
              onChange={handleChange}
              value={submitData.values.name}
              placeholder="BR Name..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData?.errors?.name?true:false}
              helperText={submitData.errors?.name ? submitData.errors.name[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
             BR Unified Social Credit Number <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="license_no"
              type="text"
              onChange={handleChange}
              value={submitData.values.license_no}
              placeholder="BR Unified Social Credit Number..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
              error={submitData?.errors?.license_no?true:false}
              helperText={submitData.errors?.license_no ? submitData.errors.license_no[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Business Name <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="chinese_name"
              value={submitData.values.chinese_name}
              onChange={handleChange}
              placeholder="BR Business Name..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.chinese_name?true:false}
              helperText={submitData.errors?.chinese_name ? submitData.errors.chinese_name[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Business Name in English <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="english_name"
              value={submitData.values.english_name}
              onChange={handleChange}
              placeholder="BR Business Name in English..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.english_name?true:false}
              helperText={submitData.errors?.english_name ? submitData.errors.english_name[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Registered Business Address <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="address"
              value={submitData.values.address}
              onChange={handleChange}
              placeholder="BR Registered Business Address..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.address?true:false}
              helperText={submitData.errors?.address ? submitData.errors.address[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Address (Eng) <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="address_eng"
              value={submitData.values.address_eng}
              onChange={handleChange}
              placeholder="BR Address (Eng)..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.address_eng?true:false}
              helperText={submitData.errors?.address_eng ? submitData.errors.address_eng[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Registered Postal Code <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              type='number'
              margin='dense'
              name="postal_code"
              value={submitData.values.postal_code}
              onChange={handleChange}
              placeholder="BR Registered Postal Code..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.postal_code?true:false}
              helperText={submitData.errors?.postal_code ? submitData.errors.postal_code[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR Image Link <span style={{ color: 'red' }}>*</span>
            </Box>
            {/* <Uploader
              className={submitData.errors?.files ? 'form-upload-error' : ''}
              multiple={true}
              accept='image/*'
              uploadOnPicked
              handleChange={handleChange}
              submitData={submitData}
            /> */}
            <TextField 
              fullWidth
              margin='dense'
              name="image_link"
              value={submitData.values.image_link}
              onChange={handleChange}
              placeholder="BR Image Link..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData?.errors?.image_link?true:false}
              helperText={submitData.errors?.image_link ? submitData.errors.image_link[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              BR City (manual)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="city_manual"
              value={submitData.values.city_manual}
              onChange={handleChange}
              placeholder="BR City (manual)..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              // error={submitData?.errors?.city_manual?true:false}
              // helperText={submitData.errors?.city_manual ? submitData.errors.city_manual[0] : ''}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          batch: {presence: {message: '^Required'}},
          name: {presence: {message: '^Required'}},
          license_no: {presence: {message: '^Required'}},
          chinese_name: {presence: {message: '^Required'}},
          english_name: {presence: {message: '^Required'}},
          address: {presence: {message: '^Required'}},
          address_eng: {presence: {message: '^Required'}},
          // city_manual: {presence: {message: '^Required'}},
          postal_code: {presence: {message: '^Required'}},
          image_link: {presence: {message: '^Required'}}
        },
        onAction: (submitData) => {
          const query = {}
          const allKeys = Object.keys(submitData.values)
          allKeys.map(key => {
            if (key == 'files') {
              query['image_link'] = submitData.values.files[0].url
            }  else {
              query[key] = submitData.values[key]
            }
          })
          return new Promise((resolve) => {
            request.post('/admin/v1/br_numbers', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'files') {
        query['image_link'] = formState.values.files[0].url
      }  else {
        query[key] = formState.values[key]
      }
    })

    request.put(`/admin/v1/br_number/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/br_numbers"
          titlePage="Drebest | BR Number"
          title="BR Number"
          fields={listBrFields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newBrNumber(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={listBrNumberFormFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/admin/v1/br_number/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="BR Number Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default BrNumberList;