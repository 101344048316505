import moment from 'moment'
import { Label } from '../../../components'

export const fields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => moment(item.create_date).format('YYYY-MM-DD HH:mm:ss'),
    width: '10rem'
  },
  {
    name: 'email',
    label: 'Email',
    width: '10rem'
  },
  {
    name: 'fullname',
    label: 'Fullname',
    width: '10rem'
  },
  {
    name: 'shortname',
    label: 'Shortname',
    width: '10rem'
  },
  {
    name: 'active',
    label: 'Status',
    width: '6rem',
    renderValue: (item) => <Label variant="ghost" color={item.active ? 'success' : 'error'}>{item.active ? 'Active' : 'Inactive'}</Label>
  },
]