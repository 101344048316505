import React, { useState } from 'react'
import { listFields } from './fields';
import { ResourceList, ModalForm } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange } from '../../../utils';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

const NeedFudList = (props) => {
  
  const { navigate } = props;

  return (
    <ResourceList 
      route="/api/v1/users"
      titlePage="Drebest | 1.3 Team need FUD (DB2)"
      title="1.3 Team need FUD (DB2)"
      fields={listFields}
      valueKey="uid"
      onClickRow={(item) => navigate(`/app1/need_fud/${item.public_id}`)}
    />
  )
}

export default NeedFudList;