import { TextField, styled } from '@mui/material';

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#000",
    backgroundColor: "#ECECEC"
  },
  "& input.Mui-disabled": {
    'WebkitTextFillColor': 'black',
  }
});

export default CustomTextField