import { request } from '../../../_services_';
import { ImagePreview } from '../../../components';
import icon1 from '../../../assets/icon1.png'
import icon2 from '../../../assets/icon2.png'
import icon3 from '../../../assets/icon3.png'
import icon4 from '../../../assets/icon4.png'
import icon5 from '../../../assets/icon5.png'
import icon6 from '../../../assets/icon6.png'
import icon7 from '../../../assets/icon7.png'
import icon8 from '../../../assets/icon8.png'
import icon9 from '../../../assets/icon9.png'
import moment from 'moment';
import { capitalizeStr } from 'src/utils';
import { ButtonDropdown } from '../../../components';
export const statusRequest = [
  // { id: 'new', name: 'New' },
  { id: 'processing', name: 'Processing' },
  // { id: 'done', name: 'Done' },
  { id: 'cancel', name: 'Cancel' },
]
import {
  Tooltip,
} from '@mui/material';


import { IconFileImport, IconCircleCheck, IconClipboardCheck, IconBrandMessenger } from '@tabler/icons';

export const listItems = [
  {
    id: 2,
    title: '9.2 Request All',
    content: 'Request All',
    route: 'app3/9b_request_all',
    icon: <img src={icon2} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 3,
    title: '9.2 Request C1 C2 B3',
    content: 'Duyệt Content & xử lý Acc disable',
    route: 'app3/9b_request_c',
    icon: <img src={icon3} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 4,
    title: '9.2 Request B5',
    content: 'Các request liên quan đến BM',
    route: 'app3/9b_request_b5',
    icon: <img src={icon4} style={{ width: 70, height: 70 }} />,
  },
  {
    id: 5,
    title: '9.2 Request other',
    content: 'Các request khác',
    route: 'app3/9b_request_other',
    icon: <img src={icon5} style={{ width: 70, height: 70 }} />,
  },
  // {
  //   id: 6,
  //   title: 'RAI Acc Disable',
  //   content: 'Check Disable Acc',
  //   route: 'app3/rai_acc_disable',
  //   icon: <img src={icon6} style={{ width: 70, height: 70 }} />,
  // },
  // {
  //   id: 7,
  //   title: 'RAC Acc Disable',
  //   content: 'Check Disable Acc',
  //   route: 'app3/rac_acc',
  //   icon: <img src={icon7} style={{ width: 70, height: 70 }} />,
  // },
  // {
  //   id: 8,
  //   title: 'RAI BM Sharing',
  //   content: 'Check RAI Processing',
  //   route: 'app3/rai_bm_sharing',
  //   icon: <img src={icon8} style={{ width: 70, height: 70 }} />,
  // },
  // {
  //   id: 9,
  //   title: 'RAC BM Sharing',
  //   content: 'Check RAC Processing',
  //   route: 'app3/rac_bm_sharing',
  //   icon: <img src={icon9} style={{ width: 70, height: 70 }} />,
  // }
]


export const listAllFields = [
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item?.name}`,
    sort: true,
    filter: true,

  },
  {
    name: 'code',
    label: 'Acc code',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code',
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_id',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1',
  },
  {
    name: 'request_type_id',
    label: 'Request Type',
    renderValue: (item) => `${item?.request_type_id?.name}`,
    sort: true,
    sortName: 'accounts.request_types.name',
    filter: true,
    filterName: 'accounts.request_types.name',
  },
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort: true,

  },
  {
    name: 'status',
    label: 'Status final',
    sort: true,
  },
  {
    name: 'money_topup_to_acc',
    label: 'Money topup to Acc',
    sort: true,
  },
  {
    name: 'other_note',
    label: 'Other Note (Ghi chú khác)',
    sort: true,
    filter: true,
  },
  {
    name: 'Via',
    label: 'Via Name on BM (show)',
    sort: true,
  },
  {
    name: 'email',
    label: 'Email Address',
    sort: true,
    filter: true,
  },
  {
    name: 'Ads',
    label: 'Ads Manager',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Acc Quality',
    sort: true,
  },
  {
    name: 'BM',
    label: 'BM ID',
    sort: true,
  },
  {
    name: 'BM',
    label: 'BM Name',
    sort: true,
  },
  {
    name: 'BM',
    label: 'BM register first time?',
    sort: true,
  },
  {
    name: 'BM',
    label: 'BM invite link (Admin Role)',
    sort: true,
  },
  {
    name: 'BM',
    label: 'Send image through:',
    sort: true,
  },
  {
    name: 'BM',
    label: 'BM 2FA Image (upload)',
    sort: true,
  },
  {
    name: 'Link',
    label: 'Link content (show)',
    sort: true,
  },
  {
    name: 'Seller',
    label: 'Team Seller Content',
    sort: true,
  },
  {
    name: 'Domain',
    sort: true,
    label: 'Domain Verification Method',
  },
  {
    name: 'Pixel',
    label: 'Pixel ID',
    sort: true,
  },
  {
    name: 'Pixel',
    label: 'Pixel Name',
    sort: true,
  },
  {
    name: 'Pixel',
    label: 'Pixel guife (show)',
    sort: true,
  },
  {
    name: 'Share',
    label: 'Share the right to manage Pixel for Via',
    sort: true,
  },
  {
    name: 'Rules',
    label: 'Rules for refund (show)',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Deleted all campaigns?',
    sort: true,
  },
  {
    name: 'Post',
    label: 'Có tài khoản bị khoá và có Ad Post chạy dở?',
    sort: true,
  },
  {
    name: 'Post',
    label: 'Phương án với Ad Post chạy dở trên Acc bị khoá?',
    sort: true,
  },
  {
    name: 'Paypal',
    label: 'Paypal acc',
    sort: true,
  },
  {
    name: 'Bank',
    label: 'Bank Vietnam',
    sort: true,
  },
  {
    name: 'Bank',
    label: 'Số tài khoản',
    sort: true,
  },
  {
    name: 'Bank',
    label: 'Chủ tài khoản',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Acc Type',
    sort: true,
  },
  {
    name: 'Screen',
    label: 'Screenshort Link',
    sort: true,
  },
  {
    name: 'Screen',
    label: 'Duyệt hồ sơ',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Related PB policy',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'BM Share button',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Partner Code',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Wallet remaining (after)',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Countif Time',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Clear Status',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Acc ID',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Status HP',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Acc Status',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Nguyên nhân dừng hợp tác',
    sort: true,
  },
  {
    name: 'Acc',
    label: 'Acc name auto',
    sort: true,
  },
]


export const listC1C2Fields = (ctx) => ([
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort: true,
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item?.name}`,
    sort: true,
    filter: true,
  },
  {
    name: 'request_type_id',
    label: 'Request Type Code',
    renderValue: (item) => `${item?.request_type_id?.code}`,
    sort: true,
    sortName: 'request_types.code',
    filter: true,
    filterName: 'request_types.code'
  },
  {
    name: 'code',
    label: 'Acc code',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code'
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_search',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1'
  },
  {
    name: 'Seller',
    label: 'Team Seller Content',
    sort: true,
  }, {
    name: 'Acc',
    label: 'Related PB policy',
    sort: true,
  },

  {
    name: 'status',
    label: 'Status final',
    sort: true,
    renderValue: (item) => item.status?.replace(/^\w/, (c) => c.toUpperCase())
  },
  {
    name: 'link_content',
    label: '',
    renderValue: (item) =>
      <Tooltip title="View Link Content File">
        <div>
          <IconFileImport size={24} stroke={2}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              ctx.viewLinkContent(item)
            }}
          />
        </div>
      </Tooltip>,
    width: '3rem',
  },
  {
    name: 'duyet_ho_so',
    label: '',
    renderValue: (item) =>
      item?.status?.toLowerCase() !== 'new' ? null :
        <Tooltip title="Duyệt hồ sơ">
          <div>
            <IconCircleCheck size={24} stroke={2}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (window.confirm("Are you sure?")) {
                  ctx.preliminaryApprove(item, ctx)
                }
              }}
            />
          </div>
        </Tooltip>,
    width: '3rem',
  },
  {
    name: 'duyet_chot',
    label: '',
    renderValue: (item) => item?.status?.toLowerCase() !== 'processing' ? null :
      <Tooltip title="Duyệt chốt">
        <div>
          <IconClipboardCheck size={24} stroke={2}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (window.confirm("Are you sure?")) {
                ctx.approveFinal(item)
              }
            }}
          />
        </div>
      </Tooltip>,
    width: '3rem',
  },
  {
    name: 'other_note',
    label: 'Other Note (Ghi chú khác)',
    sort: true,
    filter: true,
    filterName: 'other_note@ilike'
  },
  {
    name: 'request_type_id',
    label: 'Request Type',
    renderValue: (item) => `${item?.request_type_id?.name}`,
    sort: true,
    sortName: 'request_types.name',
    filter: true,
    filterName: 'request_types.name'
  },
  // {
  //   name: 'action',
  //   label: 'Action',
  //   type: 'action',
  //   width: '6rem',
  //   renderValue: (item) => (
  //     <ButtonDropdown
  //       actions={[
  //         {
  //           title: 'View Link Content File',
  //           onClick: () => ctx.viewLinkContent(item),
  //           icon: <IconFileImport size={24} stroke={2} />
  //         },
  //         // {
  //         //   title: 'View Team Mess Group',
  //         //   onClick: () => ctx.viewTeamMessGroup(item),
  //         //   icon: <IconBrandMessenger size={24} stroke={2} />
  //         // }
  //       ]}
  //     />
  //   )
  // }
])

export const listB5Fields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort: true,
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item?.name}`,
    sort: true,
    filter: true
  },
  {
    name: 'code',
    label: 'Acc code',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code'

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },

  {
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_search',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1'

  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.acc_id || ''}` : '',
    sort: true,
    sortName: 'accounts.acc_id',
    filter: true,
    filterName: 'accounts.acc_id'
  },
  {
    name: 'request_type_id',
    label: 'Request Type',
    renderValue: (item) => `${item?.request_type_id?.name || ''}`,
    sort: true,
    sortName: 'request_types.name',
    filter: true,
    filterName: 'request_types.name'
  },

  {
    name: 'status',
    label: 'Status final',
    sort: true,

  },
  {
    name: 'other_note',
    label: 'Other Note (Ghi chú khác)',
    sort: true,
    filter: true,
    filterName: 'other_note@ilike'


  },
  {
    name: 'Seller',
    label: 'BM Name',
    sort: true,

  }, {
    name: 'Acc',
    label: 'BM Admin Email',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'BM invite link (Admin Role)',
    sort: true,

  },
  {
    name: 'Acc',
    sort: true,

    label: 'BM Admin Email',
  },
  {
    name: 'Acc',
    label: 'BM Admin Email',
    sort: true,

  },
  {
    name: 'BM',
    label: 'Send image through:',
    sort: true,

  },
  {
    name: 'BM',
    label: 'BM 2FA Image (upload)',
    sort: true,

  },
  {
    name: 'Link',
    label: 'BM 2FA Image Link',
    sort: true,

  },
  {
    name: 'Seller',
    label: 'Status HP',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'Team ID manual',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'Acc Status',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'Nguyên nhân dừng hợp tác',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'Acc name auto',
    sort: true,

  }
]

export const listOtherFields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    sort: true,

  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => `${item?.name}`,
    sort: true,
    filter: true,

  },

  {
    name: 'code',
    label: 'Acc code',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code',

  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_search',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1',
  },
  {
    name: 'request_type_id',
    label: 'Request Type',
    renderValue: (item) => `${item?.request_type_id?.name || ''}`,
    sort: true,
    sortName: 'request_types.name',
    filter: true,
    filterName: 'request_types.name',
  },

  {
    name: 'status',
    label: 'Status final',
    sort: true,
  },
  {
    name: 'other_note',
    label: 'Other Note (Ghi chú khác)',
    sort: true,
    filter: true,
  },
  {
    name: 'request_type_id',
    label: 'Request Type code',
    renderValue: (item) => `${item?.request_type_id?.code}`,
    sort: true,
    sortName: 'request_types.code',
    filter: true,
    filterName: 'request_types.code'

  }, {
    name: 'Acc',
    label: 'Email Address',
    sort: true,

  },
  {
    name: 'Domain',
    label: 'Domain Verification Method',
    sort: true,

  },
  {
    name: 'Pixel',
    label: 'Pixel ID',
    sort: true,

  },
  {
    name: 'Pixel',
    label: 'Pixel Name',
    sort: true,

  },
  {
    name: 'Pixel',
    label: 'Pixel guife (show)',
    sort: true,

  },
  {
    name: 'Share',
    label: 'Share the right to manage Pixel for Via',
    sort: true,

  },
  {
    name: 'Rules',
    label: 'Rules for refund (show)',
    sort: true,

  },
  {
    name: 'Acc',
    label: 'Deleted all campaigns?',
    sort: true,

  },
  {
    name: 'Post',
    label: 'Có tài khoản bị khoá và có Ad Post chạy dở?',
    sort: true,

  },
  {
    name: 'Post',
    label: 'Phương án với Ad Post chạy dở trên Acc bị khoá?',
    sort: true,

  },
  {
    name: 'Paypal',
    label: 'Paypal acc',
    sort: true,

  },
  {
    name: 'Bank',
    label: 'Bank Vietnam',
    sort: true,

  },
  {
    name: 'Bank',
    label: 'Số tài khoản',
    sort: true,

  },
  {
    name: 'Bank',
    label: 'Chủ tài khoản',
    sort: true,

  },


]



export const formFields = [
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'request_id',
    label: 'Request ID',
    renderValue: (item) => `Request ${item?.request_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'code',
    label: 'Acc code',
    disabled: true,
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',

  },
  {
    type: 'text',
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id.team_id?.team_search} ${item?.account_id.team_id?.owner_name}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_name',
    label: 'Acc name',
    renderValue: (item) => item?.account_id?.name || '',
    disabled: true
  },
  {
    type: 'text',
    name: 'request_type_id',
    label: 'Request type',
    disabled: true,
    renderValue: (item) => `${item?.request_type_id?.name}`,

  },
  {
    type: 'select',
    name: 'status',
    label: 'Status final',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        resolve(statusRequest)
      })
    },
    renderValue: (item) => item.status ? statusRequest.find(e => e.id == item.status) : null,
    labelKey: 'name',
    valueKey: 'id'
  },
  {
    type: 'text',
    name: 'team_content_link',
    label: 'Teams content link Googlesheet',
    disabled: true
  },
  {
    type: 'number',
    name: 'money_topup_to_acc',
    label: 'Money topup to Acc',
    disabled: true
  },
  {
    type: 'text',
    name: 'bm_id',
    label: 'BM ID',
    disabled: true

  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name',
    disabled: true
  },

  {
    type: 'checkbox',
    name: 'delete_all_campaigns',
    label: 'Delete all campaign?',
    checked: (item) => item?.delete_all_campaigns ? true : false
  },
  {
    type: 'checkbox',
    name: 'have_account_block_or_ads_active',
    label: 'Có TK bị khoá và Ad post chạy dở?',
    checked: (item) => item?.have_account_block_or_ads_active ? true : false

  },
  {
    type: 'text',
    name: 'plan_ads_active',
    label: 'Phương án với Ad post chạy dở trên Acc bị khoá?',
  },
  {
    name: 'bank__id',
    label: 'Bank Vietnam',
    type: 'text',
    renderValue: (item) => item?.bank__id?.name || '',
    disabled: true
  },
  {
    type: 'text',
    name: 'reason_stop',
    label: 'Nguyên nhân dừng hợp tác',
  }

]




export const listRaiFields = [
  {
    name: 'code',
    label: 'Acc code',
    renderValue: (item) => `${item?.code}`,
    sort: true,
    filter: true,
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.team_id ? `${item?.team_id?.team_search || ''} ${item?.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'teams.team_search',
    filter: true,
    filterName: 'teams.team_search|teams.owner_name*1'
  },
  {
    name: 'name',
    label: 'Acc name',
    sort: true,
    filter: true,
  },
  {
    name: 'keep_deposit',
    label: 'Keep Deposite',
    sort: true,
  },
  {
    name: 'using',
    label: 'Using',
    sort: true,
  },
  {
    name: 'disable_date',
    label: 'Disabled time',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Causes of disabled Acc',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Đã check',
    sort: true,
  },

  {
    name: 'time_zone',
    label: 'Content gây khoá',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Cần thông báo phạt cọc',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Time phạt cọc',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Ads Manager',
    sort: true,
  },

  {
    name: 'time_zone',
    label: 'Payment Setting',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Rút tiền',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Dis forever',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Via People',
    sort: true,
  },
  {
    name: 'bm_name',
    label: 'BM lastest',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Spent 1 Total',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Remaining',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Spending Limit 2 - Cleared (now)',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Spend 2 - Cleared (now)',
    sort: true,
  },
  {
    name: 'status',
    label: 'Acc Status auto',
    sort: true,
  },
  {
    name: 'partner_code',
    label: 'Partner code',
    sort: true,
    filter: true,
  },
  {
    name: 'bm_id',
    label: 'BM ID lastest',
    sort: true,
    filter: true,
  },
  {
    name: 'time_zone',
    label: 'Spent Limit 1',
    sort: true,
  }
]


export const listRACFields = [

  {
    name: 'time_zone',
    label: 'Partner code',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'RAC ID',
    sort: true,
  },
  {
    name: 'time_zone',
    label: 'Team_Seller Username',
  },
  {
    name: 'time_zone',
    label: 'Keep Deposite',
  },
  {
    name: 'time_zone',
    label: 'Status (manual)',
  },
  {
    name: 'time_zone',
    label: 'Disabled tyoe',
  },
  {
    name: 'time_zone',
    label: 'Disabled reason',
  },
  {
    name: 'time_zone',
    label: 'Permanently Disabled',
  },
  {
    name: 'time_zone',
    label: 'Disabled date',
  },
  {
    name: 'time_zone',
    label: 'Note',
  },
  {
    name: 'time_zone',
    label: 'Giải pháp',
  },
  {
    name: 'time_zone',
    label: 'BM ID (preventive)',
  },
  {
    name: 'time_zone',
    label: 'Acc Timezone (manual)',
  },
  {
    name: 'time_zone',
    label: 'Stop',
  },
  {
    name: 'time_zone',
    label: '9-Thu hồi',
  },
  {
    name: 'time_zone',
    label: 'Manual Acc Name',
  },
  {
    name: 'time_zone',
    label: 'Cần check số Spent trên FB',
  },

  {
    name: 'time_zone',
    label: 'Page 1 - Name Link',
  },
  {
    name: 'time_zone',
    label: 'Page 2 - Name Link',
  },
  {
    name: 'time_zone',
    label: 'Spent 1 (before)_87',
  },
  {
    name: 'time_zone',
    label: 'Spent by Panda',
  },
  {
    name: 'time_zone',
    label: 'Chênh Tool & PD',
  },
  {
    name: 'time_zone',
    label: 'Prefix',
  },
  {
    name: 'time_zone',
    label: 'Acc ID',
  },
  {
    name: 'time_zone',
    label: 'Status',
  }
]

export const listRacBmFields = [
  {
    name: 'request_id',
    label: 'Tranfer ID',
    sort: true,
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => item?.request_id ? `${item?.name}` : '',
    sort: true,
    filter: true,
  },
  {
    name: 'code',
    label: 'RAC ID 2 (manual)',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code'
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'account_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_search',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1'
  },
  {
    name: 'bm_id',
    label: 'BM ID manual',
    sort: true,
    filter: true,
  },
  {
    name: 'tranfer_id',
    label: 'Name BM 2 (manual)',
    sort: true,
  },
  {
    name: 'bm2_screenshot',
    label: 'BM 2 direct Screenshot',
    sort: true,
    renderValue: (item) => item.bm2_screenshot ?
      <ImagePreview url={item.bm2_screenshot} /> : ''
  },
  {
    name: 'remove_from_all_bm',
    label: 'Remove from All BM (except BM2)',
    sort: true,
  },
  {
    name: 'remove_from_bm',
    label: 'Remove from BM ID',
    sort: true,
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
  },
]

export const listRaiBmFields = [
  {
    name: 'request_id',
    label: 'Tranfer ID',
    sort: true,
  },
  {
    name: 'name',
    label: 'Request ID',
    renderValue: (item) => item?.request_id ? `${item?.name}` : '',
    sort: true,
    filter: true,
  },
  {
    name: 'account_id',
    label: 'RAI ID 2 (manual)',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
    sort: true,
    sortName: 'accounts.code',
    filter: true,
    filterName: 'accounts.code'
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.account_id?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.account_id?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'accounts.teams.team_id',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    sort: true,
    sortName: 'accounts.teams.team_search',
    filter: true,
    filterName: 'accounts.teams.team_search|accounts.teams.owner_name*1'
  },
  {
    name: 'bm_id',
    label: 'BM ID manual',
    sort: true,
    filter: true,


  },
  {
    name: 'bm_name_2',
    label: 'Name BM 2 (manual)',
    sort: true,
    filter: true,


  },
  {
    name: 'bm2_screenshot',
    label: 'BM 2 direct Screenshot link',
    sort: true,
    renderValue: (item) => item.bm2_screenshot ?
      <ImagePreview url={item.bm2_screenshot} /> : ''

  },
  {
    name: 'remove_from_all_bm',
    label: 'Remove from All BM (except BM2)',
    sort: true,

  },
  {
    name: 'remove_from_bm',
    label: 'Remove from BM ID',
    sort: true,

  },
  {
    name: 'status',
    label: 'Status',
    sort: true,

  },
]


export const formB5Fields = [
  {
    type: 'select',
    name: 'status',
    label: 'Status final',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        resolve(statusRequest)
      })
    },
    onChange: (e, ctx) => {
      var event = {
        target: {
          name: 'status',
          value: e.target.value.id
        }
      }
      ctx.handleChange(event)
    },
    renderValue: (item) => item.status ? (statusRequest.find(e => e.id == item?.status) || { id: item.status, name: capitalizeStr(item.status) }) : null,
    labelKey: 'name',
    valueKey: 'id'
  },
  {
    type: 'text',
    name: 'name',
    label: 'Acc name auto',
    disabled: true,
    renderValue: (item) => item?.account_id ? `${item?.account_id?.team_id?.team_search} ${item?.account_id?.code}` : '',
  },
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY hh:mm:ss A') : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'request_id',
    label: 'Request ID',
    renderValue: (item) => `Request ${item?.request_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'code',
    label: 'Acc code',
    disabled: true,
    renderValue: (item) => item?.account_id ? `${item?.account_id?.code}` : '',
  },
  {
    type: 'text',
    name: 'Email',
    label: 'Team_Seller Username',
    renderValue: (item) => item?.account_id ? `${item?.account_id.team_id?.team_search || ''} ${item?.account_id.team_id?.owner_name || ''}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item?.account_id?.acc_id || '',
    disabled: true
  },
  {
    type: 'text',
    name: 'request_type_id',
    label: 'Request type',
    disabled: true,
    renderValue: (item) => `${item?.request_type_id?.name}`,

  },

  {
    type: 'text',
    name: 'bm_id',
    label: 'BM ID',
    disabled: true

  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name',
    disabled: true
  },

  {
    type: 'text',
    name: 'bm_admin_email',
    label: 'BM Admin Email',
    disabled: true
  },
  {
    type: 'text',
    name: 'send_image_through',
    label: 'Send image through',
    disabled: true
  },
  {
    type: 'text',
    name: 'bm_2fa_image_link',
    label: 'BM 2FA Image Link',
  },
  {
    type: 'text',
    name: 'type_acc',
    label: 'Acc Type',
    disabled: true,
    renderValue: (item) => item?.account_id?.type_acc || '',
  },


]



