import React, { Component } from "react";
import { withStyles } from '@mui/styles';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Drawer, Button, IconButton, Grid, Box
} from '@mui/material';
import { connect } from "react-redux";
import { IconX } from '@tabler/icons'
import moment from 'moment'
import clsx from 'clsx'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
const useStyles = (theme) => ({
  titleTable: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#6CA754",
    padding: theme.spacing(1),
    color: "#fff",
  },
  seperator: {
    height: 10,
  },
  field: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "transparent",
    padding: "5px 0",
    borderRadius: 5,
    overflow: "visible"
  },
  textField: {
    width: "90%",
  },
  checkBoxContainer: {
    padding: "0 10px",
    backgroundColor: "#fff",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  buttonClear: {
    backgroundColor: "#eeeeee",
    fontWeight: "bold",
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    backgroundColor: "#6CA754",
    color: "#ffffff",
  },
  labelRoot: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 13,
  },
});



class Filter extends Component {
  constructor(props) {
    super(props);
    var listFilter = this.props.fields.filter(x => x.filter)
    var firstFilter = listFilter.length > 0 ? (listFilter[0].filterName || listFilter[0].name) : null
    this.state = {
      values: {
        filter: this.props.filters?.filter || null,
        filter_by: this.props.filters?.filter_by || firstFilter
      },
    };
  }

  componentDidMount() {
  }







  sideList = () => {
    const {
      classes, onFilter, filters, fields
    } = this.props;
    const { values } = this.state;
    return (
      <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
        <div className="d-flex justify-content-between p-1 align-items-center p-3" style={{ borderBottom: '1px solid #ccc' }}>
          <h4>Filter</h4>
          <IconButton aria-label="filter"
            color={"default"}
            onClick={() => this.props.onClose('right', false)}                     >
            <IconX size={20} stroke={1.5} />
          </IconButton>

        </div>
        <div style={{ height: 'calc(100vh - 130px)', overflow: 'auto', padding: 10 }}>
          <TextField
            name="filter"
            variant="outlined"
            margin='dense'
            fullWidth
            label=""
            placeholder='Search'
            onChange={(e) => {
              var data = Object.assign({}, this.state.values)
              data[e.target.name] = e.target.value
              this.setState({ values: data })
            }}
            value={values.filter}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.props.onFilter(this.state.values)
              }
            }}
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              }

            }}
          />
          <div className="mt-2 mb-2">
            <Box fontWeight={'bold'}>
              Fields Search :
            </Box>

            <RadioGroup
              value={values.filter_by}
              name="filter_by"
              onChange={(e) => {
                var data = Object.assign({}, this.state.values)
                data[e.target.name] = e.target.value
                this.setState({ values: data })
              }}
            >
              {fields.map((field, i) => {
                if (field?.filter) {
                  return (
                    <FormControlLabel key={i} value={field?.filterName || field?.name} control={<Radio />} label={field.label} />
                  )
                }

              })}

            </RadioGroup>
          </div>
        </div>
        <div className="d-flex justify-content-center align-item-center p-2" style={{ position: 'absolute', bottom: 0, height: 60, borderTop: '1px solid #ccc', width: '100%' }}>

          <Button
            onClick={() => {
              var listFilter = this.props.fields.filter(x => x.filter)
              var firstFilter = listFilter.length > 0 ? (listFilter[0].filterName || listFilter[0].name) : null
              this.setState({
                values: {
                  filter: null,
                  filter_by: firstFilter || null
                }
              }, () => {
                this.props.onFilter({
                  filter: null,
                  filter_by: null
                })
              })
            }}
            className="mr-3"
            // size="large"
            color="inherit" variant="outlined">
            Clear all filters
          </Button>
          <Button
            onClick={() => {
              this.props.onFilter(this.state.values)
            }}
            color="primary" variant="contained">
            Apply
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Drawer
          anchor="right"
          onClose={() => this.props.onClose()}
          open={this.props.right}
          PaperProps={{
            sx: {
              width: 350,
              bgcolor: 'background.default'
            },
          }}
        >
          {this.sideList()}
        </Drawer>
      </div>
    )
  }
}

const propTypes = {};

const defaultProps = {};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

const connectedFilter = connect(null)(withStyles(useStyles)(Filter));
export { connectedFilter as Filter };
