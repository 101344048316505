import React from 'react'
import { Checkbox } from '@mui/material'
import NumberFormat from "react-number-format"
import moment from 'moment'
import { request } from '../../../_services_'

export const filters = [
  {
    type: 'input_picker',
    valueKey: 'id',
    key: 'teams',
    defaultAriaLabel: 'Team',
    labelKey: 'title',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/admin/v1/teams', { search: inputValue || '' }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    }
  },
  {
    type: 'date_picker',
    key: 'create_date',
    placeholder: 'First day of FUD',
  }
]

export const fields = [
  {
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => `FUD ${item.fud_id}`,
    sort: true,
    filter: true,
    width: '6rem',
  },
  // {
  //   name: 'mess_group_link',
  //   label: '',
  //   renderValue: (item) => item?.team?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
  //     className='d-flex align-items-center'
  //     href={item?.team?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
  //   width: '3rem',
  // },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team ? `${item?.team?.team_search}` : '',
    sort: true,
    filter: true,
    filterName: 'teams.team_search',
    width: '6rem',
  },
  {
    name: 'can_use',
    label: 'Can Use',
    renderValue: (item) => <Checkbox checked={item.can_use === 1} color="primary" disabled disableRipple />,
    sort: true,
    width: '6rem',
  },
  {
    name: 'total_fud_team',
    label: 'Total FUD Can Use',
    sort: false,
    filter: true,
    width: '10rem',
  },
  {
    name: 'level',
    label: 'Package Money',
    sort: true,
    filter: true,
    width: '9rem',
  },
  {
    name: 'fee_of_pack',
    label: 'Package Fee',
    renderValue: (item) => <NumberFormat value={item.fee_of_pack?.toFixed(0) || 0} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '8rem',
  },
  {
    name: 'actual_fee_sent',
    label: 'B - Actual Fee Sent',
    renderValue: (item) => <NumberFormat value={item.actual_fee_sent?.toFixed(0) || 0} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '10rem',
  },
  {
    name: 'total_topup_to_now',
    label: 'A - Total Topup By FUD',
    renderValue: (item) => <NumberFormat value={item.total_topup_to_now} displayType="text" thousandSeparator={','} />,
    sort: true
  },
  {
    name: 'remaining_topup',
    label: 'Remaining Topup',
    renderValue: (item) => <NumberFormat value={item.remaining_topup} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '10rem',
  },
  {
    name: '1st_first_date',
    label: 'First Day Of FUD',
    renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss') : '',
  },
  {
    name: 'extend_special',
    label: 'Extend Day',
    sort: true,
    width: '8rem',
  },
  {
    name: 'cycle_end_date',
    label: 'Cycle End Day',
    renderValue: (item) => item['cycle_end_date'] ? moment(item['cycle_end_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    sort: true
  },
  {
    name: 'count_down_day',
    label: 'Countdown day',
    sort: false,
    renderValue: (item) => item.count_down_day > 0 ? item.count_down_day : ''
  },
]

export const formfields = [
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    renderValue: (item) => `FUD ${item.fud_id}`
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team ? `${item?.team?.team_search}` : '',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'can_use',
    label: 'Can use',
    disabled: true,
    checked: (item) => item.can_use === 1 ? true : false,
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true,
  },
  {
    type: 'number',
    name: 'package_money',
    label: 'Package Money',
    disabled: true,
  },
  {
    type: 'number',
    name: 'fee_of_pack',
    label: 'Package Fee',
    disabled: true,
  },
  {
    type: 'number',
    name: 'actual_fee_sent',
    label: 'B - Actual fee sent (Custom Fee)',
    renderValue: (item) => item.actual_fee_sent,
  },
  {
    type: 'number',
    name: 'total_topup_to_now',
    label: 'A - Total Topup By FUD',
    disabled: true,
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'Remaining Topup',
    disabled: true,
  },
  {
    type: 'text',
    name: 'create_date',
    label: 'First Day Of FUD',
    renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss') : '',
    disabled: true,
  },
  {
    type: 'number',
    name: 'extend_special',
    label: 'Extend Day',
  },
  {
    type: 'text',
    name: 'cycle_end_date',
    label: 'Cycle End Day',
    renderValue: (item) => item['cycle_end_date'] ? moment(item['cycle_end_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    disabled: true,
  },
  {
    type: 'number',
    name: 'count_down_day',
    label: 'Countdown day',
    renderValue: (item) => item.count_down_day,
    disabled: true,
  },
]