import React from 'react'
import { Checkbox } from '@mui/material'
import NumberFormat from "react-number-format"
import moment from 'moment'
import { IconBrandMessenger } from '@tabler/icons';

export const acc_status = [
  {id: 'active', name: 'Active'},
  {id: 'disabled', name: 'Disabled'},
  {id: 'permanently_disabled', name: 'Permanently disabled'},
  {id: 'closed', name: 'Closed'},
  {id: 'pending_closed', name: 'Pending Closed'},
  {id: 'fb_closing', name: 'FB Closing'},
]

export const fields = [
  {
    name: 'create_date',
    label: 'Time Created Acc',
    renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    sort: true,
    width: '10rem',
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    sort: true,
    filter: true,
    width: '13rem',
  },
  {
    name: 'acc_name',
    label: 'Acc Name',
    sort: true,
    filter: true,
    width: '17rem',
  },
  {
    name: 'time_zone',
    label: 'Time Zone',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    name: 'partner_code',
    label: 'Partner Code',
    sort: true,
    filter: true,
    width: '8rem',
  },
  {
    name: 'status',
    label: 'Acc Status Manual',
    renderValue: (item) => item.status ? acc_status?.find(e => e.id == item.status)?.name : '',
    sort: true,
    filter: true,
    width: '10rem',
  }
]

export const formfields = [
  {
    type: 'text',
    name: 'create_date',
    label: 'Time Created Acc',
    renderValue: (item) => item['create_date'] ? moment(item['create_date']).format('DD/MM/YYYY HH:mm:ss'):'',
    sort: true,
    width: '11rem',
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'Acc ID',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'acc_name',
    label: 'Acc Name',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'text',
    name: 'time_zone',
    label: 'Time Zone',
    sort: true,
    filter: true,
    width: '7rem',
  },
  {
    type: 'text',
    name: 'partner_code',
    label: 'Partner Code',
    sort: true,
    filter: true,
    width: '9rem',
  },
  {
    type: 'select',
    name: 'status',
    label: 'Acc Status Manual',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        resolve(acc_status)
      })
    },
    renderValue: (item) => item.status ? item.status?.id ? item.status : acc_status.find(e => e.id == item.status) || '' : '',
    sort: true,
    filter: true,
    width: '11rem',
  }
]