import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IconArrowLeft } from '@tabler/icons';

const useStyles = theme => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
})

const ToolBar = ({ title, actions, actionBack, ...props }) => {
  const { classes } = props;
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
      <div className={classes.title}>
        {actionBack && 
          <IconArrowLeft cursor={"pointer"} size={24} stroke={2} onClick={() => actionBack()} />
        }
        <Typography variant="h4" marginLeft={5}>
          {title}
        </Typography>
      </div>
      {actions && 
        <div className={classes.actions}>
          {actions?.filter(e => e.visible === true).reverse().map((action, index) => (
            <Button
              key={index}
              color={action.color || 'primary'}
              size={action.size || 'small'}
              variant={action.variant || 'contained'}
              startIcon={action.startIcon}
              endIcon={action.endIcon}
              onClick={action.action}
            >
              {action.text}
            </Button>
          ))}
        </div>
      }
    </Stack>
  )
}

const connectList = withStyles(useStyles)(ToolBar);
export { connectList as ToolBar };