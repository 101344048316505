import React from 'react'
import { Checkbox } from '@mui/material'
import NumberFormat from "react-number-format"
import { IconBrandMessenger } from '@tabler/icons';
import moment from 'moment';

export const fudLevel = [
  { id: 1, name: 'Level 2K', value: 2000 },
  { id: 2, name: 'Level 5K', value: 5000 },
  { id: 3, name: 'Level 10K', value: 10000 },
  { id: 4, name: 'Level 20K', value: 20000 },
  { id: 5, name: 'Level 50K', value: 50000 },
  { id: 6, name: 'Level 100K', value: 100000 },
  { id: 7, name: 'Level 200K', value: 200000 },
  { id: 8, name: 'Level 500K', value: 500000 }
]

export const fields = [
  {
    name: 'name',
    label: 'FUD ID auto',
    renderValue: (item) => `${item.name}`,
    sort: true,
    filter: true,
  },
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.team?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
      className='d-flex align-items-center'
      href={item?.team?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_name',
    label: 'Team ID & Name',
    renderValue: (item) => item.team ? `${item?.team?.team_search || ''}` : '',
    sort: true,
    sortName: 'teams.team_search',
    filter: true,
    filterName:'teams.team_search',
  },
  {
    name: 'acc_type',
    label: 'Acc Type',
    renderValue: (item) => item.team ? item?.team?.type_acc : '',
    sort: true,
    sortName: 'teams.type_acc',
    filter: true,
    filterName:'teams.type_acc',
  },
  {
    name: '1st_first_date',
    label: '1st Topup date',
    renderValue: (item) => item['1st_first_date'] ? moment(item['1st_first_date']).format('DD/MM/YYYY HH:mm:ss') : '',
    // sort:true
  },
  {
    name: 'extend_special',
    label: 'Extend Special',
    sort: true
  },
  {
    name: 'cycle_end_date',
    label: 'Cycle end date',
    renderValue: (item) => item.cycle_end_date ? moment(item.cycle_end_date).format('DD/MM/YYYY HH:mm:ss') : '',
    // sort:true
  },
  {
    name: 'count_down_day',
    label: 'Countdown day',
    renderValue: (item) => item.count_down_day,
    sort: true
  },
  {
    name: 'remaining_topup',
    label: 'Remaining Topup',
    renderValue: (item) => <NumberFormat value={item.remaining_topup} displayType="text" thousandSeparator={','} />,
    sort: true
  },
  {
    name: 'total_topup',
    label: 'A - Total Topup -> Now',
    renderValue: (item) => item.total_topup,
    sort: true
  },
  {
    name: 'package_money',
    label: 'Package Money',
    renderValue: (item) => <NumberFormat value={item.package_money} displayType="text" thousandSeparator={','} />,
    sort: true
  },

  {
    name: 'level',
    label: 'Level',
    sort: true,
    filter: true,
  },
  {
    name: 'fee_of_pack',
    label: 'Fee of Package',
    renderValue: (item) => <NumberFormat value={item.fee_of_pack || 0} displayType="text" thousandSeparator={','} />,
    sort: true
  },
  {
    name: 'actual_fee_sent',
    label: 'B - Actual Fee Sent',
    renderValue: (item) => item.actual_fee_sent,
    sort: true
  },
  {
    name: 'lack_of_fee',
    label: 'Lack of Fee',
    renderValue: (item) => item.lack_of_fee,
    sort: true
  },

  {
    name: 'fee_by_package',
    label: '% Fee by package',
    renderValue: (item) => <NumberFormat value={(item.fee_of_pack || 0) * 100 / item.package_money} displayType="text" thousandSeparator={','} suffix="%" />
  },
  {
    name: 'percent_fee_actual',
    label: '% Fee actual',
    renderValue: (item) => item.percent_fee_actual ? <NumberFormat value={item.percent_fee_actual} displayType="text" thousandSeparator={','} suffix="%" /> : ''
  },
  {
    name: 'can_use',
    label: 'Can Use',
    renderValue: (item) => <Checkbox checked={item.can_use === 1} color="primary" disabled disableRipple />,
    sort: true
  },
  {
    name: 'custom_fee',
    label: 'Custom fee',
    sort: true
  },
  {
    name: 'cancel',
    label: 'Cancel',
    renderValue: (item) => <Checkbox checked={item.cancel === 1} color="primary" disabled disableRipple />,
    sort: true
  },
  {
    name: 'fba_type',
    label: 'FBA Type',
    sort: true
  },
  {
    name: 'note',
    label: 'KH note',
    sort: true,
    filter: true,
    filterName:'note@ilike'
  },
  {
    name: 'discount_code',
    label: 'Discount code',
    sort: true,
    filter: true,
  },
]