import React, { Component } from "react";
import tinymce from "tinymce";
import "tinymce/themes/silver";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/table";
import axios from "axios";
import { authHeaders } from '../_helpers_'

class SmallTinyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editor: null
    };
  }

  componentDidMount() {
    this.initEditor(this.props)
  }

  componentDidUpdate(prevProps){
    if(this.props.detectReset != prevProps.detectReset){
      this.onSetContent(this.props.content);
    }
  }

  initEditor = (props) => {
    const { toolbar } = props;
    const default_tolbar = {
      toolbar1:
        "bold italic strikethrough underline",
    };
    var init = {
      selector: `#${props.id}`,
      autoresize_bottom_margin: 50,
      toolbar_sticky: true,
      toolbar_sticky_offset: 60,
      menubar: false,
      classes: "error-xxxx",
      resize: true,
      statusbar: false,
      setup: (editor) => {
        editor.on("keyup change", () => {
          const content = editor.getContent();
          props.onEditorChange(content);
        });
      },
    };
    if (toolbar) {
      init = { ...init, ...toolbar };
    } else {
      init = { ...init, ...default_tolbar };
    }
    tinymce.init(init);
    this.setState({ editor: tinymce.activeEditor })
    if (props.content) {
      this.onSetContent(props.content);
    }
  }

  componentWillUnmount() {
    tinymce.remove(this.state.editor);
  }

  onSetContent = (description) => {
    tinymce.activeEditor.setContent(description);
  };

  render() {
    return (
      <textarea
        id={this.props.id}
        value={this.props.content}
        onChange={(e) => console.log(e)}
        style={this.props.style || {}}
        className={this.props.className || ""}
        placeholder={this.props.placeholder || ""}
        disabled={this.props.disabled}
      />
    );
  }
}

export default SmallTinyEditor;
