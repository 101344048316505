import React from 'react'
import NumberFormat from "react-number-format"
import { Label } from '../../../components'
import { capitalizeStr } from '../../../utils'

const statusList = [
  {id: 'success', name: 'Success'},
  {id: 'pending', name: 'Pending'},
]

export const filters = [
  {
    valueKey: 'id',
    key: 'status',
    defaultAriaLabel: 'Status',
    getOptionLabel: (item) => item.name,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = statusList.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
        resolve(result)
      })
    }
  }
]

export const fields = [
  {
    name: 'transaction_date',
    label: 'Time',
    sort: true,
    width: '4rem',
  },
  {
    name: 'transaction_id',
    label: 'Transaction ID',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'amount',
    label: 'Amount (VND)',
    sort: true,
    filter: true,
    width: '5rem',
    renderValue: (item) => item.amount ? <NumberFormat value={item.amount.toFixed(0)} thousandSeparator="," displayType='text' /> : ''
  },
  {
    name: 'description',
    label: 'Description',
    sort: true,
    filter: true,
    width: '12rem',
  },
  {
    name: 'team_id',
    label: 'Team',
    renderValue: (item) => item.team_id?.team_id ? `Team ${item.team_id.team_id}` : '',
    sort: true,
    filter: true,
    width: '4rem',
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    width: '4rem',
    renderValue: (item) => <Label variant="ghost" color={(item.status === 'pending' && 'warning') || 'success'}>{capitalizeStr(item.status)}</Label>
  },
]

export const formfields = [
  {
    type: 'text',
    disabled: true,
    name: 'transaction_date',
    label: 'Time',
    sort: true,
    width: '6rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'transaction_id',
    label: 'Transaction ID',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'number',
    disabled: true,
    name: 'amount',
    label: 'Amount (VND)',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'description',
    label: 'Description',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team',
    renderValue: (item) => item.team_id ? `Team ${item.team_id.id}` : '',
    sort: true,
    filter: true,
    width: '6rem',
  },
  {
    type: 'text',
    disabled: true,
    name: 'status',
    label: 'Status',
    sort: true,
    filter: true,
    width: '6rem',
  },
]