import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';

export const timeZoneAcc = [
  {id: 'GMT+7', name: 'GMT+7'},
  {id: 'GMT-7', name: 'GMT-7'},
]

export const typeAcc = [
  {id: 'GSK', name: 'GSK'},
  {id: 'GSC', name: 'GSC'},
  {id: 'GVK', name: 'GVK'},
  {id: 'GVC', name: 'GVC'},
  {id: 'FS2', name: 'FS2'},
  {id: 'FSN', name: 'FSN'},
  {id: 'FV2', name: 'FV2'},
  {id: 'FVN', name: 'FVN'}
]

export const mainMarket = [
  {id: 'US', name: 'US'},
  {id: 'EU', name: 'EU'},
  {id: 'VN', name: 'VN'},
  {id: 'WW', name: 'WW'}
]

export const listFields = [
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team ID & Name',
    renderValue: (item) => `${item.team_search || ''} ${item?.short_name || ''}`,
    sort:true,
    filter:true,
    filterName:'team_search|shortname*1'
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    renderValue: (item) => typeAcc.find(e => e.id == item.type_acc)?.name,
    sort:true,
    filter:true,
  },
  
  {
    name: 'sale_convert',
    label: 'Sale convert',
    sort:true,
    filter:true,

  },
  {
    name: 'email',
    label: 'Email Address',
    sort:true,
    filter:true,

  },
  {
    name: 'new_password',
    label: 'New Password',
    sort:true,
  },
  {
    name: 'time_zone',
    label: 'Time zone of acc',
    sort:true,
  }
]

export const formFields = [
  {
    type: 'select',
    name: 'type_acc',
    label: 'Type Acc',
    renderValue: (item) => item.type_acc ? typeAcc.find(e => e.id == item.type_acc) : null,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = typeAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID & Name',
    renderValue: (item) => `${item.team_search || ''} ${item.owner_name || ''}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Team - Mess group',
  },
  {
    type: 'text',
    name: 'sale_convert',
    label: 'Sale Convert',
  },
  {
    type: 'text',
    name: 'content_file',
    label: 'Content File'
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address'
  },
  {
    type: 'text',
    name: 'short_name',
    label: 'Team short name'
  },
  {
    type: 'select',
    name: 'product_type_id',
    label: 'Main Product Type',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/product_types', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.product_type_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'main_web',
    label: 'Main Web'
  },
  {
    type: 'text',
    name: 'main_fanpage',
    label: 'Main Fanpage'
  },
  {
    type: 'select',
    name: 'main_market',
    label: 'Main Market',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = mainMarket.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.main_market ? mainMarket.find(e => e.id == item.main_market) : null,
    valueKey: "id",
    labelKey: "name"
  },
  {
    type: 'select',
    name: 'time_zone',
    label: 'Time zone of Acc',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timeZoneAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.time_zone ? timeZoneAcc.find(e => e.id == item.time_zone) : null,
    labelKey: 'name',
    valueKey: 'id'
  },
  {
    type: 'number',
    name: 'spent_per_month',
    label: 'Spent/ month (USD)'
  },
  {
    type: 'select',
    name: 'type_acc2',
    label: 'Type Acc 2',
    renderValue: (item) => item.type_acc2 ? typeAcc.find(e => e.id == item.type_acc2) : null,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = typeAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
  },
  {
    type: 'number',
    name: 'rate_max',
    label: 'Rate max',
    renderValue: (item) => item.rate_max ? `${item.rate_max}%` : ''
  },
]


export const formActiveFields = [
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Team - Mess group',
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID & Name',
    renderValue: (item) => `${item.team_search || ''} ${item?.owner_name||''}`,
    disabled: true
  },
  {
    type: 'select',
    name: 'type_acc',
    label: 'Type Acc',
    renderValue: (item) => item.type_acc ? typeAcc.find(e => e.id == item.type_acc) : null,
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = typeAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
  },
  
  {
    type: 'text',
    name: 'sale_convert',
    label: 'Sale Convert',
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address'
  },
  {
    type: 'text',
    name: 'content_file',
    label: 'Content File'
  },
  {
    type: 'text',
    name: 'facebook_link',
    label: 'User - Facebook Link'
  },
  {
    type: 'text',
    name: 'new_password',
    label: 'New password'
  },
  {
    type: 'select',
    name: 'time_zone',
    label: 'Time zone of Acc',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timeZoneAcc.filter(e => e.name.includes((inputValue || '').toUpperCase()))
        resolve(result)
      })
    },
    renderValue: (item) => item.time_zone ? timeZoneAcc.find(e => e.id == item.time_zone) : null,
    labelKey: 'name',
    valueKey: 'id'
  }
]