import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box, FormControlLabel, Checkbox
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { ModalForm, ResourceList, CustomNumberFormat } from '../../../components';
import { fields, fudLevel } from './constants';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';

class FudCountList extends Component {

  newFUD = (ctx) => {
    ModalForm.instance.current.openForm({
      title: "New",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Team ID
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/teams', {search: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"team_id"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "team_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Team"}
              menuPortalTarget={document.body}
              getOptionLabel={({ team_id, short_name }) => `Team_${team_id} ${short_name}`}
              getOptionValue={({ team_id }) => team_id}
              valueKey={"id"}
              value={submitData?.values.team_id || null}
              styles={submitData.errors?.team_id ? errorStyles : customStyles}
            />
            {submitData.errors?.team_id && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.team_id[0]}</span> }
          </div>
          
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Level
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = fudLevel.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"level"}
              onChange={(value) => {
                var e = {
                  target: {
                    name: "level",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Level"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.level || null}
              styles={submitData.errors?.level ? errorStyles : customStyles}
            />
            {submitData.errors?.level && <span style={{ color: 'red', fontSize: 12 }}>{submitData.errors.level[0]}</span> }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Extend Special
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="extend_special"
              type="text"
              onChange={handleChange}
              value={submitData.values.extend_special}
              placeholder="Extend Special..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Custom Fee
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="custom_fee"
              type="text"
              onChange={handleChange}
              value={submitData.values.custom_fee}
              placeholder="Custom Fee..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              label={<Box fontSize={15} fontWeight="bold">Cancel</Box>}
              control={
                <Checkbox 
                  checked={submitData.values.cancel}
                  onChange={(event) => {
                    const e = {
                      target: {
                        name: 'cancel',
                        value: event.target.checked
                      }
                    }
                    handleChange(e)
                  }}
                  color="primary"
                  name={'cancel'}
                />
              }
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Note
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="note"
              value={submitData.values.note}
              multiline
              onChange={handleChange}
              placeholder="Note..."
              rows={3}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Hết chu kỳ
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="cycle_end"
              type="text"
              onChange={handleChange}
              value={submitData.values.cycle_end}
              placeholder="Hết chu kỳ..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Discount code
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="discount_code"
              type="text"
              onChange={handleChange}
              value={submitData.values.discount_code}
              placeholder="Discount code..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: {
          level: {presence: {message: '^Required'}},
          team_id: {presence: {message: '^Required'}},
        },
        onAction: (submitData) => {
          const query = {
            ...submitData.values,
            level: submitData.values.level.name,
            package_money: submitData.values.level.value,
            team_id: submitData.values.team_id.team_id,
            cancel: submitData.values.cancel ? 1 : 0
          }

          return new Promise((resolve) => {
            request.post('/admin/v1/fuds', query).then(res => {
              if (res.data.success) {
                resolve(true);
                toastr.success("Create FUD successful")
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err);
              resolve(false)
            })
          })
        }
      }
    })
  }

  render () {
    const { navigate } = this.props;
    return (
      <ResourceList 
        route="/api/v1/fuds"
        titlePage="Drebest | 3.2.2 FUD count (DB3)"
        actions={[
          {
            text: 'New',
            variant: 'contained',
            icon: <AddIcon />,
            action: (ctx) => this.newFUD(ctx),
            visible: false
          }
        ]}
        title="3.2.2 FUD count (DB3)"
        fields={fields}
        valueKey="fud_id"
        onClickRow={(item) => navigate(`/app1/fud_count/${item.fud_id}`)}
        defaultFilters={{
          fud_count: 1
        }}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(FudCountList)