import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles, makeId, capitalizeStr } from '../../../utils';
import { ModalForm, ResourceList, Label, TinyEditor } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import moment from 'moment';

export const typeNotifications = [
  {
    code: 'all',
    name: 'All',
  },
  {
    code: 'company',
    name: 'Company',
  },
  {
    code: 'team',
    name: 'Team',
  },
  {
    code: 'individual',
    name: 'Individual',
  },
]

const fields = [
  {
    name: 'title',
    label: 'Title',
    sort: true,
    width: '12rem'
  },
  {
    name: 'content',
    label: 'Content',
    sort: true,
    width: '12rem',
    renderValue: (item) => <div dangerouslySetInnerHTML={{__html: item.content}} />
  },
  {
    name: 'type',
    label: 'Send option',
    sort: true,
    width: '6rem',
    renderValue: (item) => capitalizeStr(item.type)
  },
  {
    name: 'send_options',
    label: 'Send to',
    sort: true,
    width: '8rem',
    renderValue: (item) => (item.type != 'all') ? item.send_options?.map(e => e.name).join(', ') : ''
  },
  {
    name: 'is_sent',
    label: 'State',
    sort: true,
    width: '4rem',
    renderValue: (item) => <Label variant="ghost" color={item.is_sent ? 'success' : 'error'}>{item.is_sent ? 'Sent' : 'Not send'}</Label>
  },
  {
    name: 'create_date',
    label: 'Create date',
    sort: true,
    width: '8rem',
    renderValue: (item) => moment(item.create_date).format('YYYY-MM-DD HH:mm:ss')
  }
]

const ManualNotificationList = (props) => {
  const editorRef = useRef();

  const createManualNotification = (item, ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New manual notification',
      data: item || {type: 'all'},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Title <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="title"
              onChange={handleChange}
              value={submitData.values.title}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.title}
              helperText={submitData.errors?.title ? submitData.errors.title[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Type
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  const result = typeNotifications.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
                  resolve(result)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="type"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'type',
                    value: value.code
                  }
                }
                handleChange(event)
                const event_send_options = {
                  target: {
                    name: 'send_options',
                    value: []
                  }
                }
                handleChange(event_send_options)
              }}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ code }) => code}
              valueKey={"code"}
              value={typeNotifications.find(e => e.code == submitData.values.type)}
              styles={submitData.errors?.type ? errorStyles : customStyles}
            />
          </div>
          {submitData.values.type == 'company' && 
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Company <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/companies', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.company_id, name: e.name })))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                isMulti
                name="send_options"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'send_options',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Company"}
                menuPortalTarget={document.body}
                getOptionLabel={({ id }) => `Company_${id}`}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.send_options}
                styles={submitData.errors?.send_options ? errorStyles : customStyles}
              />
              {submitData.errors?.send_options && <small style={{ color: 'red' }}>{submitData.errors?.send_options[0]}</small>}

            </div>
          }
          {submitData.values.type == 'team' &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Team <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.team_id, name: `Team ${e.team_id}` })))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                isMulti
                name="send_options"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'send_options',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Team"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.send_options}
                styles={submitData.errors?.send_options ? errorStyles : customStyles}
              />
              {submitData.errors?.send_options && <small style={{ color: 'red' }}>{submitData.errors?.send_options[0]}</small>}

            </div>
          }
          {submitData.values.type == 'individual' &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                User <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/users', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.uid, name: `Team_${e.team_id?.team_id} ${e.shortname}`})))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                isMulti
                name="send_options"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'send_options',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"User"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.send_options}
                styles={submitData.errors?.send_options ? errorStyles : customStyles}
              />
              {submitData.errors?.send_options && <small style={{ color: 'red' }}>{submitData.errors?.send_options[0]}</small>}

            </div>
          }
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Content <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={3}
              margin='dense'
              name="content"
              onChange={handleChange}
              value={submitData.values.content}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.content}
              helperText={submitData.errors?.content ? submitData.errors.content[0] : ''}
            />
          </div>
          {/* <div className='mt-2 d-editor'>
            <Box fontSize={15} fontWeight='bold'>
              Content <span style={{ color: 'red' }}>*</span>
            </Box>
            <TinyEditor
              className={submitData.errors?.content ? 'error' : ''}
              style={{ width: '100%' }}
              content={submitData.values.content}
              id={makeId(20)}
              ref={editorRef}
              onEditorChange={(content) => {
                const event = {
                  target: {
                    name: 'content',
                    value: content
                  }
                }
                handleChange(event)
              }}
            />
            {submitData.errors?.content && <small style={{ color: 'red'}}>{submitData.errors.content[0]}</small>}
          </div> */}
        </div>
      ),
      multipleActions: true,
      actions: [
        {
          invisible: item && item.is_sent ? true : false,
          titleAction: 'Save',
          schema: (submitData) => {
            var schema = {
              title: {
                presence: { allowEmpty: false, message: '^Required' },
              },
              content: {
                presence: { allowEmpty: false, message: '^Required' },
              },
            }
            if (submitData.values.type != 'all') {
              schema['send_options'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            return schema
          },
          onAction: (submitData) => {
            return new Promise((resolve) => {
              if (item) {
                request.put(`/api/v1/manual_notification/${item.id}`, submitData.values).then(res => {
                  if (res.data.success) {
                    const items = ctx.state.items.map(e => e.id == item.id ? res.data.data : e)
                    ctx.setState({ items })
                    resolve(true);
                    toastr.success("Update successful")
                  } else {
                    toastr.error(res.data.msg)
                    resolve(false)
                  }
                }, err => {
                  toastr.error(err);
                  resolve(false)
                })
              } else {
                request.post('/api/v1/manual_notification', submitData.values).then(res => {
                  if (res.data.success) {
                    const items = Object.assign([], ctx.state.items)
                    items.unshift(res.data.data)
                    ctx.setState({ items, totals: ctx.state.totals + 1 })
                    resolve(true);
                    toastr.success("Create successful")
                  } else {
                    toastr.error(res.data.msg)
                    resolve(false)
                  }
                }, err => {
                  toastr.error(err);
                  resolve(false)
                })
              }
            })
          }
        },
        {
          invisible: item ? true : false,
          titleAction: 'Save & Send',
          schema: (submitData) => {
            var schema = {
              title: {
                presence: { allowEmpty: false, message: '^Required' },
              },
              content: {
                presence: { allowEmpty: false, message: '^Required' },
              },
            }
            if (submitData.values.type != 'all') {
              schema['send_options'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            return schema
          },
          onAction: (submitData, ctx) => {
            return new Promise((resolve) => {
              request.post('/api/v1/manual_notification', {...submitData.values, is_sent: 1}).then(res => {
                if (res.data.success) {
                  const items = Object.assign([], ctx.state.items)
                  items.unshift(res.data.data)
                  ctx.setState({ items, totals: ctx.state.totals + 1 })
                  resolve(true);
                  toastr.success("Create notification successful")
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            })
          }
        },
        {
          invisible: item && !item.is_sent ? false : true,
          titleAction: 'Send',
          schema: (submitData) => {
            var schema = {
              title: {
                presence: { allowEmpty: false, message: '^Required' },
              },
              content: {
                presence: { allowEmpty: false, message: '^Required' },
              },
            }
            if (submitData.values.type != 'all') {
              schema['send_options'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            return schema
          },
          onAction: (submitData, ctx) => {
            return new Promise((resolve) => {
              request.put(`/api/v1/manual_notification/${item.id}`, {is_sent: 1}).then(res => {
                if (res.data.success) {
                  const items = ctx.state.items.map(e => e.id == item.id ? res.data.data : e)
                  ctx.setState({ items })
                  resolve(true);
                  toastr.success("Send notification successful")
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            })
          }
        }
      ]
    })
  }

  const ManualNotificationForm = (item) => {
    ModalForm.instance.current.openForm({
      title: 'Manual notification detail',
      isSent: item.is_sent,
      data: {
      },
      customView: (submitData, handleChange, ctx) => {
        return (
          <div className='p-2'>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Title <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                disabled = {!(submitData.mode == 'edit')}
                fullWidth
                margin='dense'
                name="title"
                onChange={handleChange}
                value={submitData.values.title || item.title}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                error={submitData.errors?.title}
                helperText={submitData.errors?.title ? submitData.errors.title[0] : ''}
              />
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Content <span style={{ color: 'red' }}>*</span>
              </Box>
              <Editor
                disabled = {!(submitData.mode == 'edit')}
                value = {submitData.values.content || item.value}
                onEditorChange={ (string, editor) => {
                  const event = {
                    target: {
                      name: 'content',
                      value: string
                    }
                  }
                  handleChange(event)
                }}
              />
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Type
              </Box>
              <AsyncSelect
                isDisabled = {!(submitData.mode == 'edit')}
                className="MuiFormControl-marginDense"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    resolve(typeNotifications)
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="type"
                onChange={(obj) => {
                  const event = {
                    target: {
                      name: 'type',
                      value: obj.code
                    }
                  }
                  handleChange(event)
                }}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ code }) => code}
                valueKey={"code"}
                value={submitData.values.type || item.data}
                styles={submitData.errors?.type ? errorStyles : customStyles}
              />
            </div>
            {submitData.values.type && ['company'].includes(submitData.values.type) && 
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  Company <span className='field-require'>*</span>
                </Box>
                <AsyncSelect
                  isDisabled = {!(submitData.mode == 'edit')}
                  className="MuiFormControl-marginDense"
                  cacheOptions
                  loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise(resolve => {
                      request.get('/api/v1/companies', { search: inputValue }).then(res => {
                        if (res.data.success) {
                          resolve(res.data.data.items.map(e => ({ id: e.company_id, name: e.name })))
                        }
                      })
                    })
                  })}
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  isMulti
                  name="company_id"
                  onChange={(value) => {
                    const event = {
                      target: {
                        name: 'company_id',
                        value
                      }
                    }
                    handleChange(event)
                  }}
                  placeholder={"Company"}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ name, id }) => `Company_${id} ${name}`}
                  getOptionValue={({ id }) => id}
                  valueKey={"id"}
                  // value={toArrayObject('company', submitData.values.company_id)}
                  styles={customStyles}
                />
                {submitData.errors?.company_id && <span style={{ color: 'red' }}>{submitData.errors?.company_id[0]}</span>}
              </div>
            }
            {submitData.values.type && ['team'].includes(submitData.values.type) &&
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  Team <span className='field-require'>*</span>
                </Box>
                <AsyncSelect
                  isDisabled = {!(submitData.mode == 'edit')}
                  className="MuiFormControl-marginDense"
                  loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise(resolve => {
                      request.get('/admin/v1/teams', { search: inputValue }).then(res => {
                        if (res.data.success) {
                          resolve(res.data.data.items.map(e => ({ id: e.team_id, name: e.name })))
                        }
                      })
                    })
                  })}
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  isMulti
                  name="team_id"
                  onChange={(value) => {
                    const event = {
                      target: {
                        name: 'team_id',
                        value
                      }
                    }
                    handleChange(event)
                  }}
                  placeholder={"Team"}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ id, name }) => `Team_${id} ${name}`}
                  getOptionValue={({ id }) => id}
                  valueKey={"id"}
                  // value={toObject('team', submitData.values.team_id)}
                  styles={customStyles}
                />
                {submitData.errors?.team_id && <span style={{ color: 'red' }}>{submitData.errors?.team_id[0]}</span>}
              </div>
            }
            {submitData.values.type && ['individual'].includes(submitData.values.type) &&
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  User <span className='field-require'>*</span>
                </Box>
                <AsyncSelect
                  isDisabled = {!(submitData.mode == 'edit')}
                  className="MuiFormControl-marginDense"
                  loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise(resolve => {
                      request.get('/api/v1/users', { search: inputValue }).then(res => {
                        if (res.data.success) {
                          resolve(res.data.data.items.map(e => ({ id: e.uid, name: e.name })))
                        }
                      })
                    })
                  })}
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  isMulti
                  name="user_id"
                  onChange={(value) => {
                    const event = {
                      target: {
                        name: 'user_id',
                        value
                      }
                    }
                    handleChange(event)
                  }}
                  placeholder={"User"}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ id, name }) => `User_${id} ${name}`}
                  getOptionValue={({ id }) => id}
                  valueKey={"id"}
                  // value={toObject('user', submitData.values.user_id)}
                  styles={customStyles}
                />
                {submitData.errors?.uid && <span style={{ color: 'red' }}>{submitData.errors?.uid[0]}</span>}
              </div>
            }
          </div>
        )
      },
      multipleActions: true,
      actions: [
        {
          titleAction: 'Save',
          schema: (submitData) => {
            var schema = {
              title: {
                presence: { allowEmpty: false, message: '^Title required' },
              },
              content: {
                presence: { allowEmpty: false, message: '^Content required' },
              },
    
            }
            if (submitData.values.type && ['company'].includes(submitData.values.type)) {
              schema['company_id'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            if (submitData.values.type && ['team'].includes(submitData.values.type)) {
              schema['team_id'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            if (submitData.values.type && ['individual'].includes(submitData.values.type)) {
              schema['user_id'] = {
                presence: { allowEmpty: false, message: '^Required' }
              }
            }
            return schema
          },
          onAction: (submitData, ctx) => {
            return new Promise((resolve) => {
              submitData.mode = 'view'
              const query = {}
              const allKeys = Object.keys(submitData.valueChanges)
              allKeys.map(key => {
                if (['user_id', 'company_id', 'team_id'].includes(key)) {
                  const obj_id = submitData.values[key]
                  let ids = []
                  obj_id.map((obj) => ids.push(obj.id))
                  query[key] = ids
                } else {
                  query[key] = submitData.values[key]
                }
              })
              request.put(`/api/v1/manual_notification/${item.id}`, query).then(res => {
                if (res.data.success) {
                  const items = Object.assign([], ctx.state.items)
                  items.push(res.data.data)
                  ctx.setState({ items, totals: ctx.state.totals + 1 })
                  resolve(true);
                  toastr.success("Save notification successful")
                  window.location.reload();
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            })
          }
        },
        {
          titleAction: 'Send',
          schema: (submitData) => {
            var schema = {}
            return schema
          },
          onAction: (submitData, ctx) => {
            return new Promise((resolve) => {
              const query = {}
              query.notification_id = [item.id]
              request.post('/api/v1/send_notification', query).then(res => {
                if (res.data.success) {
                  const items = Object.assign([], ctx.state.items)
                  items.push(res.data.data)
                  ctx.setState({ items, totals: ctx.state.totals + 1 })
                  resolve(true);
                  toastr.success("Send notification successful")
                  window.location.reload();
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })
            })
          }
        },
        {
          titleAction: 'Edit',
          schema: (submitData) => {
            var schema = {}
            return schema
          },
          onAction: (submitData, ctx) => {
            return new Promise((resolve) => {
              submitData.mode = 'edit'
            })
          }
        } 
      ]
    })
  }

  return (
    <ResourceList 
      route="/api/v1/manual_notification"
      titlePage="Drebest | M-Notification"
      actions={[
        {
          text: 'New',
          variant: 'contained',
          icon: <AddIcon />,
          action: (ctx) => createManualNotification(null, ctx),
          visible: true
        }
      ]}
      defaultFilters={{create_type: 'manual'}}
      title="Manual notification"
      fields={fields}
      valueKey="notification_id"
      onClickRow={(item, ctx) => createManualNotification(item, ctx)}
    />
  )

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ManualNotificationList)