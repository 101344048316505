import React, { Component } from 'react'
import { listFields } from './fields';
import { ResourceList, DrawerForm } from '../../../components';
import { formFields } from './fields'
import { request } from '../../../_services_';
import Toastr from 'src/_helpers_/toastr';

class TeamList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      objectId: null,
      mode: 'view',
    }
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    
    request.put(`/api/v1/team/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        Toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        Toastr.error(res.data.msg)
      }
    }, err => {
      Toastr.error("There was an error updating data")
    })
  }

  render () {
    const { navigate } = this.props;
    const { open, mode, objectId } = this.state;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          route="/admin/v1/teams"
          titlePage="Drebest | 1.1 Team Info"
          title="1.1 Team Info In"
          fields={listFields}
          valueKey="team_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/team/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Team Detail"
          actions={[
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

export default TeamList;