import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, toastr } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';

const fields = [
    {
        type: 'text',
        name: 'name',
        label: 'TAY ID',
        disabled: true,
        renderValue: (item) => `${item.name}`,
    },
    {
        type: 'text',
        name: 'acc_code',
        label: 'Acc Code',
        disabled: true,
        renderValue: (item) => `${item?.account?.code || ''}`,
    },
    {
        type: 'text',
        name: 'team_id',
        label: 'Team ID Username',
        disabled: true,
        renderValue: (item) => `${item?.account?.team_id?.team_search || ''} ${item?.account?.team_id?.owner_name || ''}`,
    }
    ,
    {
        type: 'text',
        name: 'acc_name',
        label: 'Acc Name',
        disabled: true,
        renderValue: (item) => `${item?.account?.name || ''}`,
    }
    ,
    {
        type: 'text',
        name: 'acc_id',
        label: 'Acc ID',
        disabled: true,
        renderValue: (item) => `${item?.account?.acc_id || ''}`,
    }
    ,
    {
        type: 'number',
        name: 'money_request',
        label: 'Top Up Amount',
        // disabled: true,
        // renderValue: (item) => `${item.money_request}`,
    }
    ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Spending Limit 1 (Total topup)',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
    // ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Spending Limit 2 (before) - cleared',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
    // ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Spending Limit 1 (after)',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
    // ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Spending Limit 2 (after) - cleared',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // },
    {
        type: 'text',
        name: 'name',
        label: 'Request ID',
        disabled: true,
        renderValue: (item) => `${item?.request?.name || ''}`,
    }
    ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Ads Manager',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
    // ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Payment Settings',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
    // ,
    // {
    //     type: 'text',
    //     name: 'name',
    //     label: 'Spending Limit (Temporary Done)',
    //     disabled: true,
    //     renderValue: (item) => `${item.name}`,
    // }
]

const RACTopupForm = (props) => {

    const handleSave = (ctx, formState) => {
        const query = {}
        const allKeys = Object.keys(formState.changes)
        allKeys.map(key => {
            query[key] = formState.changes[key]
        })

        request.put(`/api/v1/acc_topup/${formState.values.id}`, query).then(res => {
            if (res.data.success) {
                const form = Object.assign({}, formState)
                form.changes = {}
                form.values = res.data.data
                ctx.setState({ formState: form })
                toastr.success("Update successful")
                setMode('view')
            } else {
                toastr.error(res.data.msg)
            }
        }, err => {
            toastr.error("There was an error updating data")
        })
    }

    const handleCancel = (ctx) => {
        setMode('view')
        ctx.handleCancel()
    }

    const [mode, setMode] = useState('view')
    return (
        <ResourceForm
            mode={mode}
            fields={fields}
            route="app1/rai_topups"
            onLoadData={(id) => {
                return new Promise((resolve) => {
                    request.get(`/api/v1/acc_topup/${id}`,{ type_acc: 'RAI' }).then(res => {
                        if (res.data.success) {
                            resolve(res.data.data)
                        } else {
                            resolve(false)
                        }
                    }, err => {
                        resolve(false)
                    })
                });
            }}
            title="RAC Topup Detail"
            actionBack={() => props.navigate('/app1/rai_topups')}
            actions={[
                {
                    text: 'Edit',
                    variant: 'contained',
                    color: 'primary',
                    action: () => setMode('edit'),
                    visible: mode == 'view'
                },
                {
                    text: 'Save',
                    variant: 'contained',
                    color: 'primary',
                    action: (ctx, formState) => handleSave(ctx, formState),
                    visible: mode == 'edit'
                },
                {
                    text: 'Cancel',
                    variant: 'outlined',
                    color: 'error',
                    action: (ctx, formState) => handleCancel(ctx),
                    visible: mode == 'edit'
                },
            ]}
            {...props}
        />
    )
}

export default withRouter(RACTopupForm);